import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TransferPortfolio from './TransferPortfolio/TransferPortfolio';
import PortfolioSearch from './PortfolioSearch/PortfolioSearch';
import PortfolioDetails from './PortfolioDetails/PortfolioDetails';


const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const Portfolios = (props) => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;
  const classes = useStyles();

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  };

  const isPageActive = (page) => pathName.search(page) !== -1;

  return (
    <>
      <Grid container justifyContent="center" alignItems="baseline">
        <Grid item>
          <Button color="primary" className={isPageActive('search') ? classes.active : ''} onClick={() => handleChangePage('search')} >
            {translate('search portfolios')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('clone') ? classes.active : ''} onClick={() => handleChangePage('clone')}>
            {translate('copy transactions')}
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Switch>
          <Route path={previousRoute + '/search'} component={PortfolioSearch}/>
          <Route path={previousRoute + '/clone'} component={TransferPortfolio}/>
          <Route path={previousRoute + '/details/:PortfolioId'} component={PortfolioDetails}/>
          <Redirect to={previousRoute + '/search'} />
        </Switch>
      </Grid>
    </>
  );
};

export default Portfolios;
