import React from 'react';
import Dropzone from 'react-dropzone';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import * as color from '../constants';

const useStyles = makeStyles({
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: color.DarkerWhite,
    borderStyle: 'dashed',
    backgroundColor: color.SoftGray,
    color: color.Gray,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    minHeight: '40px',
    justifyContent: 'center'
  },
  loading: {
    color: color.Gray
  }
}
);

const DropzoneCustomize = ({ handleFile, maxSize, accept, text, loading }) => {
  const classes = useStyles();
  return (
    <Dropzone onDrop={(accepted, rejected) => handleFile(accepted, rejected)} maxSize={maxSize} accept={accept} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <section className='container'>
          <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <Typography className={classes.baseStyle} variant='subtitle1'>
              { loading ?
                <CircularProgress className={classes.loading} data-testid='loading' size='2.5rem' /> :
                text }
            </Typography>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropzoneCustomize;
