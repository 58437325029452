import axios from 'axios';

export const getCouponList = () => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}coupon`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createCoupon = (body) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}coupon`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};

export const editCoupon = (code, body) => {
  return axios({
    method: 'patch',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}coupon/${code}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};

export const deleteCoupon = (code) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}coupon/${code}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
