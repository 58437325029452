import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../shared';
import { deleteUser } from '../../../../services/user';

const DeleteUserModal = ({ open, closeModal, item: user, setMessage, updateTable }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { t: translate } = useTranslation();

  const startForm = (bool) => {
    setLoading(bool);
    setDisabled(bool);
  };

  const confirmDelete = () => {
    startForm(true);

    deleteUser( user,
      (response) => {
        switch (response.status) {
        case 200:
          setMessage(translate(`deleted user`));
          updateTable();
          break;
        case 403:
          setMessage(translate('permission denied'));
          break;
        default:
          setMessage(translate(`NOT deleted user`));
        }
        startForm(false);
        closeModal();
      }
    );
  };

  return (
    <>
      <ConfirmationModal
        openModal={open}
        closeModal={() => closeModal()}
        testid='delete-user-modal'
        name={translate('confirm user delete')}
        confirm={() => confirmDelete()}
        additionalInfo={user.email}
        disabled={disabled}
        cancel='contained'
        loading={loading}
      />
    </>
  );
};

export default DeleteUserModal;
