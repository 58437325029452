import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@material-ui/core';
import { ReactComponent as AddPortfolios } from '../../../../assets/images/icons/add_portfolios.svg';
import { ReactComponent as RemovePortfolios } from '../../../../assets/images/icons/remove_portfolios.svg';
import { createOrganizationUserPortfolioRelation, insertOrganizationsUser, listOrganizationsUsers, removeOrganizationUserPortfolioRelation, removeOrganizationsUser } from '../../../../services/organizations';
import { ConfirmationModal } from '../../../../shared';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { getImpersonateLink } from '../../../../services/user';
import { useHistory } from 'react-router-dom';

export const UsersTable = ({ setMessage, data: orgData, setUsersCount }) => {
  const impersonateIconPath = `${process.env.REACT_APP_ICON_PATH}/Platform/Go-Gorila.svg`;

  const portfolioActionInitial = {
    action: '',
    user: '',
    role: 'VIEWER',
    loading: false
  };

  const history = useHistory();

  const [portfolioAction, setPortfolioAction] = useState(portfolioActionInitial);

  const { t: translate } = useTranslation();

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove user')
      },
      addTooltip: translate('add organization user'),
      deleteTooltip: translate('remove user')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization user already exists':
      return setMessage(translate('User already in organization'));
    case 'Invalid parameters provided':
      return setMessage(translate('This userId doesnt exist'));
    default:
      return setMessage(translate(`NOT ${serviceName} user`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const userId = newData.user.id;
      const role = newData.role || 'VIEWER';
      const { id: orgId } = orgData;

      if (serviceName === 'inserted') {
        await service(orgId, userId, role);
      } else {
        await service(orgId, userId);
      }
      return setMessage(translate(`${serviceName} user`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editableTable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', insertOrganizationsUser, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationsUser, oldData);
    }
  };

  const impersonateUser = async (rowData) => {
    const impersonateUrl = await getImpersonateLink(rowData.user.id);
    window.open(impersonateUrl.data);
  };

  const tableProps = {
    columns: [
      { title: 'UserId', field: 'user.id' },
      { title: translate('name'), field: 'user.name', editable: 'never' },
      { title: 'Email', field: 'user.email', editable: 'never' },
      {
        title: translate('role'),
        field: 'role',
        editComponent: (props) => (
          <Select
            value={props.value || 'VIEWER'}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <MenuItem value="EDITOR">EDITOR</MenuItem>
            <MenuItem value="VIEWER">VIEWER</MenuItem>
          </Select>
        )
      }
    ],
    actions: [
      {
        icon: () => (
          <img
            src={impersonateIconPath}
            alt='ImpersonateIcon'
            style={{ width: '20px', height: '20px' }}
          />
        ),
        tooltip: translate('impersonate user'),
        isFreeAction: false,
        onClick: (event, rowData) => {
          impersonateUser(rowData);
        }
      },
      {
        icon: () => <AddPortfolios style={{ height: 24, width: 24 }}/>,
        tooltip: translate('add all portfolios'),
        isFreeAction: false,
        onClick: (event, rowData) => setPortfolioAction({ ...portfolioAction, action: 'add', user: rowData.user })
      },
      {
        icon: () => <RemovePortfolios style={{ height: 24, width: 24 }}/>,
        tooltip: translate('remove all portfolios'),
        isFreeAction: false,
        onClick: (event, rowData) => setPortfolioAction({ ...portfolioAction, action: 'remove', user: rowData.user })
      },
      {
        icon: VisibilityOutlinedIcon,
        tooltip: translate('user details'),
        onClick: ((e, rowData) => history.push(`/user/details/${rowData.user.id}`, { userData: rowData.user }))
      }
    ],
    editable: editableTable
  };

  const handlePortfolioAction = async () =>{
    try {
      setPortfolioAction((prevState) =>({ ...prevState, loading: true }));

      if (portfolioAction.action === 'add') {
        const { data: response } = await createOrganizationUserPortfolioRelation({
          orgId: orgData.id,
          userId: portfolioAction.user.id,
          addAll: true,
          role: portfolioAction.role
        });

        setMessage(translate(`added @total portfolios to the user`, { total: response.total }));
      } else if (portfolioAction.action === 'remove') {
        const { data: response } = await removeOrganizationUserPortfolioRelation({
          orgId: orgData.id,
          userId: portfolioAction.user.id,
          removeAll: true
        });

        setMessage(translate(`removed @total portfolios from the user`, { total: response.total }));
      }

      setPortfolioAction(portfolioActionInitial);
    } catch (error) {
      setPortfolioAction(portfolioActionInitial);
      return setMessage(translate(`NOT ${portfolioAction.action} portfolios`));
    }
  };

  const searchOrganizationUsers = async (offset, limit, search, count) => {
    const { data: response } = await listOrganizationsUsers({ orgId: orgData.id, offset, limit, search, count, populate: 'user' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchOrganizationUsers}
        columns={tableProps.columns}
        actions={tableProps.actions}
        editable={tableProps.editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setUsersCount}
      />
      {portfolioAction?.action &&
      <ConfirmationModal
        openModal={!!portfolioAction?.action}
        closeModal={() => setPortfolioAction(portfolioActionInitial)}
        name={
          portfolioAction?.action === 'remove' ?
            <b>{ translate('confirm remove all portfolios') }</b> :
            <b>{ translate('confirm add all portfolios') }</b>
        }
        confirm={() => handlePortfolioAction()}
        additionalInfo={
          <>
            <div>
              <b style={{ marginRight: '0.25rem' }}>Email:</b> {portfolioAction.user.email}
            </div>
            { portfolioAction?.action === 'add' &&
            <div>
              <b style={{ marginRight: '0.4rem' }}>{ translate('role in the portfolios') }:</b>
              <Select
                value={portfolioAction.role}
                onChange={(e) => {
                  setPortfolioAction({ ...portfolioAction, role: e.target.value });
                }}
              >
                <MenuItem value="EDITOR">EDITOR</MenuItem>
                <MenuItem value="VIEWER">VIEWER</MenuItem>
              </Select>
            </div>
            }
          </>
        }
        disabled={portfolioAction.loading}
        cancel='contained'
        loading={portfolioAction.loading}
      />}
    </>
  );
};

export default UsersTable;
