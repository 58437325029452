import axios from 'axios';

export const getPriceList = (params) => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}prices`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    params: params
  });
};

export const postPrice = (price) => {
  return axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}prices`, price, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

