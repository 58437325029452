import { verifyToken } from '../../services/auth';
import { setAuthenticationState, setErrorMessage, loading } from './../../shared/ActionTypes';

export const getToken = (err) => {
  if (err && err.description) {
    return { type: setErrorMessage, message: err.description };
  } else {
    return { type: setAuthenticationState, validToken: true };
  }
};

export const setLoading = () => ({ type: loading });

export const revokeToken = () => {
  return { type: setAuthenticationState, validToken: false };
};

export const checkToken = () => {
  return { type: setAuthenticationState, validToken: verifyToken() };
};
