import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const FormDatePicker = (props) => {
  const { label, value, onChange, required = true, error, helperText } = props;

  const { t: translate } = useTranslation();

  return (
    <Grid item xs={12}>
      <KeyboardDatePicker
        variant="inline"
        format="DD/MM/YYYY"
        margin="normal"
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        label={translate(label)}
        value={value}
        onChange={onChange}
        allowKeyboardControl={true}
        error={error}
        helperText={error && helperText}
        required={required}
      />
    </Grid>
  );
};

FormDatePicker.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool
};

export default FormDatePicker;
