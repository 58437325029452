import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { color } from '../../../../shared';
import { updateUser } from '../../../../services/user';

const useStyles = makeStyles((theme) => ({
  loading: {
    marginLeft: '0.5rem',
    color: color.GorilaDefault
  }
}));

const ToggleActiveModal = ({ open, closeModal, item: user, setMessage, updateTable }) => {
  const { t: translate } = useTranslation();
  const [text, setText] = useState('');
  const [confirmText, setconfirmText] = useState();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (open && user?.status) {
      if (user.status === 'ACTIVE') {
        setText('confirm to disable user');
        setconfirmText('disable');
      } else {
        setText('confirm to activate user');
        setconfirmText('activate');
      }
    }
  }, [open, user]);

  const confirm = async () => {
    setLoading(true);
    setClicked(true);

    try {
      if (confirmText === 'activate') {
        await updateUser({ id: user.id, status: 'ACTIVE' });
        setMessage(translate(`user activated`));
      } else if (confirmText === 'disable') {
        await updateUser({ id: user.id, status: 'DISABLED' });
        setMessage(translate(`user disabled`));
      }
      updateTable();
    } catch {
      if (confirmText === 'activate') {
        setMessage(translate(`user NOT activated`));
      } else if (confirmText === 'disable') {
        setMessage(translate(`user NOT disabled`));
      }
    }

    setLoading(false);
    setClicked(false);
    closeModal();
  };

  const cancel = async () => {
    closeModal();
    setClicked(false);
  };

  return <>
    <Dialog
      fullWidth
      open={open}
      onClose={closeModal}
      aria-labelledby="Diálogo de confirmação"
      aria-describedby="Diálogo de confirmação"
      data-testid="support-org-modal"
    >
      <DialogContent>
        <DialogContentText>
          {translate('toggleUserActive')}
        </DialogContentText>
        <DialogContentText data-testid="info">
          {translate(text)}
        </DialogContentText>
        <DialogActions>
          <Button onClick={cancel} variant='contained' data-testid='cancel' color='primary'>
            {translate('cancel')}
          </Button>
          <Button onClick={confirm} color='primary' data-testid='save' disabled={clicked} autoFocus>
            {translate(confirmText)}
            {loading && <CircularProgress className={classes.loading} size='1rem' />}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  </>;
};

export default ToggleActiveModal;
