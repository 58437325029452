import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DetailsPage from '../../../shared/components/DetailsPage/DetailsPage';
import {
  readOrganization
} from '../../../services/organizations';
import dayjs from 'dayjs';
import { UsersTable } from './Tabs/UsersTable';
import { PortfoliosTable } from './Tabs/PortfoliosTable';
import { UserPortfoliosTable } from './Tabs/UserPortfoliosTable';
import { ApiKeyTable } from './Tabs/ApiKeyTable';


const OrganizationDetails = () => {
  const { t: translate } = useTranslation();
  const { OrgId } = useParams();

  const history = useHistory();

  const [orgData, setOrgData] = useState(history?.location?.state?.orgData || {});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [usersCount, setUsersCount] = useState(null);
  const [portfoliosCount, setPortfoliosCount] = useState(null);
  const [userPortfoliosCount, setUserPortfoliosCount] = useState(null);
  const [apiKeysCount, setApiKeysCount] = useState(null);

  useEffect( () => {
    const getDetails = async () => {
      try {
        setLoading(true);
        const responseData = await readOrganization(OrgId);
        setOrgData(responseData);
      } catch (err) {
        setMessage(translate('it was not possible to search organization details'));
      }
      setLoading(false);
    };

    if (!Object.keys(orgData).length) {
      getDetails();
    }
  }, [OrgId, orgData, translate]);

  const header = {
    title: orgData?.name,
    fields: [
      {
        name: 'orgId',
        data: orgData?.id
      },
      {
        name: 'taxId',
        data: orgData?.taxId
      },
      {
        name: 'created date',
        data: orgData?.created && dayjs(orgData?.created).format('DD/MM/YY')
      }
    ]
  };

  const tabs = [
    {
      name: `${translate('users')}` + (usersCount !== null ? ` (${usersCount})` : ''),
      content: {
        component: UsersTable,
        props: {
          setUsersCount
        }
      }
    },
    {
      name: `${translate('portfolios')}` + (portfoliosCount !== null ? ` (${portfoliosCount})` : ''),
      content: {
        component: PortfoliosTable,
        props: {
          setPortfoliosCount
        }
      }
    },
    {
      name: `${translate('user portfolios')}` + (userPortfoliosCount !== null ? ` (${userPortfoliosCount})` : ''),
      content: {
        component: UserPortfoliosTable,
        props: {
          setUserPortfoliosCount
        }
      }
    },
    {
      name: `${translate('api keys')}` + (apiKeysCount !== null ? ` (${apiKeysCount})` : ''),
      content: {
        component: ApiKeyTable,
        props: {
          setApiKeysCount
        }
      }
    }
  ];

  return (
    <DetailsPage
      tabs={tabs}
      message={message}
      header={header}
      loading={loading}
      data={orgData}
      setMessage={setMessage}
    />
  );
};

export default OrganizationDetails;
