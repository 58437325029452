import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs';
import FormDatePicker from '../../../shared/forms/FormDatePicker';
import SuccessSnackBar from '../../../shared/snackbar/SuccessSnackBar';
import DataConfirmationModal from '../../../shared/modals/DataConfirmationModal';
import { postCrypto } from '../../../services/securities';

const CryptoInsert = () => {
  const { t: translate } = useTranslation();

  const [symbol, setSymbol] = React.useState('');

  const displayNameRef = React.useRef('');

  const [issueDate, setIssueDate] = React.useState(new Date());

  const [openDialog, setOpenDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [showSnackBar, setShowSnackBar] = React.useState(false);

  const [failureMessage, setFailureMessage] = React.useState('');

  const [success, setSuccess] = React.useState(false);

  const handleSymbolChange = (e) => {
    const newValue = e.target.value;

    setSymbol(newValue.toUpperCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOpenDialog(true);
  };

  const handleCryptoInsert = async (e) => {
    setOpenDialog(false);

    setLoading(true);

    try {
      const issueDateDayJS = dayjs(issueDate);

      const security = {
        issueDate: issueDateDayJS.format('YYYY-MM-DD'),
        symbol: symbol,
        productType: 'CRYPTOCURRENCY',
        tradeSettlementLead: 0,
        validityDate: issueDateDayJS.format('YYYY-MM-DD'),
        displayName: symbol,
        source: 'FINNHUB',
        tag: 'BRL',
        calendar: {
          name: 'EMPTY',
          category: 'EMPTY'
        },
        paymentCurrency: 'USD',
        classification: 'CRYPTO',
        pricingMethod: 'PRICE_SERVICE',
        specificData: {
          description: displayNameRef.current.value
        }
      };

      await postCrypto(security);

      setSuccess(true);
    } catch (err) {
      switch (err.response && err.response.status) {
      case 404:
        setFailureMessage('Cryptocurrency not found on our provider');
        break;
      case 409:
        setFailureMessage('Cryptocurrency already exists in our database');
        break;
      default:
        setFailureMessage('Internal server error, could not insert crypto');
        break;
      }

      setSuccess(false);
    } finally {
      setShowSnackBar(true);

      setLoading(false);
    }
  };

  return (
    <>
      <Box my={3}>
        <Container maxWidth="sm">
          <Paper>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box p={3}>
                <Grid container justifyContent="center" alignItems="baseline" spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('name')}
                      type="search"
                      variant="standard"
                      fullWidth
                      inputRef={displayNameRef}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('ticker')}
                      type="search"
                      variant="standard"
                      fullWidth
                      value={symbol}
                      onChange={handleSymbolChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDatePicker
                      label="issue date"
                      value={issueDate}
                      onChange={setIssueDate}
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                      {loading ? <CircularProgress size={25} /> : translate('insert')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
      <DataConfirmationModal
        open={openDialog}
        setOpen={setOpenDialog}
        sections={[
          {
            data: {
              'name': displayNameRef.current.value,
              'ticker': symbol,
              'issue date': dayjs(issueDate).format('DD/MM/YYYY')
            }
          }
        ]}
        onConfirm={handleCryptoInsert}
      />

      <SuccessSnackBar
        success={success}
        successMessage="Cripto inserida com sucesso!"
        failureMessage={translate(failureMessage)}
        open={showSnackBar}
        setOpen={setShowSnackBar}
      />
    </>
  );
};

export default CryptoInsert;
