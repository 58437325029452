import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DataConfirmationModal = (props) => {
  const { open, setOpen, sections, onConfirm, downloadData } = props;

  const { t: translate } = useTranslation();

  const exportData = (data) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'data.json';

    link.click();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {translate('confirmation')}
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Grid
            container
            justifyContent="center"
            alignItems="baseline"
            spacing={3}
          >
            <Grid item xs={12}>
              {translate('the following data will be added in the server')}
            </Grid>

            {downloadData &&
              <Button
                onClick={() => exportData(downloadData)}
                color="gray"
                variant="contained"
              >
                {translate('download')}
              </Button>
            }

            {
              sections.map((section, i) => (
                <React.Fragment key={i}>
                  {
                    section.title && (
                      <Grid item xs={12}>
                        <h3>
                          {translate(section.title)}
                        </h3>
                      </Grid>
                    )
                  }
                  {Object.keys(section.data).map((key) => (
                    <>
                      <Grid item xs={6}>
                        {translate(key)}:
                      </Grid>
                      <Grid item xs={6}>
                        {section.data[key]}
                      </Grid>
                    </>
                  ))}
                  {section.component}
                </React.Fragment>
              ))
            }
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(e) => onConfirm(e)}
          color="primary"
          variant="contained"
        >
          {translate('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DataConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  downloadData: PropTypes.object,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.object,
      component: PropTypes.element
    })
  )
};

export default DataConfirmationModal;
