import MaterialTable from '@material-table/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialIcons } from '../../../shared/MaterialIcons';
import IssuerCreateModal from '../Modals/IssuerCreateModal';
import EditNameModal from '../Modals/EditNameModal';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '85%'
  }
}));

const IssuerList = ({ issuerList, setIssuerList, setRefreshList, refreshList }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const closeModal = () => {
    setOpenModal(false);
    setOpenEditModal(false);
    setRefreshList(!refreshList);
  };

  return (
    <div className={classes.table}>
      <MaterialTable
        title={translate('issuers table')}
        icons={{
          NextPage: MaterialIcons.NextPage,
          PreviousPage: MaterialIcons.PreviousPage,
          ResetSearch: MaterialIcons.Clear,
          Search: MaterialIcons.Search
        }}
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 25],
          showFirstLastPageButtons: false,
          paginationType: 'stepped',
          sorting: false,
          draggable: false,
          actionsColumnIndex: -1,
          headerStyle: { fontWeight: 'bold', fontSize: '18px' }
        }}
        localization={{
          header: {
            actions: translate('actions')
          },
          toolbar: {
            searchPlaceholder: translate('search issuer')
          }
        }}
        columns={[
          { title: translate('name'), field: 'displayName' },
          { title: translate('symbol'), field: 'symbol' }
        ]}
        data={issuerList}
        actions={[
          {
            icon: MaterialIcons.Add,
            tooltip: translate('add issuer'),
            position: 'toolbar',
            onClick: () => {
              setOpenModal(true);
            }
          },
          {
            icon: MaterialIcons.Edit,
            tooltip: translate('edit issuer'),
            onClick: (evt, data) => {
              setSelectedRow(data);
              setOpenEditModal(true);
            }
          }
        ]}
      />
      <IssuerCreateModal visible={openModal} closeModal={closeModal} data={issuerList} />
      <EditNameModal visible={openEditModal} data={selectedRow} closeModal={closeModal} issuerList={issuerList} enterpriseType='issuer' />
    </div>
  );
};

export default IssuerList;
