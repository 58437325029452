import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from '../components';

const UnauthenticatedApp = () => (
  <>
    <Route path="/" component={Auth} />
    <Redirect to="/" />
  </>
);

export default UnauthenticatedApp;
