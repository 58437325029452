import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const SuccessSnackBar = (props) => {
  const { success, successMessage, failureMessage, open, setOpen } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      {success ? (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleClose}
        >
          {successMessage}
        </MuiAlert>
      ) : (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={handleClose}
        >
          {failureMessage}
        </MuiAlert>
      )}
    </Snackbar>
  );
};

SuccessSnackBar.propTypes = {
  success: PropTypes.bool,
  open: PropTypes.bool,
  successMessage: PropTypes.string,
  failureMessage: PropTypes.string,
  setOpen: PropTypes.func
};

export default SuccessSnackBar;
