import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  Enterprises,
  Coupons,
  PremiumData,
  Notification,
  User,
  Price,
  Security,
  Organizations,
  Portfolios
} from '../components';

const AuthenticatedApp = () => (
  <>
    <Route path="/notification" component={Notification} />
    <Route path="/user" component={User} />
    <Route path="/portfolios" component={Portfolios} />
    <Route path="/organizations" component={Organizations} />
    <Route path="/coupons" component={Coupons} />
    <Route path="/enterprises" component={Enterprises} />
    <Route path="/premiumdata" component={PremiumData} />
    <Route path="/prices" component={Price} />
    <Route path="/securities" component={Security} />
    <Redirect exact from="/" to="/user" />
  </>
);

export default AuthenticatedApp;
