import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { color } from '../..';
import CustomTextField from './CustomTextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CustomButton from './CustomButton';
import CustomRadioGroup from './CustomRadioGroup';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    position: 'relative',
    width: '30%',
    maxWidth: '25rem'
  },
  containerInput: {
    position: 'relative',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '90%'
  },
  errorText: {
    fontWeight: 'bold',
    color: color.NegativeFeedBack
  },
  successText: {
    fontWeight: 'bold',
    color: color.PositveFeedBack
  }
}));

const CreateEntityForm = ({ title, fields, validation, action, radioGroup }) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  const initialDataState = fields.reduce((obj, field) => {
    return {
      ...obj,
      [field.name]: ''
    };
  }, {});

  if (radioGroup?.name) {
    initialDataState[radioGroup.name] = '';
  }

  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState([]);
  const [formStarted, setFormStarted] = useState({});
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(true);
  const [formData, setFormData] = useState(initialDataState);
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value
      };
    });
  };

  const handleRegisterUser = async () => {
    if (loading) return;

    setLoading(true);

    Object.keys(formData).forEach((field) => {
      if (typeof formData[field] === 'string') {
        formData[field] = formData[field].trim();
      }
    });

    const { error, message } = await action(formData);

    setError(error);
    setMessage(translate(message));

    if (!error) {
      clearForm();
    }
    setLoading(false);
  };

  const clearForm = () => {
    setFormData(initialDataState);
    setFormStarted({});
    setLoading(false);
  };

  const handleTouch = (fieldName) => {
    if (!formStarted[fieldName]) setFormStarted({ ...formStarted, [fieldName]: true });
    if (message) setMessage(false);
    setTouched((prevTouched) => {
      const updatedTouched = [...prevTouched];
      updatedTouched[fieldName] = !prevTouched[fieldName];
      return updatedTouched;
    });
  };

  const fieldError = (field) => {
    return validation(formData, field);
  };

  const handleError = (fieldName) => {
    return (formStarted[fieldName] && !!fieldError(fieldName) && (!!formData[fieldName]?.length || !touched[fieldName]));
  };

  return (
    <>
      <Grid container justifyContent='center' alignItems="center">
        <Paper className={classes.paper}>
          <Typography variant='h5' align="center" style = {{ marginBottom: '1rem' }} color="primary">
            {translate(title)}
          </Typography>
          <Grid className={classes.containerInput}>
            {fields.map((input) => {
              return (
                <CustomTextField
                  key={input.name}
                  name={input.name}
                  value={formData[input.name]}
                  onChange={(event) => handleChangeInput(event)}
                  onBlur={() => handleTouch(input.name)}
                  onFocus={() => handleTouch(input.name)}
                  error={handleError(input.name)}
                  type={input.type === 'password' ? (showPassword ? 'text' : 'password') : input.type}
                  helperText={handleError(input.name) && translate(fieldError(input.name))}
                  endAdornment={
                    input.type === 'password' && {
                      onClick: () => setShowPassword(!showPassword),
                      icon: showPassword ? <Visibility /> : <VisibilityOff />
                    }
                  }
                />
              );
            })}
            {!!radioGroup && (
              <CustomRadioGroup
                name={radioGroup.name}
                value={formData[radioGroup.name]}
                options={radioGroup.options}
                onChange={(event) => {
                  radioGroup.onChange && radioGroup.onChange({ event, formData, setFormData });
                  handleChangeInput(event);
                }}
              />
            )}
          </Grid>
          {!!message && (
            <Typography data-testid="message" align="center" className={`${error ? classes.errorText : classes.successText}`}>
              {message}
            </Typography>
          )}
          <CustomButton
            text='register'
            onClick={handleRegisterUser}
            disabled={Object.keys(formData).some((field) => !!fieldError(field))}
            loading={loading}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default CreateEntityForm;
