import axios from 'axios';

export const getPremiumPlans = () => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}plans`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const editPremiumPlans = (planId, body) => {
  return axios({
    method: 'patch',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}plans/${planId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};

export const getPremiumFeatures = () => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}plans/feature-category`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const editPremiumFeatures = (categoryName, body) => {
  return axios({
    method: 'patch',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}plans/feature-category/${categoryName}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};
