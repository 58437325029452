import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { nominalToPercent } from '../../../../../shared/functions/math';

const EventsTable = (props) => {
  const { t: translate } = useTranslation();

  const { events } = props;

  const eventTableColumns = [
    {
      id: 'eventType',
      label: translate('event type'),
      minWidth: 120,
      format: (value) => {
        if (value.match(/.*amortization.*/i)) {
          return translate('amortization');
        }
        return translate(value);
      }
    },
    { id: 'eventDate', label: translate('date'), minWidth: 80 },
    {
      id: 'eventYield',
      label: translate('yield'),
      minWidth: 80,
      align: 'right',
      format: (value) => value && `${nominalToPercent(value)}%`
    },
    {
      id: 'explicitValue',
      label: translate('Value'),
      minWidth: 80,
      align: 'right'
    }
  ];

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ width: '100%', maxHeight: 400 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {eventTableColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                events.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {eventTableColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

EventsTable.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      eventDate: PropTypes.string,
      eventType: PropTypes.string,
      eventYield: PropTypes.number
    })
  )
};

export default EventsTable;
