import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { color } from '../../../../shared';
import { checkSupportOrg, addToSupportOrg, removeFromSupportOrg } from '../../../../services/user';

const useStyles = makeStyles((theme) => ({
  loading: {
    marginLeft: '0.5rem',
    color: color.GorilaDefault
  }
}));

const ToggleSuportOrgModal = ({ open, closeModal, item: user, setMessage }) => {
  const { t: translate } = useTranslation();
  const [text, setText] = useState('');
  const [contentLoading, setContentLoading] = useState(true);
  const [confirmText, setconfirmText] = useState();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  const classes = useStyles();

  const validateSupportOrgUser = useCallback( async () => {
    try {
      const check = await checkSupportOrg(user.id);

      const inOrg = check.inSupportOrg;

      if (!inOrg) {
        setText('confirm add user to support org');
        setconfirmText('add');
      } else {
        setText('confirm remove user from support org');
        setconfirmText('remove');
      }
      setContentLoading(false);
    } catch {
      closeModal();
      setMessage(translate('something went wrong, try again later'));
    }
  }, [user, closeModal, setMessage, translate]);

  useEffect(() => {
    if (open && user?.id) {
      setContentLoading(true);
      validateSupportOrgUser();
    }
  }, [open, user, validateSupportOrgUser]);

  const confirm = async () => {
    setLoading(true);
    setClicked(true);

    try {
      if (confirmText === 'add') {
        await addToSupportOrg(user.id);
        setMessage(translate(`user added to support organization`));
      } else if (confirmText === 'remove') {
        await removeFromSupportOrg(user.id);
        setMessage(translate(`user removed from support organization`));
      }
    } catch {
      if (confirmText === 'add') {
        setMessage(translate(`user NOT added to support organization`));
      } else if (confirmText === 'remove') {
        setMessage(translate(`user NOT removed from support organization`));
      }
    }

    setLoading(false);
    setClicked(false);
    closeModal();
  };

  const cancel = async () => {
    closeModal();
    setClicked(false);
  };

  return <>
    <Dialog
      fullWidth
      open={open}
      onClose={closeModal}
      aria-labelledby="Diálogo de confirmação"
      aria-describedby="Diálogo de confirmação"
      data-testid="support-org-modal"
    >
      <DialogContent>
        <DialogContentText>
          {translate('toggleSupportOrg')}
        </DialogContentText>
        { contentLoading ? (
          <DialogContent data-testid="info" align='center'>
            <CircularProgress className={classes.loading} />
          </DialogContent>
        ) : (
          <DialogContentText data-testid="info">
            {translate(text)}
          </DialogContentText>
        )
        }
        {
          !contentLoading ? (
            <DialogActions>
              <Button onClick={cancel} variant='contained' data-testid='cancel' color='primary'>
                {translate('cancel')}
              </Button>
              <Button onClick={confirm} color='primary' data-testid='save' disabled={clicked} autoFocus>
                {translate(confirmText)}
                {loading && <CircularProgress className={classes.loading} size='1rem' />}
              </Button>
            </DialogActions>
          ) : ''
        }
      </DialogContent>
    </Dialog>
  </>;
};

export default ToggleSuportOrgModal;
