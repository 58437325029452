import React from 'react';
import { useTranslation } from 'react-i18next';
import { createOrganizationUserPortfolioRelation,
  removeOrganizationUserPortfolioRelation,
  getPortfolioImpersonateLink } from '../../../../services/organizations';
import { listPortfolioOrganizationUsers } from '../../../../services/portfolio';
import { Select, MenuItem } from '@material-ui/core';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';

export const OrgUsersTable = ({ setMessage, data: portfolioData, setOrgUsersCount }) => {
  const { t: translate } = useTranslation();
  const impersonateIconPath = `${process.env.REACT_APP_ICON_PATH}/Platform/Go-Gorila.svg`;

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove organization-user')
      },
      addTooltip: translate('add organization-user'),
      deleteTooltip: translate('remove organization-user')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization user portfolio already exists':
      return setMessage(translate('Organization user portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This user or portfolio doesnt belong to this organization'));
    case 'Request validation failed':
      return setMessage(translate('The organizationId or userId provided is invalid'));
    default:
      return setMessage(translate(`NOT ${serviceName} user`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const orgId = newData.organization;
      const userId = newData.user.id;
      const role = newData.role || 'VIEWER';
      const { id: portfolioId } = portfolioData;

      if (serviceName === 'inserted') {
        await service({ orgId, userId, portfolioId, role });
      } else {
        await service({ orgId, userId, portfolioId });
      }

      return setMessage(translate(`${serviceName} user`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const impersonatePortfolioWithUser = async (rowData) => {
    const impersonateUrl = await getPortfolioImpersonateLink(rowData.portfolio, rowData.user.id);
    window.open(impersonateUrl.data);
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createOrganizationUserPortfolioRelation, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationUserPortfolioRelation, oldData);
    }
  };

  const columns = [
    { title: 'UserId', field: 'user.id' },
    { title: translate('name'), field: 'user.name', editable: 'never' },
    { title: 'OrganizationId', field: 'organization' },
    {
      title: translate('role'),
      field: 'role',
      editComponent: (props) => (
        <Select
          value={props.value || 'VIEWER'}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="EDITOR">EDITOR</MenuItem>
          <MenuItem value="VIEWER">VIEWER</MenuItem>
        </Select>
      )
    }
  ];

  const actions = [
    {
      // TODO: make this button into a self contained component to be used in all tables applicable
      icon: () => (
        <img
          src={impersonateIconPath}
          alt='ImpersonateIcon'
          style={{ width: '20px', height: '20px' }}
        />
      ),
      tooltip: 'Impersonate portfolio with user',
      isFreeAction: false,
      onClick: (event, rowData) => {
        impersonatePortfolioWithUser(rowData);
      }
    }
  ];

  const searchPortfolioOrganizationUsers = async (offset, limit, search, count) => {
    const { data: response } = await listPortfolioOrganizationUsers({ portfolioId: portfolioData.id, offset, limit, search, count, populate: 'user' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchPortfolioOrganizationUsers}
        columns={columns}
        actions={actions}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setOrgUsersCount}
      />
    </>
  );
};

export default OrgUsersTable;
