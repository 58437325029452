import React from 'react';
import { CreateEntityForm } from '../../../shared/components';
import { createOrganization } from '../../../services/organizations';

const CreateOrganization = () => {
  const fields = [
    { name: 'name', type: 'text' },
    { name: 'taxId', type: 'text' }
  ];

  const validation = (formData, field) => {
    const value = formData[field].trim();

    if (field !== 'legacyAdvisor' && value === '') {
      return 'required field';
    }

    if (field === 'taxId') {
      if ( value !== '' && !/^[0-9]+$/.test(value)) {
        return 'field must contain only numbers';
      }
    }

    if (field === 'taxId') {
      if (value.length < 11 || value.length > 14) {
        return 'field must have between 11 and 14 characters';
      }
    }

    return '';
  };

  const errorMessage = (status)=> {
    switch (status) {
    case 400:
      return 'invalid parameters provided';
    case 409:
      return 'taxId already in use';
    case 403:
      return 'permission denied';
    default:
      return '';
    }
  };

  const action = async (formData) => {
    let error;
    let message;

    try {
      await createOrganization(formData);

      error = false;
      message = 'organization successfully registered';
    } catch (err) {
      error = true;
      const response = err.response;
      if (response?.status) {
        message = errorMessage(response.status);
      };
      if (!message) {
        message = 'error create organization';
      }
    }

    return {
      error,
      message
    };
  };

  return (
    <CreateEntityForm
      title='create organization'
      fields={fields}
      validation={validation}
      action={action}
    />
  );
};

export default CreateOrganization;
