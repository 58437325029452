import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import DropzoneCustomize from '../../dropzone/DropzoneCustomize';

const useStyles = makeStyles({
  containerLogo: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '60px 40px'
  },
  requirements: {
    maxWidth: '35rem'
  },
  dropzone: {
    flexBasis: '45%'
  },
  list: {
    paddingLeft: '1rem'
  }
});

const UploadFile = ({ uploadInfo, setMessage, handleUploadFile }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleFile = (accepted, rejected) => {
    setLoading(true);
    return accepted.length >= 1 ? handleAcceptedFile(accepted) : handleRejectedFile(rejected, uploadInfo.contentType);
  };

  const handleAcceptedFile = async (accepted) => {
    try {
      await handleUploadFile(accepted[0]);
      setLoading(false);
    } catch (error) {
      setMessage(translate('error uploading file'));
      setLoading(false);
    }
  };

  const handleRejectedFile = (rejected, contentType) => {
    setLoading(false);
    let message = translate('error uploading file');

    rejected.map((content) => {
      if (content?.type && !contentType.includes(content?.type)) {
        message = translate('file not supported');
      } else if (content?.size && content?.size > uploadInfo.maxSize) {
        message = translate('file exceeds the maximum file size');
      }
      return null;
    });

    setMessage(message);
  };

  return (
    <>
      <Paper className={classes.containerLogo}>
        <Grid className={classes.requirements}>
          <strong>{translate('Requirements')}</strong>
          <ul className={classes.list}>
            {uploadInfo && uploadInfo.requirements.map((requirement, index) => {
              return Array.isArray(requirement) ? (
                <ul key={index} className={classes.list}>
                  {requirement.map((subItem, subIndex) => {
                    return <li key={subIndex}>{translate(subItem)}</li>;
                  })}
                </ul>
              ) : <li key={index}>{translate(requirement, uploadInfo?.translateKeys)}</li>;
            })}
          </ul>
        </Grid>
        <Grid className={classes.dropzone}>
          <DropzoneCustomize
            handleFile={handleFile}
            maxSize={uploadInfo.maxSize}
            accept={uploadInfo.type}
            text={translate('drag or click here to select files (only @type)', { type: uploadInfo.type })}
            loading={loading}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default UploadFile;
