import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@material-ui/core';
import { createOrganizationUserPortfolioRelation, removeOrganizationUserPortfolioRelation } from '../../../../services/organizations';
import { listUserOrganizationPortfolios } from '../../../../services/user';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';

export const OrgPortfoliosTable = ({ setMessage, data: userData, setOrgPortfoliosCount }) => {
  const { t: translate } = useTranslation();

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove user-portfolio')
      },
      addTooltip: translate('add user-portfolio'),
      deleteTooltip: translate('remove user-portfolio')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization user portfolio already exists':
      return setMessage(translate('Organization user portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This user or portfolio doesnt belong to this organization'));
    case 'Request validation failed':
      return setMessage(translate('The organizationId or portfolioId provided is invalid'));
    default:
      return setMessage(translate(`NOT ${serviceName} portfolio`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const portfolioId = newData.portfolio !== undefined ? newData.portfolio.id : undefined;
      const { id: userId } = userData;
      const role = newData.role || 'VIEWER';
      const orgId = newData.organization;

      if (serviceName === 'inserted') {
        await service({ orgId, userId, portfolioId, role });
      } else {
        await service({ orgId, userId, portfolioId });
      }

      return setMessage(translate(`${serviceName} portfolio`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createOrganizationUserPortfolioRelation, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationUserPortfolioRelation, oldData);
    }
  };

  const columns = [
    { title: 'PortfolioId', field: 'portfolio.id' },
    { title: translate('portfolio name'), field: 'portfolio.name', editable: 'never' },
    { title: 'OrganizationId', field: 'organization' },
    {
      title: translate('role'),
      field: 'role',
      editComponent: (props) => (
        <Select
          value={props.value || 'VIEWER'}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="EDITOR">EDITOR</MenuItem>
          <MenuItem value="VIEWER">VIEWER</MenuItem>
        </Select>
      )
    }
  ];

  const searchUserOrganizations = async (offset, limit, search, count) => {
    const { data: response } = await listUserOrganizationPortfolios({ userId: userData.id, offset, limit, search, count, populate: 'portfolio' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchUserOrganizations}
        columns={columns}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setOrgPortfoliosCount}
      />
    </>
  );
};

export default OrgPortfoliosTable;
