import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import DateJsUtils from '@date-io/dayjs';
import './index.css';
import './i18n';

import theme from './config/theme';
import { ThemeProvider } from '@material-ui/styles';

import App from './App';
import { AuthProvider } from './context/authentication';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const app = (
  <MuiPickersUtilsProvider utils={DateJsUtils}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </MuiPickersUtilsProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
