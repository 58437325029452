import React, { useState, useEffect } from 'react';
import { uploadSinacorFile, validateSinacorFile } from '../../../../services/portfolio';
import { MegaBytes } from '../../../../shared/bytesConversion';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Checkbox, CircularProgress, Typography } from '@material-ui/core';
import { color, InformationModal } from '../../../../shared';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '../../../../shared/components';

const XLSX = require('xlsx');

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: '1rem'
  },
  loading: {
    marginRight: '0.5rem',
    color: color.White
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: '0px'
    }
  }
}));

const BrokerageNotes = ({ setMessage, data }) => {
  const classes = useStyles();

  const [resultData, setResultData] = useState();
  const [uploadData, setUploadData] = useState();
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(false);
  const [importCosts, setImportCosts] = useState(true);

  const { t: translate } = useTranslation();


  const handleCloseModal = () =>{
    setModal();
    setLoading(false);
    setResultData();
    setUploadData();
    setImportCosts(true);
  };

  const uploadInfo = {
    name: 'ZIP',
    type: '.zip',
    maxSize: 10*MegaBytes,
    maxZipFiles: process.env.REACT_APP_MAX_ZIP_FILES || 150,
    contentType: [
      'application/octet-stream',
      'application/zip-compressed',
      'application/zip',
      'application/x-zip-compressed',
      'multipart/x-zip'
    ],
    requirements: [
      'the file must be a ZIP, with a maximum size of 10MB and @maxZipFiles notes',
      'covered brokers and assets',
      [
        'btg, xp - stocks, bdrs, etfs, fiis, options and futures',
        'rico, clear - stocks, bdrs, etfs, fiis and options',
        'orama, nova futura - stocks, bdrs, etfs and fiis'
      ]
    ],
    translateKeys: {
      maxZipFiles: process.env.REACT_APP_MAX_ZIP_FILES || 150
    }
  };

  const handleUploadSinacor = async (uploadedFile) =>{
    const validateZip = await validateSinacorFile(uploadedFile, uploadInfo.maxZipFiles, uploadInfo.maxSize/MegaBytes);

    if (validateZip?.validation) {
      setModal({
        title: 'confirm upload file',
        content: {
          'file name': uploadedFile.path,
          'files number': validateZip.files
        },
        cancel: 'cancel',
        confirm: 'confirm',
        action: () => setUploadData(uploadedFile)
      });
    } else if (validateZip?.size > uploadInfo.maxSize/MegaBytes) {
      setMessage(translate('file exceeds the maximum file size'));
    } else if (validateZip?.files > uploadInfo.maxZipFiles) {
      setMessage(translate('exceeded maximum file limit in ZIP'));
    } else {
      setMessage(translate('error uploading file'));
    }
  };

  useEffect(() => {
    const uploadSinacor = async () => {
      setLoading(true);
      const uploadedData = await uploadSinacorFile(uploadData, data.id, importCosts);
      if (uploadedData) {
        setResultData(uploadedData);
      } else {
        handleCloseModal();
        setMessage(translate('there is already a process running for this user'));
      }
      setLoading(false);
      setUploadData();
    };

    if (uploadData) {
      uploadSinacor();
    }
  }, [importCosts, uploadData, data, setMessage, translate]);

  useEffect(() => {
    const handleDownloadReport = () =>{
      try {
        setLoading(true);

        const workbook = XLSX.utils.book_new();

        const trades = resultData?.trades.map((trade) => {
          if (trade.costs) {
            trade = {
              ...trade,
              brokerageCost: trade.costs.brokerage,
              othersCosts: trade.costs.others
            };
            delete trade.costs;
          }
          return trade;
        });

        const tradesWorksheet = XLSX.utils.json_to_sheet(trades);
        XLSX.utils.book_append_sheet(workbook, tradesWorksheet, 'Trades');

        const errors = resultData?.errors.map((error) => {
          delete error.trade;
          return error;
        });

        const errorsWorksheet = XLSX.utils.json_to_sheet(errors);
        XLSX.utils.book_append_sheet(workbook, errorsWorksheet, 'Errors');

        XLSX.writeFile(workbook, `${resultData.processStatusId}.xlsx`);
      } catch {
        setMessage(translate('error generating the report'));
      }
      setLoading(false);
    };

    const handleResultModalContent = () =>{
      setModal({
        title: 'uploaded files',
        content: {
          'process id': resultData?.processStatusId,
          'extracted operations': resultData?.trades?.length,
          'extraction errors': resultData?.errors?.length
        },
        cancel: 'close',
        confirm: 'download report',
        action: handleDownloadReport
      });
    };

    if (resultData) {
      handleResultModalContent();
    }
  }, [resultData, setMessage, translate]);

  return (
    <>
      <UploadFile
        uploadInfo={uploadInfo}
        setMessage={setMessage}
        handleUploadFile={handleUploadSinacor}
      />
      { !!modal &&
      <InformationModal
        modalOpen={!!modal}
        closeModal={handleCloseModal}
        title={translate(modal.title)}
      >
        { Object.keys(modal.content).map((contentKey, index) => (
          <Typography key = {index}>
            <b>{translate(contentKey)}:</b> {modal.content[contentKey]}
          </Typography>
        ))}

        {modal.title === 'confirm upload file' &&
          <Typography style={{ display: 'flex' }}>
            <b>{translate('upload costs')}:&nbsp;</b>
            <span className={classes.checkbox}>
              <Checkbox
                checked={importCosts}
                size='small'
                color='primary'
                onClick={()=> setImportCosts(!importCosts)}
              />
            </span>
          </Typography>}

        <Grid container className={classes.grid}>
          <Button
            onClick={() => handleCloseModal()}
            color='primary'
            disabled={loading}
            className={classes.button}
          >
            {translate(modal.cancel)}
          </Button>
          <Button
            onClick={() => modal.action()}
            variant='contained'
            color='primary'
            disabled={loading}
            autoFocus
          >
            {loading && <CircularProgress data-testid='loading' className={classes.loading} size='1rem'/>}
            {translate(modal.confirm)}
          </Button>
        </Grid>
      </InformationModal>
      }
    </>
  );
};

export default BrokerageNotes;
