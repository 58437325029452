import React, { useState } from 'react';
import { getImpersonateLink, getUserList } from '../../../services/user';
import { SearchTable } from '../../../shared/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Support } from '../../../assets/images/icons';
import {
  DeleteUserModal,
  EditUserModal,
  PortfolioRerunModal,
  ToggleActiveModal,
  ToggleSuportOrgModal
} from './Modals';
import { Grid, Paper } from '@material-ui/core';

const UserSearch = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [churnModalOpen, setChurnModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rerunModalOpen, setRerunModalOpen] = useState(false);
  const [supportOrgModalOpen, setSupportOrgModalOpen] = useState(false);

  const history = useHistory();
  const { t: translate } = useTranslation();

  const impersonateUser = async ({ dataItem: user, setMessage }) => {
    setMessage(translate(`you'll be redirected to Gorila in instants`));
    try {
      const impersonate = await getImpersonateLink(user.id);
      window.open(impersonate.data);
      setMessage('');
    } catch (e) {
      setMessage(translate(`something went wrong, try again later`));
    }
  };

  const goToDetails = ({ dataItem: user }) => {
    history.push(`/user/details/${user.id}`, { userData: user });
  };

  const tableFields = [{
    name: 'userId',
    key: 'id',
    sortable: true,
    translateData: false
  },
  {
    name: 'name',
    key: 'name',
    sortable: true,
    translateData: false
  },
  {
    name: 'email',
    key: 'email',
    sortable: true,
    translateData: false
  }];

  const tableActions =[{
    type: 'user details',
    authPermission: 'read:users',
    action: goToDetails,
    srcImg: 'Nav/Clients'
  },
  {
    type: 'impersonate user',
    authPermission: 'read:users',
    action: impersonateUser,
    srcImg: 'Platform/Go-Gorila'
  },
  {
    inMenu: true,
    type: 'toggleUserActive',
    authPermission: 'update:users',
    srcImg: 'Platform/Check',
    action: () => setChurnModalOpen(true),
    modal: {
      component: ToggleActiveModal,
      open: churnModalOpen,
      closeModal: () => setChurnModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'updateUser',
    authPermission: 'update:users',
    srcImg: 'Platform/Edit',
    action: () => setEditModalOpen(true),
    modal: {
      component: EditUserModal,
      open: editModalOpen,
      closeModal: () => setEditModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'deleteUser',
    authPermission: 'delete:users',
    srcImg: 'Platform/Delete',
    action: () => setDeleteModalOpen(true),
    modal: {
      component: DeleteUserModal,
      open: deleteModalOpen,
      closeModal: () => setDeleteModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'rerunPortfolio',
    authPermission: 'update:portfolio',
    srcImg: 'Platform/Sync',
    action: () => setRerunModalOpen(true),
    modal: {
      component: PortfolioRerunModal,
      open: rerunModalOpen,
      closeModal: () => setRerunModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'toggleSupportOrg',
    authPermission: 'update:users',
    srcImg: Support,
    action: () => setSupportOrgModalOpen(true),
    modal: {
      component: ToggleSuportOrgModal,
      open: supportOrgModalOpen,
      closeModal: () => setSupportOrgModalOpen(false)
    }
  }];

  return (
    <Grid container justifyContent="center">
      <Grid item style={{ marginTop: '2rem', marginBottom: '2rem', width: '75%' }}>
        <Paper>
          <SearchTable
            name='user'
            fields={tableFields}
            searchService={getUserList}
            actions={tableActions}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserSearch;
