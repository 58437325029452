import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, Typography, Grid, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { createOrganizationUserPortfolioRelation,
  listOrganizationUserPortfolios,
  removeOrganizationUserPortfolioRelation,
  getPortfolioImpersonateLink,
  createOrgUserPortfolioList } from '../../../../services/organizations';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';
import { InformationModal, color } from '../../../../shared';
import PublishIcon from '@material-ui/icons/Publish';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: '1rem'
  },
  modalLoading: {
    marginRight: '0.5rem',
    color: color.White
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const UserPortfoliosTable = ({ setMessage, data: orgData, setUserPortfoliosCount }) => {
  const classes = useStyles();

  const { t: translate } = useTranslation();

  const impersonateIconPath = `${process.env.REACT_APP_ICON_PATH}/Platform/Go-Gorila.svg`;
  const [modal, setModal] = useState();
  const [modalLoading, setModalLoading] = useState(false);

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove user-portfolio')
      },
      addTooltip: translate('add organization user portfolio'),
      deleteTooltip: translate('remove user-portfolio')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization user portfolio already exists':
      return setMessage(translate('Organization user portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This user or portfolio doesnt belong to this organization'));
    case 'Request validation failed':
      return setMessage(translate('The portfolioId or userId provided is invalid'));
    default:
      return setMessage(translate(`NOT ${serviceName} user`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const portfolioId = newData.portfolio.id;
      const userId = newData.user.id;
      const role = newData.role || 'VIEWER';
      const { id: orgId } = orgData;

      if (serviceName === 'inserted') {
        await service({ orgId, userId, portfolioId, role });
      } else {
        await service({ orgId, userId, portfolioId });
      }

      return setMessage(translate(`${serviceName} user`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const impersonatePortfolioWithUser = async (rowData) => {
    const impersonateUrl = await getPortfolioImpersonateLink(rowData.portfolio.id, rowData.user.id);
    window.open(impersonateUrl.data);
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createOrganizationUserPortfolioRelation, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationUserPortfolioRelation, oldData);
    }
  };

  const columns = [
    { title: 'PortfolioId', field: 'portfolio.id' },
    { title: 'UserId', field: 'user.id' },
    { title: translate('user name'), field: 'user.name', editable: 'never' },
    { title: translate('portfolio name'), field: 'portfolio.name', editable: 'never' },
    {
      title: translate('role'),
      field: 'role',
      editComponent: (props) => (
        <Select
          value={props.value || 'VIEWER'}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="EDITOR">EDITOR</MenuItem>
          <MenuItem value="VIEWER">VIEWER</MenuItem>
        </Select>
      )
    }
  ];

  const handleDownloadReport = (result) =>{
    setModalLoading(true);

    try {
      const workbook = XLSX.utils.book_new();

      const resultWorksheet = XLSX.utils.json_to_sheet(result);
      XLSX.utils.book_append_sheet(workbook, resultWorksheet, 'Result');

      XLSX.writeFile(workbook, `result.xlsx`);
    } catch {
      setMessage(translate('error generating the report'));
    }
    setModalLoading(false);
  };

  const createRelationList = async (relations) => {
    setModalLoading(true);

    try {
      const { data } = await createOrgUserPortfolioList(orgData.id, relations);

      setModal({
        title: 'uploaded files',
        content: {
          'total relations sent': data?.result.length,
          'total relations created': data.totalCreated,
          'total relations not created': data.totalNotCreated
        },
        cancel: 'close',
        confirm: 'download report',
        action: () => {
          handleDownloadReport(data.result);
        }
      });
    } catch (error) {
      setMessage('something went wrong, try again later');
    }

    setModalLoading(false);
  };

  const readUploadFile = async (file) => {
    setModalLoading(true);

    try {
      const data = await file.arrayBuffer();

      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const fileHeaders = Object.keys(worksheet).map((key) => worksheet[key].v);

      if (!['UserId', 'PortfolioId', 'Role'].every((header) => fileHeaders.includes(header))) {
        throw new Error('invalid file headers');
      }

      const relations = XLSX.utils.sheet_to_json(worksheet);

      const fixedRelations = relations.map((relation) => {
        const user = relation.UserId;
        const portfolio = relation.PortfolioId;
        const role = relation.Role;

        return {
          user,
          portfolio,
          role
        };
      });

      setModal({
        title: 'confirm upload file',
        content: {
          'organization': orgData.id,
          'file name': file.name,
          'total relations': fixedRelations.length
        },
        cancel: 'cancel',
        confirm: 'confirm',
        action: () => {
          createRelationList(fixedRelations);
        }
      });
    } catch (error) {
      setMessage('invalid file');
    }

    setModalLoading(false);
  };

  const handleUploadFile = () => {
    setModal({
      title: 'confirm upload file',
      content: {
        'organization': orgData.id,
        'file model': <Link style={{ textDecoration: 'none' }} to="/modelo-planilha-relações.xlsx" target="_blank" download> Download </Link>
      },
      cancel: 'cancel',
      confirm: 'select file'
    });
  };

  const actions = [
    {
      icon: () => (
        <img
          src={impersonateIconPath}
          alt='ImpersonateIcon'
          style={{ width: '20px', height: '20px' }}
        />
      ),
      tooltip: translate('impersonate portfolio with user'),
      isFreeAction: false,
      onClick: (event, rowData) => {
        impersonatePortfolioWithUser(rowData);
      }
    },
    {
      icon: PublishIcon,
      tooltip: translate('upload user-portfolio relations'),
      isFreeAction: true,
      onClick: () => handleUploadFile()
    }
  ];

  const searchOrganizationUserPortfolios = async (offset, limit, search, count) => {
    const { data: response } = await listOrganizationUserPortfolios({ orgId: orgData.id, offset, limit, search, count, populate: 'user,portfolio' });
    return response;
  };

  return (
    <>
      { !!modal &&
      <InformationModal
        modalOpen={!!modal}
        closeModal={()=> setModal()}
        title={translate(modal.title)}
      >
        { Object.keys(modal.content).map((contentKey, index) => (
          <Typography key = {index}>
            <b>{translate(contentKey)}:</b> {modal.content[contentKey]}
          </Typography>
        ))}

        <Grid container className={classes.grid}>
          <Button
            onClick={() => setModal()}
            color='primary'
            disabled={modalLoading}
            className={classes.button}
          >
            {translate(modal.cancel)}
          </Button>
          { modal.confirm === 'select file' ?
            <Button
              variant='contained'
              color='primary'
              component="label"
              disabled={modalLoading}
            >
              {modalLoading && <CircularProgress data-testid='loading' className={classes.modalLoading} size='1rem'/>}
              {translate(modal.confirm)}
              <input
                type="file"
                accept=".xlsx"
                onChange={(event) => readUploadFile(event.target.files[0])}
                hidden
              />
            </Button> :
            <Button
              onClick={() => modal.action()}
              variant='contained'
              color='primary'
              disabled={modalLoading}
              autoFocus
            >
              {modalLoading && <CircularProgress data-testid='loading' className={classes.modalLoading} size='1rem'/>}
              {translate(modal.confirm)}
            </Button>
          }
        </Grid>
      </InformationModal>
      }
      <RemoteTable
        setMessage={setMessage}
        dataService={searchOrganizationUserPortfolios}
        columns={columns}
        actions={actions}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setUserPortfoliosCount}
      />
    </>
  );
};

export default UserPortfoliosTable;
