import React from 'react';
import { loading, setAuthenticationState, setErrorMessage } from './../shared/ActionTypes';
const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

const initialState = {
  errorMessage: null,
  loadingLogin: false,
  isAuthenticate: false,
  isAccessToEtlAuthorized: false
};

function authReducer(state = initialState, action) {
  switch (action.type) {
  case loading: {
    return { ...state, loadingLogin: true, errorMessage: null };
  }
  case setAuthenticationState: {
    return { ...state, isAuthenticate: action.validToken, loadingLogin: false, errorMessage: null };
  }
  case setErrorMessage: {
    return { ...state, errorMessage: action.message, loadingLogin: false, validToken: false };
  }
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(authReducer, {});
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error(`useAuthDispatch must be used within a AuthenticationProvider`);
  }
  return context;
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error(`useAuthState must be used within a AuthenticationProvider`);
  }
  return context;
}

export {
  AuthProvider,
  useAuthDispatch,
  useAuthState
};
