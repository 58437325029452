import React from 'react';
import NotificationBannerPreview from './NotificationBannerPreview';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
    width: '25rem'
  },
  button: {
    borderRadius: '20px'
  },
  buttonEdit: {
    marginRight: '1rem'
  }
}));

const NotificationActive = () => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const notificationList = {
    notifications: [
      {
        hasVariable: false,
        image: '',
        title: 'Olá {username}',
        message: '{qty}',
        startDate: '25/05/2019',
        endDate: '02/06/2019',
        fromColor: '#3366FF',
        toColor: '#24C060',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      },
      {
        hasVariable: false,
        image: '',
        title: 'Olá {nickname}',
        message: '{totalValue}',
        startDate: '10/06/2019',
        endDate: '28/06/2019',
        fromColor: '#ed4581',
        toColor: '#fcbd1f',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      },
      {
        hasVariable: false,
        image: '',
        title: 'Olá {email}',
        message: '{broker}',
        startDate: '01/07/2019',
        endDate: '01/08/2019',
        fromColor: '#815ae8',
        toColor: '#0DD6F4',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      }
    ]
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
      {notificationList.notifications.map((notification, index) => {
        return (
          <Grid item key={index}>
            <Paper className={classes.paper}>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                {translate('active notification from @startDate to @endDate', { startDate: notification.startDate, endDate: notification.endDate })}
              </Typography>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item>
                  <NotificationBannerPreview hasVariable={notification.hasVariable}
                    image={notification.image}
                    title={notification.title}
                    message={notification.message}
                    linkBtnText={notification.linkBtnText}
                    linkBtnAnchor={notification.linkBtnAnchor}
                    fromColor={notification.fromColor}
                    toColor={notification.toColor} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" className={`${classes.button} ${classes.buttonEdit}`}>
                    {translate('update')}
                  </Button>
                  <Button color="primary" className={classes.button}>
                    {translate('remove')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default NotificationActive;
