import React, { useState } from 'react';
import { color } from '../../../shared';
import SourceForm from './SourceForm';
import DestForm from './DestForm';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowRightIcon } from '../../../assets/images/icons/index';
import { useTranslation } from 'react-i18next';
import {
  Snackbar,
  useMediaQuery,
  CircularProgress,
  Button,
  Grid
} from '@material-ui/core';
import {
  getUserDetails,
  getOrganizationUserDetails
} from '../../../services/user';
import {
  mirrorOrganizationUserPortfolios
} from '../../../services/organizations';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '1rem',
    borderRadius: '20px'
  },
  loading: {
    marginLeft: '0.5rem',
    color: color.White
  },
  arrowRightIcon: {
    width: '30px'
  }
}));
const Relations = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const desktop = useMediaQuery('(min-width:992px)');
  const [sourceSearch, setSourceSearch] = useState({ searchingValue: '', typingTimeout: 0, foundValues: [] });
  const [destSearch, setDestSearch] = useState({ searchingValue: '', typingTimeout: 0, foundValues: [] });
  const [selectedValues, setSelectedValues] = useState({ sourceChilds: [], dest: [] });
  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const resetStatesInitialValue = () => {
    setSourceSearch({ searchingValue: '', typingTimeout: 0, foundValues: [] });
    setDestSearch({ searchingValue: '', typingTimeout: 0, foundValues: [] });
    setSelectedValues({ sourceChilds: [], dest: [] });
    setIsAllChecked(false);
    setLoading(false);
    setIsChecked({});
  };

  const handleSourceInput = (event) => {
    const searchField = event.target.value;
    if (sourceSearch.typingTimeout) {
      clearTimeout(sourceSearch.typingTimeout);
    }

    setSourceSearch({
      searchingValue: searchField,
      typingTimeout: setTimeout(async () => {
        try {
          const { data } = await getOrganizationUserDetails(searchField);

          const userOrgs = data.records.map((orgUser) => ({ orgId: orgUser.organization.id, name: orgUser.organization.name, role: orgUser.role }));

          setSourceSearch({ ...sourceSearch, searchingValue: searchField, foundValues: userOrgs });
          setSelectedValues({ ...selectedValues, sourceChilds: [] });
          setIsAllChecked(false);
          setIsChecked({});
        } catch (error) {
          setMessage(translate('it was not possible to search user details'));
        }
      }, 1500)
    });
  };

  const handleDestInput = (event) => {
    const searchField = event.target.value;
    if (destSearch.typingTimeout) {
      clearTimeout(destSearch.typingTimeout);
    }
    setDestSearch({
      searchingValue: searchField,
      typingTimeout: setTimeout(async () => {
        try {
          const { data: user } = await getUserDetails(searchField);
          setDestSearch({ ...destSearch, searchingValue: searchField, foundValues: [user] });
        } catch (error) {
          setMessage(translate('it was not possible to search user details'));
        }
      }, 1500)
    });
  };

  const handleTransfer = () => {
    if (loading) return;
    setLoading(true);
    const sourceUserId = sourceSearch.searchingValue;
    const sourceOrgs = selectedValues.sourceChilds;
    const destinationUserIds = selectedValues.dest.map((dest) => dest.id);
    const promisse = mirrorOrganizationUserPortfolios(sourceUserId, sourceOrgs, destinationUserIds);
    promisse.then((response) => {
      resetStatesInitialValue();
      const { data } = response;
      setMessage(`O total de ${data.successes.length} portfólios em organizações foram espelhados com sucesso.`);
    }).catch((error) => {
      resetStatesInitialValue();
      console.log(error);
    });
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" />
      <Grid container alignItems="center" justifyContent="center">
        <SourceForm item childs={sourceSearch} handleSourceInput={handleSourceInput} selectedValues={selectedValues} setSelectedValues={setSelectedValues} isChecked={isChecked} setIsChecked={setIsChecked} isAllChecked={isAllChecked} setIsAllChecked={setIsAllChecked} />
        {
          desktop && <img className={classes.arrowRightIcon} src={ArrowRightIcon} alt="right arrow" />
        }
        <DestForm item dests={destSearch} searchingValue={destSearch.searchingValue} handleDestInput={handleDestInput} selectedValues={selectedValues} setSelectedValues={setSelectedValues} setDestSearch={setDestSearch} />
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleTransfer}
          disabled={!(selectedValues.sourceChilds.length && selectedValues.dest.length)}
          className={classes.button}
        >
          {translate('mirror associations')}
          {
            loading &&
            <CircularProgress data-testid="loading" className={classes.loading} size="1rem" />
          }
        </Button>
      </Grid>
      {
        message &&
        <Snackbar
          data-testid="message"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          key={`bottom,center`}
          open={true}
          onClose={() => setMessage('')}
          message={message}
        />
      }
    </>
  );
};

export default Relations;
