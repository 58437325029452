import { Button, Grid, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createCoupon,
  deleteCoupon,
  editCoupon
} from '../../../services/coupon';
import { color } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: color.GorilaDefault
  },
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    position: 'absolute',
    backgroundColor: '#FFF',
    boxShadow: '7px 7px 10px rgb(0 0 0 / 30%)',
    padding: '20px'
  },
  form: {
    width: '100%',
    margin: '30px 0'
  },
  containerInput: {
    position: 'relative',
    padding: '0 45px',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    margin: '10px',
    width: '100px',
    borderRadius: '20px'
  },
  buttonDiv: {
    textAlign: 'center'
  },
  select: {
    width: '200px',
    marginTop: '15px'
  }
}));

export default function CouponModals(props) {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const modal = props.modal[0];
  const tableRow = props.modal[1];

  const initialCreateCouponState = {
    code: '',
    cycles: '',
    from: '',
    until: '',
    amount: '',
    advisorId: '',
    plan: ''
  };
  const [createNewCouponValue, setCreateNewCouponValue] = useState([
    initialCreateCouponState
  ]);
  const inputCreateCouponState = [
    { name: 'code', type: 'text', placeholder: 'EXEMPLO-1', error: false },
    { name: 'cycles', type: 'number', placeholder: '999', error: false },
    { name: 'from', type: 'date', error: false },
    { name: 'until', type: 'date', error: false },
    { name: 'amount', type: 'number', placeholder: '999', error: false },
    {
      name: 'advisorId',
      type: 'number',
      placeholder: 'OPCIONAL',
      error: false
    },
    { name: 'plan', type: 'select', placeholder: '', error: false }
  ];
  const [inputCreateCouponValue, setInputCreateCouponValue] = useState(
    inputCreateCouponState
  );
  const initialEditCouponState = {
    from: '',
    until: ''
  };
  const [editCouponValue, setEditCouponValue] = useState([
    initialEditCouponState
  ]);
  const inputEditCoupon = [
    { name: 'from', type: 'date', dateSetting: { locale: 'pt-BR' } },
    { name: 'until', type: 'date', dateSetting: { locale: 'pt-BR' } }
  ];

  const handleCreateCouponInput = (event, index) => {
    const { name, value, type, checked } = event.target;
    inputCreateCouponState.find((input) => input.name === name).error = !value;
    setInputCreateCouponValue(inputCreateCouponState);
    const list = [...createNewCouponValue];
    const updateUser = {
      ...createNewCouponValue[index],
      [name]: type === 'checkbox' ? checked : value
    };
    list.splice(index, 1, updateUser);
    setCreateNewCouponValue(list);
  };

  const handleCreateCoupon = () => {
    const newCoupon = {
      code: createNewCouponValue[0].code.toUpperCase(),
      cycles: Number(createNewCouponValue[0].cycles),
      from: createNewCouponValue[0].from,
      until: createNewCouponValue[0].until,
      amount: Number(createNewCouponValue[0].amount),
      advisorId: createNewCouponValue[0].advisorId,
      plan: createNewCouponValue[0].plan
    };

    if (!newCoupon.advisorId) delete newCoupon.advisorId;

    if (newCoupon.plan === 'all') delete newCoupon.plan;

    newCoupon.amount = Number(newCoupon.amount);
    newCoupon.cycles = Number(newCoupon.cycles);
    newCoupon.advisorId = Number(newCoupon.advisorId);
    createCoupon(newCoupon);
    setCreateNewCouponValue([initialCreateCouponState]);
    props.closeModal();
  };

  const disabledButtonCreate = () => {
    const fromDate = dayjs(createNewCouponValue[0].from, 'YYYY-MM-DD');
    const untilDate = dayjs(createNewCouponValue[0].until, 'YYYY-MM-DD');

    const isValidCode = createNewCouponValue[0].code.length > 0;
    const isValidAmount = createNewCouponValue[0].amount.length > 0;
    const isValidCycles = createNewCouponValue[0].cycles.length > 0;
    const isValidFrom = fromDate.isValid() && fromDate.isBefore(dayjs());
    const isValidUntil = untilDate.isValid() && untilDate.isAfter(dayjs());
    const isValidPlan = createNewCouponValue[0].plan.length > 0;

    return !(
      isValidUntil &&
      isValidFrom &&
      isValidCode &&
      isValidAmount &&
      isValidCycles &&
      isValidPlan
    );
  };

  const handleEditCouponInput = (event, index) => {
    const { name, value, type, checked } = event.target;
    const list = [...editCouponValue];
    const updateUser = {
      ...editCouponValue[index],
      [name]: type === 'checkbox' ? checked : value
    };
    list.splice(index, 1, updateUser);
    setEditCouponValue(list);
  };

  const handleEditCoupon = () => {
    editCoupon(tableRow.code, editCouponValue[0]);
    setEditCouponValue([initialEditCouponState]);
    props.closeModal();
  };

  const disabledButtonEdit = () => {
    const fromDate = dayjs(editCouponValue[0].from, 'YYYY-MM-DD');
    const untilDate = dayjs(editCouponValue[0].until, 'YYYY-MM-DD');

    const isValidFrom = fromDate.isValid() && fromDate.isBefore(dayjs());
    const isValidUntil = untilDate.isValid() && untilDate.isAfter(dayjs());

    return !(isValidUntil && isValidFrom);
  };

  const createModal = [
    <div key='createModal' className={classes.container}>
      <h2 className={classes.title}>{translate('create coupon')}</h2>
      <form noValidate autoComplete="off" className={classes.form}>
        {createNewCouponValue.map((user, i) => {
          return (
            <Grid container key={i} className={classes.containerInput}>
              {inputCreateCouponValue.map((input, index) => {
                return input.name !== 'plan' ? (
                  <TextField
                    required={input.name !== 'advisorId' ? true : false}
                    key={index}
                    onKeyDown={(e) => input.type === 'date' ? e.preventDefault() : ''}
                    onChange={(event) => handleCreateCouponInput(event, i)}
                    name={input.name}
                    label={translate(input.name)}
                    type={input.type}
                    placeholder={input.placeholder}
                    helperText={input.error ? translate('required field') : ''}
                    error={input.name !== 'advisorId' ? input.error : false}
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      'data-lpignore': 'true',
                      'role': 'textbox'
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                ) : (
                  <Select
                    required
                    displayEmpty
                    key={index}
                    onChange={(event) => handleCreateCouponInput(event, i)}
                    name={input.name}
                    label={translate(input.name)}
                    defaultValue=''
                    className={classes.select}
                    variant='outlined'
                  >
                    <MenuItem value='' disabled>
                      {translate('plan')}
                    </MenuItem>
                    <MenuItem value={'poppy_mensal'}>{translate('poppy monthly')}</MenuItem>
                    <MenuItem value={'poppy_anual'}>{translate('poppy yearly')}</MenuItem>
                    <MenuItem value={'kong_mensal'}>{translate('kong monthly')}</MenuItem>
                    <MenuItem value={'kong_anual'}>{translate('kong yearly')}</MenuItem>
                    <MenuItem value={'all'}>{translate('all')}</MenuItem>
                  </Select>
                );
              })}
            </Grid>
          );
        })}
      </form>
      <div className={classes.buttonDiv}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={props.closeModal}
        >
          {translate('close')}
        </Button>
        <Button
          disabled={disabledButtonCreate()}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleCreateCoupon}
        >
          {translate('create')}
        </Button>
      </div>
    </div>
  ];

  const editModal = [
    <div key='editModal' className={classes.container}>
      <h2 className={classes.title}>{translate('edit coupon')}</h2>
      <form noValidate autoComplete="off" className={classes.form}>
        {editCouponValue.map((user, i) => {
          return (
            <Grid container key={i} className={classes.containerInput}>
              {inputEditCoupon.map((input, index) => {
                return (
                  <TextField
                    key={index}
                    onKeyDown={(e) => input.type === 'date' ? e.preventDefault() : ''}
                    onChange={(event) => handleEditCouponInput(event, i)}
                    name={input.name}
                    label={translate(input.name)}
                    type={input.type}
                    placeholder={input.placeholder}
                    error={input.error}
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      'data-lpignore': 'true',
                      'role': 'textbox'
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                );
              })}
            </Grid>
          );
        })}
      </form>
      <div className={classes.buttonDiv}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={props.closeModal}
        >
          {translate('close')}
        </Button>
        <Button
          disabled={disabledButtonEdit()}
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
          onClick={handleEditCoupon}
        >
          {translate('edit')}
        </Button>
      </div>
    </div>
  ];

  const deleteModal = [
    <div key='deleteModal' className={classes.container}>
      <h2 className={classes.title}>
        {translate('do you want to delete this coupon?')}
      </h2>
      <div className={classes.buttonDiv}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={props.closeModal}
        >
          {translate('no')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => {
            deleteCoupon(tableRow.code);
            props.closeModal();
          }}
        >
          {translate('yes')}
        </Button>
      </div>
    </div>
  ];

  const modals = [...createModal, ...editModal, ...deleteModal];

  return (
    <div>
      <Modal
        open={props.visible}
        onClose={props.closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modals[modal]}
      </Modal>
    </div>
  );
}
