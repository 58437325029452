import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableHeaderCell: {
    textTransform: 'capitalize',
    fontWeight: '600'
  }
});

const Table = ({ headers, children, arialabel, testid, data, emptyTableMessage }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      <MaterialTable aria-label={translate(arialabel)} data-testid={testid}>
        <TableHead>
          <TableRow>
            {
              headers.map((header) => (
                <TableCell key={header} className={classes.tableHeaderCell}>{translate(header)}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data && data.length > 0 ?
              children :
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="h6" color="textSecondary" align="center" data-testid="table-message" gutterBottom>
                    {translate(emptyTableMessage)}
                  </Typography>
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </MaterialTable>
    </>
  );
};

export default Table;
