import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const InformationModal = ({ modalOpen, closeModal, title, children }) => {
  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby={title}
      aria-describedby={title}
      data-testid="informationModal"
    >
      <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default InformationModal;
