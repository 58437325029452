import { Container, Snackbar, makeStyles } from '@material-ui/core';
import React from 'react';
import DetailsHeader from './DetailsHeader';
import DetailsTabs from './DetailsTabs';

const useStyles = makeStyles( (theme) => ({
  snackBarContent: {
    '& .MuiSnackbarContent-root': {
      justifyContent: 'center'
    }
  }
}));

const DetailsPage = ({ data, header, tabs, loading, message, setMessage }) => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <DetailsHeader
          title={header?.title}
          fields={header?.fields}
          imageUrl={header?.imageUrl}
          loading={loading}
        />
        <DetailsTabs
          tabs={tabs}
          data={data}
          loading={loading}
          setMessage={setMessage}
        />
      </Container>
      {
        message &&
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setMessage('')}
          open={true}
          message={message}
          className={classes.snackBarContent}
        />
      }
    </>
  );
};

export default DetailsPage;
