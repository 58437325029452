import { Backdrop, Button, Modal } from '@material-ui/core';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import Form from '@rjsf/material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { editPremiumPlans } from '../../../services/premiumData';
import { color } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: color.GorilaDefault,
    height: '5px'
  },
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    position: 'absolute',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    boxShadow: '7px 7px 10px rgb(0 0 0 / 30%)',
    padding: '10px'
  },
  button: {
    margin: '10px',
    width: '100px',
    borderRadius: '10px',
    color: '#FFF'
  },
  buttonDiv: {
    textAlign: 'center'
  },
  formContainer: {
    width: '300px'
  }
}));


const EditPremiumPlans = (props) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  if (props.row) {
    // eslint-disable-next-line no-var
    var schema = {
      type: 'object',
      properties: {
        value: { type: 'number', title: translate('Value'), default: props.row.value },
        discount: { type: 'string', title: translate('Discount'), default: props.row.discount },
        features: { type: 'string', title: translate('Features'), default: props.row.subscriptionInfo.features },
        warning: { type: 'string', title: translate('Warning'), default: props.row.subscriptionInfo.warning },
        included: { type: 'string', title: translate('Included'), default: props.row.subscriptionInfo.included },
        order: { type: 'number', title: translate('Order'), default: props.row.subscriptionInfo.order },
        paymentPeriod: { type: 'string', title: translate('PaymentPeriod'), default: props.row.subscriptionInfo.paymentPeriod }
      }
    };

    if (props.row.isFree) {
      delete schema.properties.discount;
      delete schema.properties.warning;
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: color.GorilaDefault
      },
      secondary: {
        main: color.GorilaSecondary
      }
    },
    props: {
      MuiTextField: {
        size: 'small',
        variant: 'outlined'
      }
    }
  });

  const uiSchema = {
    features: {
      'ui:widget': 'textarea'
    }
  };

  const onSubmit = (event) => {
    const formData = props.row;
    const planId = props.row.planId;

    formData.value = event.formData.value;
    formData.discount = event.formData.discount;
    formData.subscriptionInfo.features = event.formData.features.split('\n');
    formData.subscriptionInfo.warning = event.formData.warning;
    formData.subscriptionInfo.included = event.formData.included;
    formData.subscriptionInfo.order = event.formData.order;
    formData.subscriptionInfo.paymentPeriod = event.formData.paymentPeriod;

    delete formData.planId;
    delete formData.tier;
    delete formData.subscriptionInfo.image;

    if (formData.isFree) {
      delete formData.code;
      delete formData.gateway;
      delete formData.period;
      delete formData.subscriptionInfo.warning;
    };

    editPremiumPlans(planId, formData);

    props.closeModal();
  };

  return (
    <Modal
      open={props.visible}
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300
      }}
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{translate('EditingModal').toUpperCase()}</h2>
        <MuiThemeProvider theme={theme}>
          <Form schema={schema} uiSchema={uiSchema} onSubmit={(event) => onSubmit(event)}>
            <div className={classes.buttonDiv}>
              <Button
                type='button'
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={props.closeModal}
              >
                {translate('close')}
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                className={classes.button}
                endIcon={<Edit />}
              >
                {translate('edit')}
              </Button>
            </div>
          </Form>
        </MuiThemeProvider>
      </div>
    </Modal >
  );
};

export default EditPremiumPlans;
