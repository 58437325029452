/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import formatUnicorn from 'format-unicorn';
import { loadDictionary } from '../../shared';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '25rem',
    height: '20rem',
    backgroundImage: (props) => `linear-gradient(to right, ${props.fromColor}, ${props.toColor})`
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    color: '#fff',
    borderRadius: '20px',
    width: '60%'
  },
  text: {
    color: '#fff',
    padding: '1rem'
  }
}));

const NotificationBannerPreview = (props) => {
  const classes = useStyles(props);
  let { hasVariable, image, title, message, linkBtnText, linkBtnAnchor } = props;
  const [state, setState] = useState({ variables: loadDictionary(props.type) });

  if (!hasVariable) {
    title = title.formatUnicorn(state.variables);
    message = message.formatUnicorn(state.variables);
    linkBtnText = linkBtnText.formatUnicorn(state.variables);
    linkBtnAnchor = linkBtnAnchor.formatUnicorn(state.variables);
  }

  useEffect(() => {
    setState({ variables: loadDictionary(props.type) });
  }, [props.type]);

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.cardContainer}>
      {image && <img src={image} className="img-fluid" alt="Imagem" />}
      <Typography variant="h4" align="center" className={classes.text} >
        {title}
      </Typography>
      <Typography variant="body1" align="center" className={classes.text} >
        {message}
      </Typography>
      {linkBtnText && linkBtnAnchor ?
        <Button
          className={classes.button}
          href={linkBtnAnchor}
        >
          {linkBtnText}
        </Button> :
        null}
    </Grid>
  );
};

export default NotificationBannerPreview;
