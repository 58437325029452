import React from 'react';
import { useTranslation } from 'react-i18next';
import { createNewOrganizationPortfolio, removeOrganizationPortfolio } from '../../../../services/organizations';
import { Select, MenuItem } from '@material-ui/core';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';
import { listPortfolioOrganizations } from '../../../../services/portfolio';

export const OrgsTable = ({ setMessage, data: portfolioData, setOrgsCount }) => {
  const { t: translate } = useTranslation();

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove organization')
      },
      addTooltip: translate('add organization'),
      deleteTooltip: translate('remove organization')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization portfolio already exists':
      return setMessage(translate('Organization portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This organizationId doesnt exist'));
    default:
      return setMessage(translate(`NOT ${serviceName} portfolio`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const orgId = newData.organization.id;
      const role = newData.role || 'VIEWER';
      const { id: porfolioId, name: porfolioName } = portfolioData;

      if (serviceName === 'inserted') {
        await service(orgId, porfolioId, porfolioName, role);
      } else {
        await service(orgId, porfolioId);
      }

      return setMessage(translate(`${serviceName} portfolio`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createNewOrganizationPortfolio, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationPortfolio, oldData);
    }
  };

  const columns = [
    { title: 'OrganizationId', field: 'organization.id' },
    { title: translate('name'), field: 'organization.name', editable: 'never' },
    {
      title: translate('role'),
      field: 'role',
      editComponent: (props) => (
        <Select
          value={props.value || 'VIEWER'}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="EDITOR">EDITOR</MenuItem>
          <MenuItem value="VIEWER">VIEWER</MenuItem>
        </Select>
      )
    }
  ];


  const searchPortfolioOrganizations = async (offset, limit, search, count) => {
    const { data: response } = await listPortfolioOrganizations({ portfolioId: portfolioData.id, offset, limit, search, count, populate: 'organization' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchPortfolioOrganizations}
        columns={columns}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setOrgsCount}
      />
    </>
  );
};

export default OrgsTable;
