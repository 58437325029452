import { makeStyles } from '@material-ui/core/styles';

import { color } from '../../shared';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  container: {
    alignItems: 'center'
  },
  paper: {
    width: '330px'
  },
  indicator: {
    backgroundColor: color.GorilaDefault
  },
  tableContainer: {
    padding: '30px',
    minWidth: '60rem'
  },
  tableCell: {
    maxWidth: '9rem',
    minWidth: '6rem'
  },
  tableRow: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

export default useStyles;
