import axios from 'axios';

export const createOrganization = async (body) => {
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization`,
    data: body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  return data;
};

export const listOrganizations = async (search) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization`,
    params: {
      search
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const updateOrganization = async (infos) => {
  const { id, taxId, name } = infos;

  return await axios({
    method: 'patch',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization/${id}`,
    data: {
      taxId,
      name
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const readOrganization = async (orgId) => {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization/${orgId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  return data;
};

export const listOrganizationsUsers = async (params) => {
  const { orgId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user`,
    params: {
      organization: orgId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listOrganizationPortfolios = async (params) => {
  const { orgId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-portfolio`,
    params: {
      organization: orgId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listOrganizationUserPortfolios = async (params) => {
  const { orgId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    params: {
      organization: orgId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};


export const createOrganizationUserPortfolioRelation = async ({ orgId, userId, portfolioId, addAll, role }) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    data: {
      organization: orgId,
      user: userId,
      portfolio: portfolioId,
      addAll,
      role
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const removeOrganizationUserPortfolioRelation = async ({ orgId, userId, portfolioId, removeAll }) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    data: {
      organization: orgId,
      user: userId,
      portfolio: portfolioId,
      removeAll
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createOrgUserPortfolioList = async (organization, relations) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio/list`,
    data: {
      organization,
      relations
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const mirrorOrganizationUserPortfolios = async (userId, orgs, destinationUsers) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user/mirror`,
    data: {
      sourceUser: userId,
      orgs,
      destinationUsers
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createNewOrganizationPortfolio = async (orgId, portfolioId, name, role) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-portfolio`,
    data: {
      organization: orgId,
      portfolio: portfolioId,
      role,
      name
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const removeOrganizationPortfolio = async (orgId, portfolioId) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-portfolio`,
    data: {
      organization: orgId,
      portfolio: portfolioId
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const insertOrganizationsUser = async (orgId, userId, role) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user`,
    data: {
      role,
      organization: orgId,
      user: userId
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const removeOrganizationsUser = async (orgId, userId) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user`,
    data: {
      organization: orgId,
      user: userId
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listOrganizationsApiKeys = async (orgId) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization/${orgId}/api-key`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const deleteOrganizationsApiKey = async (orgId, apiKeyId) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization/${orgId}/api-key/${apiKeyId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createOrganizationApiKey = async (orgId, label) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization/${orgId}/api-key`,
    data: {
      label
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const rerunPortfolio = async (orgId) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/rerun`,
    data: {
      orgId
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const getPortfolioImpersonateLink = async (portfolioId, userId) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/${portfolioId}/impersonate/${userId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
