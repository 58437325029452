import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useAuthState, useAuthDispatch } from '../../context/authentication';
import { getToken, setLoading } from '../../context/actions/auth';
import { loadToken } from '../../services/auth';
import { color } from '../../shared';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    height: 'fit-content',
    padding: theme.spacing(3, 2),
    width: '35rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: '1rem',
    borderRadius: '20px'
  },
  errorMessage: {
    marginTop: '1rem',
    color: color.Negative
  },
  loading: {
    marginLeft: '0.5rem',
    color: color.White
  },
  container: {
    height: '100%'
  }
}));

const Auth = () => {
  const { t: translate } = useTranslation();
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const classes = useStyles();
  const [values, setValues] = useState({ email: '', password: '' });

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleKeyDown = (e) => {
    // The key code 13 represents the enter key
    if (e.keyCode && e.keyCode === 13) {
      login();
    }
  };

  const login = () => {
    if (values.email.trim() && values.password.trim()) {
      dispatch(setLoading());
      loadToken(values, (err) => dispatch(getToken(err)));
    }
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.container}>
      <Paper className={classes.loginContainer}>
        <Typography variant="h5" align="center" color="textSecondary">
          {translate('login')}
        </Typography>
        <Grid container direction="column">
          <Grid item>
            <TextField
              id="email_input"
              inputProps={{
                'data-testid': 'emailInput'
              }}
              value={values.email}
              onChange={handleChange('email')}
              label={translate('email')}
              type="email"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id="password_input"
              inputProps={{
                'data-testid': 'passwordInput'
              }}
              value={values.password}
              onChange={handleChange('password')}
              onKeyDown={handleKeyDown}
              label={translate('password')}
              type="password"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <Button variant="contained" color="primary" className={classes.button} onClick={login} data-testid="login-button">
              {translate('login')}
              {
                state.loadingLogin &&
                <CircularProgress data-testid="loading" className={classes.loading} size="1rem" />
              }
            </Button>
          </Grid>
          {
            state.errorMessage &&
            <Typography variant="h5" align="center" data-testid="errorMessage" className={classes.errorMessage}>
              {state.errorMessage}
            </Typography>
          }
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Auth;
