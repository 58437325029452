import React, { useState } from 'react';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import IconAction from './IconAction';

const useStyles = makeStyles((theme) => ({
  horizontal: {
    'display': 'table-cell',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

function groupActions(actions) {
  const menu = [];
  const regular = [];

  actions.forEach((action) => {
    if (action.inMenu) {
      menu.push(action);
    } else {
      regular.push(action);
    }
  });

  return {
    menu,
    regular
  };
}

const canSeeIcon = (dataItem, allowedData) => {
  for (const key in allowedData) {
    if (dataItem.hasOwnProperty(key) && Array.isArray(allowedData[key])) {
      if (!allowedData[key].includes(dataItem[key])) {
        return false;
      }
    }
  }
  return true;
};

const canSeeAnyActionInMenu = (dataItem, actions) => {
  return actions.some((action) => canSeeIcon(dataItem, action.allowedData));
};

const TableActions = ({ dataItem, dataIndex, actions, setMessage, setSelectedItem }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (action) => {
    action({ setMessage, dataItem });
    setSelectedItem(dataItem);
  };

  const {
    regular: regularActions,
    menu: menuActions
  } = groupActions(actions);

  return (
    <>
      { menuActions.length > 0 &&
        canSeeAnyActionInMenu(dataItem, menuActions) && <>
        <IconAction
          type='more actions'
          testid={`${dataIndex}-menu-action-button`}
          action={handleClick}
          srcImg='Platform/Actions'
          width='24px'
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          data-testid={`${dataIndex}-menu`}
        >
          {menuActions.map( (action, index) => {
            return canSeeIcon(dataItem, action.allowedData) &&
            <MenuItem
              key={index}
              onClick={handleClose}
              className={classes.horizontal}
              disableGutters={true}
            >
              <IconAction
                type={action.type}
                testid={`${dataIndex}-${action.type}`}
                permissionType={action.authPermission}
                action={() => handleOpenModal(action.action)}
                srcImg={action.srcImg}
                setMessage={setMessage}
                width={action.iconWidth || '20px'}
              />
            </MenuItem>;
          })}
        </Menu>
      </>}
      {regularActions.map((action, index) => (
        canSeeIcon(dataItem, action.allowedData) &&
          <IconAction
            key={index}
            testid={`${dataIndex}-${action.type}`}
            type={action.type}
            permissionType={action.authPermission}
            action={() => handleOpenModal(action.action)}
            srcImg={action.srcImg}
            setMessage={setMessage}
            width={action.iconWidth || '24px'}
          />
      ))}

    </>
  );
};

export default TableActions;
