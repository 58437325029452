import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TableActions from './TableActions';

const useStyles = makeStyles(() => ({
  tableDataCell: {
    overflowWrap: 'break-word'
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

function WordWithWBR({ children }) {
  let word = children;

  if (!word) return word;

  word = `${word}`;

  const characters = word.includes('@') ? ['@', '+'] : ['+', '.'];

  const regex = new RegExp(`[${characters.join('\\')}]`, 'g');
  const wordParts = word.split(regex);

  return (
    <>
      {wordParts.length > 1 ?
        wordParts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < wordParts.length - 1 && <wbr />}
            {i < wordParts.length - 1 && word.match(regex)[i]}
          </React.Fragment>
        )) :
        word}
    </>
  );
}
const TableData = ({ data, fields, actions, setMessage, setSelectedItem }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      {
        data.map((item, index) => (
          <TableRow key={index}>
            {fields.map((field) => (
              <React.Fragment key={`${index}_${field.key}`}>
                <TableCell align="center" className={classes.tableDataCell}>
                  <WordWithWBR>
                    {field.translateData ? translate(item[field.key]) : item[field.key]}
                  </WordWithWBR>
                </TableCell>
              </React.Fragment>
            ))}
            {actions?.length > 0 &&
            <TableCell className={classes.actionCell}>
              <TableActions
                dataItem={item}
                dataIndex={index}
                actions={actions}
                setMessage={setMessage}
                setSelectedItem={setSelectedItem}
              />
            </TableCell>}
          </TableRow>
        ))
      }
    </>
  );
};

export default TableData;
