export const percentToNominal = (value) => {
  const decimalField = `${value}`.split('.')[1];
  const originalDecimalQty = (decimalField && decimalField.length) || 0;

  let decimalQtyFromDivision = 2;
  if (!originalDecimalQty) {
    let temp = value;
    while (temp >= 10) {
      const rest = temp % 10;
      if (rest) {
        break;
      }
      decimalQtyFromDivision--;
      temp = temp / 10;
    }
  }

  const desiredDecimalQty = originalDecimalQty + decimalQtyFromDivision;

  return Number((value / 100).toFixed(desiredDecimalQty));
};

export const nominalToPercent = (value) => {
  const decimalField = `${value}`.split('.')[1];

  let desiredDecimalQty = 0;
  if (decimalField && decimalField.length > 2) {
    desiredDecimalQty = decimalField.length - 2;
  }

  return (value * 100).toFixed(desiredDecimalQty);
};
