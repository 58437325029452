import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { CloseIcon, SearchIcon } from '../../../assets/images/icons/index';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '24px'
  },
  loading: {
    marginRight: '1rem'
  },
  clearButton: {
    '&:disabled': {
      opacity: '0.5'
    }
  }
}));

const SearchInput = ({ name, searchLoading, searchValue, handleChange, clearSearchInput }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      <TextField
        fullWidth
        placeholder={translate(`search ${name} field`)}
        value={searchValue}
        onChange={handleChange}
        autoComplete="off"
        inputProps={{
          'data-lpignore': 'true',
          'data-testid': 'searchInput'
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={SearchIcon} alt="" className={classes.icon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {
                searchLoading ?
                  <CircularProgress size={20} color="primary" className={classes.loading} data-testid="loading" /> :
                  <IconButton
                    aria-label={translate('clear search input')}
                    data-testid="clear-button"
                    onClick={clearSearchInput}
                    disabled={searchValue.trim() === ''}
                    className={classes.clearButton}
                  >
                    <img src={CloseIcon} alt="" className={classes.icon} />
                  </IconButton>
              }
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default SearchInput;
