import axios from 'axios';

export const postSecurity = (security) => {
  return axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}securities`, security, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const postCrypto = (security) => {
  return axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}securities/crypto`, security, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
