import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { color } from '../..';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1rem',
    borderRadius: '2rem'
  },
  loading: {
    marginLeft: '0.5rem',
    color: color.White
  }
}));

const CustomButton = ({ text, loading, disabled, onClick }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Button
      color='primary'
      variant='contained'
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      {translate(text)}
      {loading && <CircularProgress className={classes.loading} size='1rem' />}
    </Button>
  );
};

export default CustomButton;
