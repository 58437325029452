import React, { useState } from 'react';
import { SearchTable } from '../../../shared/components';
import { useHistory } from 'react-router-dom';
import {
  DeleteTradesModal,
  PortfolioRerunModal
} from './Modals';
import { Grid, Paper } from '@material-ui/core';
import { getPortfolioList } from '../../../services/portfolio';

const PortfolioSearch = () => {
  const [rerunModalOpen, setRerunModalOpen] = useState(false);
  const [deleteTradesModalOpen, setDeleteTradesModalOpen] = useState(false);

  const history = useHistory();

  const goToDetails = ({ dataItem: portfolio }) => {
    history.push(`/portfolios/details/${portfolio.id}`, { portfolioData: portfolio });
  };

  const tableFields = [{
    name: 'portfolioId',
    key: 'id',
    sortable: true,
    translateData: false
  },
  {
    name: 'name',
    key: 'name',
    sortable: true,
    translateData: false
  },
  {
    name: 'externalId',
    key: 'externalId',
    sortable: true,
    translateData: false
  },
  {
    name: 'contributions and redemptions',
    key: 'rnc',
    sortable: true,
    translateData: true
  }];

  const tableActions =[{
    type: 'portfolioDetails',
    authPermission: 'read:portfolio',
    action: goToDetails,
    srcImg: 'Nav/Clients'
  },
  {
    inMenu: true,
    type: 'rerunPortfolio',
    authPermission: 'update:portfolio',
    srcImg: 'Platform/Sync',
    action: () => setRerunModalOpen(true),
    modal: {
      component: PortfolioRerunModal,
      open: rerunModalOpen,
      closeModal: () => setRerunModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'deletePortfolioTrades',
    authPermission: 'delete:portfolio',
    srcImg: 'Nav/Wallet',
    action: () => setDeleteTradesModalOpen(true),
    modal: {
      component: DeleteTradesModal,
      open: deleteTradesModalOpen,
      closeModal: () => setDeleteTradesModalOpen(false)
    }
  }];

  const changeDataItem = (data) => {
    data.rnc = data.autoRnC ? 'automatic' : 'manual';
    return data;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item style={{ marginTop: '2rem', marginBottom: '2rem', width: '75%' }}>
        <Paper>
          <SearchTable
            name='portfolio'
            fields={tableFields}
            searchService={getPortfolioList}
            changeDataItem={changeDataItem}
            actions={tableActions}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PortfolioSearch;
