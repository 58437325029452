import {
  Button,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../../../shared';
import { editBrokers } from '../../../services/brokers';
import { editIssuer } from '../../../services/issuers';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: color.GorilaDefault
  },
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    position: 'absolute',
    backgroundColor: '#FFF',
    boxShadow: '7px 7px 10px rgb(0 0 0 / 30%)',
    padding: '20px'
  },
  form: {
    width: '100%',
    margin: '30px 0'
  },
  containerInput: {
    height: '80px',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '300px'
  },
  button: {
    margin: '10px',
    width: '100px',
    borderRadius: '20px'
  },
  buttonDiv: {
    textAlign: 'center'
  },
  warning: {
    marginTop: '15px',
    color: '#888e8e',
    fontSize: '10px',
    textAlign: 'center'
  }
}));

export default function EditNameModal({ closeModal, visible, data, enterpriseType, issuerList, brokersList }) {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const initialCreateState = {
    displayName: '',
    symbol: ''
  };
  const [editValues, setEditValues] = useState(initialCreateState);
  const [editModal, setEditModal] = useState(false);
  const [editModalMensage, setEditModalMensage] = useState('');
  const showCreatedModalTime = 3000;

  useEffect(() => {
    if (editModal) {
      const timerRef = setTimeout(() => {
        setEditModal(false);
        setEditModalMensage('');
        closeModal();
      }, showCreatedModalTime);

      return () => clearTimeout(timerRef);
    }
  }, [editModal]);

  const handleCreateInput = (event) => {
    const auxValues = { ...editValues };
    auxValues[event.target.name] = event.target.value;
    setEditValues(auxValues);
  };

  const handleCreate = () => {
    const body = {
      displayName: editValues.displayName.toUpperCase().trim(),
      symbol: data.symbol,
      ...(enterpriseType === 'broker' && { country: data.country }),
      ...(enterpriseType === 'issuer' && { validityDate: data.validityDate.split('T')[0] })
    };

    switch (enterpriseType) {
    case 'broker':
      if (brokersList.find((broker) => broker.displayName === body.displayName)) return resetModal();
      editBrokers(body).then((response) => {
        setEditModalMensage('Broker edited successfully!');
        setEditModal(true);
      }).catch((error) => {
        console.log(error.response.data);
        setEditModalMensage(error.response.data.message);
        setEditModal(true);
      });
      break;
    case 'issuer':
      if (issuerList.find((issuer) => issuer.displayName === body.displayName)) return resetModal();
      editIssuer(body).then((response) => {
        setEditModalMensage('Issuer edited successfully!');
        setEditModal(true);
      }).catch((error) => {
        console.log(error.response.data);
        setEditModalMensage(error.response.data.message);
        setEditModal(true);
      });
      break;
    default:
      break;
    }

    setEditValues(initialCreateState);
  };

  const handleCloseModal = () => {
    closeModal();
    setEditValues(initialCreateState);
  };

  const resetModal = () => {
    setEditModalMensage(`${enterpriseType} name already exists! If you want to change its name, report to securities`);
    setEditModal(true);
    setEditValues(initialCreateState);
    return;
  };

  const disabledButtonCreate = () => {
    const isValidDisplayName = editValues.displayName !== '';
    return !isValidDisplayName;
  };

  return (
    <Modal open={visible} onClose={closeModal}>
      {editModal === false ? (
        <div key='editModal' className={classes.container}>
          <h2 className={classes.title}>{translate(`edit ${enterpriseType}`)}</h2>
          <form noValidate autoComplete='off' className={classes.form}>
            <Grid container className={classes.containerInput}>
              <TextField
                required
                name='displayName'
                label={translate(`${enterpriseType} name`)}
                placeholder={data.displayName}
                className={classes.input}
                onChange={(event) => handleCreateInput(event)}
                margin='normal'
                variant='outlined'
                inputProps={{
                  'data-lpignore': 'true',
                  'role': 'textbox'
                }}
              />
            </Grid>
            <Grid container className={classes.containerInput}>
              <TextField
                disabled
                name='symbol'
                value={data.symbol}
                className={classes.input}
                label={translate('symbol')}
                margin='normal'
                variant='outlined'
                inputProps={{
                  'data-lpignore': 'true',
                  'role': 'textbox'
                }}
              />
            </Grid>
          </form>
          <div className={classes.buttonDiv}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={handleCloseModal}
            >
              {translate('close')}
            </Button>
            <Button
              disabled={disabledButtonCreate()}
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={handleCreate}
            >
              {translate('edit')}
            </Button>
          </div>
          <p className={classes.warning}>
            {translate(
              ''
            )}
          </p>
        </div>
      ) : (
        <div key='createModal' className={classes.container}>
          <h2 className={classes.title}>{translate(editModalMensage)}</h2>
        </div>
      )}
    </Modal>
  );
}
