import React from 'react';
import styled from 'styled-components';
import { color } from '../../../shared';

const DestFathers = ({ dest, setSelectedValues, selectedValues, setDestSearch, destSearch }) => {
  const { id, email, name } = dest;

  const handleCheckDest = (event) => {
    if (selectedValues.dest.find(((e) => e.id === dest.id))) {
      setDestSearch({ ...destSearch, searchingValue: '', foundValues: destSearch.foundValues.filter((dest) => dest.id !== id) });
      return;
    }
    if (event.target.checked) {
      setSelectedValues({ ...selectedValues, dest: [...selectedValues.dest, dest] });
      setDestSearch({ ...destSearch, searchingValue: '', foundValues: destSearch.foundValues.filter((dest) => dest.id !== id) });
    }
  };

  return (
    <OptionWrapper>
      <div>
        <span>Fund ID: {id}</span>
        <span>Name: {name}</span>
        <span>Email: {email}</span>
      </div>
      <input type="checkbox" name={''} value={id} onChange={handleCheckDest} checked={selectedValues.dest.includes(id)} />
    </OptionWrapper>
  );
};

export default DestFathers;

const OptionWrapper = styled.section`
  padding: 5px;
  border: 1px solid ${ color.GorilaDefault };
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  & span {
    display: block;
    word-break: break-all;
  }
`;
