import axios from 'axios';

export const getBrokersList = () => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}brokers`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createBrokers = (body) => {
  return axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}brokers`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};

export const editBrokers = (body) => {
  return axios.put(`${process.env.REACT_APP_URL_BACKOFFICE_API}brokers`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};
