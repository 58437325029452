import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs';
import FormDatePicker from '../../../shared/forms/FormDatePicker';
import SuccessSnackBar from '../../../shared/snackbar/SuccessSnackBar';
import DataConfirmationModal from '../../../shared/modals/DataConfirmationModal';
import { postSecurity } from '../../../services/securities';

const issuers = {
  '02.332.886/0016-82': 'XP CCTVM',
  '13.434.335/0001-60': 'RICO CCTVM'
};

const COEInsert = () => {
  const { t: translate } = useTranslation();

  const symbolRef = React.useRef('');

  const nameRef = React.useRef('');

  const [issuer, setIssuer] = React.useState('');

  const [issueDate, setIssueDate] = React.useState(new Date());

  const [maturityDate, setMaturityDate] = React.useState(new Date());

  const [openDialog, setOpenDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [showSnackBar, setShowSnackBar] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  const handleDateChange = (onChange) => (date) => {
    onChange(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOpenDialog(true);
  };

  const handleCOEInsert = async (e) => {
    setOpenDialog(false);

    setLoading(true);

    try {
      const issueDateDayJS = dayjs(issueDate);
      const maturityDateDayJS = dayjs(maturityDate);

      const security = {
        issueDate: issueDateDayJS.format('YYYY-MM-DD'),
        symbol: symbolRef.current.value,
        productType: 'STRUCTURED_COE',
        tradeSettlementLead: 1,
        validityDate: issueDateDayJS.format('YYYY-MM-DD'),
        displayName: `${nameRef.current.value} - ${maturityDateDayJS.diff(issueDateDayJS, 'year')} anos - ${issueDateDayJS.format('DD.MM.YYYY')}`,
        source: 'USER',
        calendar: {
          name: 'BRAZIL',
          category: 'COUNTRY'
        },
        paymentCurrency: 'BRL',
        classification: 'STRUCTURED_PRODUCTS',
        pricingMethod: 'TRADE',
        specificData: {
          issuer: issuer,
          description: `Código CETIP: ${symbolRef.current.value}`,
          maturityDate: maturityDateDayJS.format('YYYY-MM-DD')
        }
      };

      await postSecurity(security);

      setSuccess(true);
    } catch (err) {
      setSuccess(false);
    } finally {
      setShowSnackBar(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Box my={3}>
        <Container maxWidth="sm">
          <Paper>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box p={3}>
                <Grid container justifyContent="center" alignItems="baseline" spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('name')}
                      type="search"
                      variant="standard"
                      fullWidth
                      inputRef={nameRef}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('CETIP code')}
                      type="search"
                      variant="standard"
                      fullWidth
                      inputRef={symbolRef}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                      <InputLabel>{translate('issuer')}</InputLabel>
                      <Select
                        value={issuer}
                        onChange={(e) => setIssuer(e.target.value)}
                        label={translate('issuer')}
                      >
                        {Object.entries(issuers).map(([key, value]) => (<MenuItem key={key} value={key}>{value}</MenuItem>))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormDatePicker
                      label="issue date"
                      value={issueDate}
                      onChange={handleDateChange(setIssueDate)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDatePicker
                      label="maturity date"
                      value={maturityDate}
                      onChange={handleDateChange(setMaturityDate)}
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                      {loading ? <CircularProgress size={25} /> : translate('insert')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>

      <DataConfirmationModal
        open={openDialog}
        setOpen={setOpenDialog}
        sections={[
          {
            data: {
              'name': nameRef.current.value,
              'CETIP code': symbolRef.current.value,
              'issuer': issuers[issuer],
              'issue date': dayjs(issueDate).format('DD/MM/YYYY'),
              'maturity date': dayjs(maturityDate).format('DD/MM/YYYY')
            }
          }
        ]}
        onConfirm={handleCOEInsert}
      />

      <SuccessSnackBar
        success={success}
        successMessage="COE inserido com sucesso!"
        failureMessage="Ocorreu um erro na inserção do COE"
        open={showSnackBar}
        setOpen={setShowSnackBar}
      />
    </>
  );
};

export default COEInsert;
