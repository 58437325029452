import MaterialTable from '@material-table/core';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Search from '@material-ui/icons/Search';
import { Refresh } from '@material-ui/icons';
import { MaterialIcons } from '../../MaterialIcons';

export const RemoteTable = ({ setMessage, dataService, columns, actions, editable, icons, localization, setTotalCount }) => {
  const tableRef = useRef();

  const [searchActive, setSearchActive] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [currentTotalCount, setCurrentTotalCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(false);
  const [tableEditable, setTableEditable] = useState(editable);

  const { t: translate } = useTranslation();

  useEffect(() => {
    const newEditable = { ...editable };

    Object.keys(editable).forEach((key) => {
      if (newEditable[key] instanceof Function) {
        newEditable[key] = async (...args) => {
          tableRef.current.state.query.page = 0;
          setUpdateCount(true);

          await editable[key](...args);
        };
      }
    });

    setTableEditable(newEditable);
  }, [editable]);

  const localizationTable = {
    body: {
      addTooltip: translate('add'),
      deleteTooltip: translate('remove'),
      ...localization?.body,
      editRow: {
        saveTooltip: translate('save'),
        cancelTooltip: translate('cancel'),
        deleteText: translate('remove'),
        ...localization?.body?.editRow
      },
      emptyDataSourceMessage: noDataMessage
    },
    header: {
      actions: translate('actions')
    },
    toolbar: {
      searchPlaceholder: translate('search')
    }
  };

  const tableProps = {
    data: async (query) => {
      try {
        const limit = query.pageSize;
        const offset = query.page * limit;
        const count = query.page === 0 || updateCount;

        let search;
        if (query?.search?.length >= 3) { // User-api requires at least 3 characters to search
          search = query.search;
        }

        const result = await dataService(offset, limit, search, count);

        if (noDataMessage === '') {
          setNoDataMessage(translate('no records to display'));
        }

        if (count) {
          setCurrentTotalCount(result.count);

          if (!search) {
            setTotalCount(result.count);
          } else if (updateCount) {
            const countResult = await dataService(0, 1, undefined, true);
            setTotalCount(countResult.count);
          }

          setUpdateCount(false);
        }

        return {
          data: result.records,
          page: query.page,
          totalCount: result.count || currentTotalCount
        };
      } catch (error) {
        const message = translate('it was not possible to get table data');

        setMessage(message);
        setNoDataMessage(message);

        return {
          data: [],
          page: 0,
          totalCount: 0
        };
      }
    },
    options: {
      showTitle: false,
      draggable: false,
      search: searchActive,
      sorting: false,
      actionsColumnIndex: -1,
      addRowPosition: 'first',
      paging: true,
      debounceInterval: 1000,
      emptyRowsWhenPaging: false,
      pageSizeOptions: [5, 10, 25, 50, 100]
    },
    actions: [
      {
        icon: Search,
        tooltip: searchActive ? translate('disable search') : translate('enable search'),
        isFreeAction: true,
        onClick: () => setSearchActive(!searchActive)
      },
      {
        icon: Refresh,
        tooltip: translate('refresh table'),
        isFreeAction: true,
        onClick: () => {
          setUpdateCount(true);
          tableRef.current.onQueryChange({ page: 0 });
        }

      },
      ...(actions || [])
    ],
    columns
  };

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        icons={{ ...MaterialIcons, ...icons }}
        setMessage={setMessage}
        data={tableProps.data}
        options={tableProps.options}
        columns={tableProps.columns}
        actions={tableProps.actions}
        editable={tableEditable}
        localization={localizationTable}
      />
    </>
  );
};

export default RemoteTable;
