import React from 'react';
import { TableCell, TableSortLabel, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';

const useStyles = makeStyles(() => ({
  tableHaederCell: {
    fontWeight: 'bold',
    flexGrow: 1
  },
  sortLabel: {
    paddingLeft: '1.5rem'
  }
}));

const TableHeader = ({ fields, orderBy, order, handleSort, includeActions }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      {
        fields.map((field, index) => {
          return (
            <TableCell key={index} align="center" className={classes.tableHaederCell}>
              <TableSortLabel
                active={orderBy === field.key}
                direction={orderBy === field.key ? order : 'asc'}
                onClick={() => handleSort(field.key)}
                className={classes.sortLabel}
                disabled={!field.sortable}
              >
                {upperFirst(translate(field.name))}
              </TableSortLabel>
            </TableCell>
          );
        })
      }
      {
        includeActions &&
        <TableCell align="center" className={classes.tableHaederCell}>
          <TableSortLabel
            className={classes.sortLabel}
            disabled={true}
          >
            {translate('actions')}
          </TableSortLabel>
        </TableCell>
      }
    </>
  );
};

export default TableHeader;
