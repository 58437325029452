import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../shared';
import { rerunPortfolio } from '../../../../services/organizations';

const PortfolioRerunModal = ({ open, closeModal, item: org, setMessage, updateTable }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { t: translate } = useTranslation();

  const startForm = (bool) => {
    setLoading(bool);
    setDisabled(bool);
  };

  const errorMessage = (error) => {
    const response = error.response || error;
    switch (response.status) {
    case 403:
      setMessage(translate('Maximum portfolio limit exceeded'));
      break;
    default:
      setMessage(translate(`NOT rerunPortfolio user`));
      break;
    }
  };

  const confirmRerun = async () => {
    startForm(true);
    try {
      await rerunPortfolio(org.id);
      setMessage(translate(`rerunPortfolio user`));
    } catch (err) {
      errorMessage(err);
    };
    startForm(false);
    closeModal();
  };

  return (
    <>
      <ConfirmationModal
        openModal={open}
        closeModal={() => closeModal()}
        testid='portfolio-rerun-modal'
        name={translate(`rerun the portfolio's profitability?`)}
        confirm={() => confirmRerun()}
        disabled={disabled}
        cancel='contained'
        loading={loading}
      />
    </>
  );
};

export default PortfolioRerunModal;
