import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@material-ui/core';
import { createNewOrganizationPortfolio, listOrganizationPortfolios, removeOrganizationPortfolio } from '../../../../services/organizations';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useHistory } from 'react-router-dom';

export const PortfoliosTable = ({ setMessage, data: orgData, setPortfoliosCount }) => {
  const { t: translate } = useTranslation();

  const history = useHistory();

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove portfolio')
      },
      addTooltip: translate('add organization portfolio'),
      deleteTooltip: translate('remove portfolio')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'Organization portfolio already exists':
      return setMessage(translate('Organization portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This portfolioId doesnt exist'));
    default:
      return setMessage(translate(`NOT ${serviceName} portfolio`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const portfolioId = newData.portfolio !== undefined ? newData.portfolio.id : undefined;
      const name = newData.portfolio !== undefined ? newData.portfolio.name : undefined;
      const role = newData.role || 'VIEWER';

      const { id: orgId } = orgData;

      if (serviceName === 'inserted') {
        await service(orgId, portfolioId, name, role);
      } else {
        await service(orgId, portfolioId);
      }

      return setMessage(translate(`${serviceName} portfolio`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createNewOrganizationPortfolio, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', removeOrganizationPortfolio, oldData);
    }
  };

  const tableColumns = [
    { title: 'PortfolioId', field: 'portfolio.id' },
    { title: translate('portfolio name'), field: 'portfolio.name' },
    {
      title: translate('role'),
      field: 'role',
      editComponent: (props) => (
        <Select
          value={props.value || 'VIEWER'}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="EDITOR">EDITOR</MenuItem>
          <MenuItem value="VIEWER">VIEWER</MenuItem>
        </Select>
      )
    }
  ];

  const actions = [
    {
      icon: VisibilityOutlinedIcon,
      tooltip: translate('portfolioDetails'),
      onClick: ((e, rowData) => history.push(`/portfolios/details/${rowData.portfolio.id}`, { portfolioData: rowData.portfolio }))
    }
  ];

  const searchOrganizationPortfolios = async (offset, limit, search, count) => {
    const { data: response } = await listOrganizationPortfolios({ orgId: orgData.id, offset, limit, search, count, populate: 'portfolio' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        actions={actions}
        dataService={searchOrganizationPortfolios}
        columns={tableColumns}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setPortfoliosCount}
      />
    </>
  );
};

export default PortfoliosTable;
