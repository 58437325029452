import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CustomRadioGroup = ({ name, value, options, onChange }) => {
  const { t: translate } = useTranslation();

  return options && (
    <FormControl>
      <RadioGroup name={name} value={value} onChange={onChange} style = {{ marginTop: '1rem' }}>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio color="primary" />}
            label={translate(option)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;
