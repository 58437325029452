import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: '140px',
    minWidth: '270px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '3rem',
    margin: '1rem'
  }
}));

const TransferForm = ({ values, title, userType, handleChange }) => {
  const classes = useStyles();
  const userPortfolioId = `user${userType}PortfolioId`;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' align="center" color="primary" >
        { title }
      </Typography>
      <TextField
        id={userPortfolioId}
        inputProps={{
          'data-testid': userPortfolioId
        }}
        value={values[userPortfolioId]}
        onChange={handleChange(userPortfolioId)}
        label={`PortfolioId ${userType}`}
        type='text'
        margin='normal'
        fullWidth
      />
    </Paper>
  );
};

export default TransferForm;
