import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CustodyAdjustmentIcon } from '../../../../assets/images/icons/custody_adjustment.svg';
import { ReactComponent as IntegrationResyncIcon } from '../../../../assets/images/icons/integration_resync.svg';
import { Refresh } from '@material-ui/icons';
import MaterialTable from '@material-table/core';
import dayjs from 'dayjs';
import { getIntegrations, startResync } from '../../../../services/integrations';
import { IntegrationStateEnum, IntegrationProviderEnum } from '../../../../shared/integrations';
import { ConfirmationModal } from '../../../../shared';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const UserIntegrations = ({ setMessage, data: userData, setIntegrationsCount }) => {
  const { t: translate } = useTranslation();

  const initialModalState = {
    title: null,
    text: null,
    action: () => {},
    single: false,
    loading: false
  };

  const [tableLoading, setTableLoading] = useState(true);
  const [modalState, setModalState] = useState(initialModalState);
  const [integrationsData, setIntegrationsData] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState('');

  const allowedProviders = [
    IntegrationProviderEnum.B3
  ];

  const allowedStates = [
    IntegrationStateEnum.UPDATED,
    IntegrationStateEnum.ERROR_UNEXPECTED,
    IntegrationStateEnum.ERROR_PAYLOAD,
    IntegrationStateEnum.ERROR_AUTHENTICATION,
    IntegrationStateEnum.ERROR_TIMEOUT
  ];

  const allowedStatesIfTimeout = [
    IntegrationStateEnum.CALCULATING_WALLET
  ];

  const disabledColor = '#ABAEB8';
  const enabledColor = '#333333';

  useEffect( () => {
    const loadIntegrations = async () => {
      try {
        setTableLoading(true);
        const integrationsResponse = await getIntegrations(userData.id);

        setNoDataMessage(translate('no records to display'));

        setIntegrationsCount(integrationsResponse.data?.length);
        setIntegrationsData(integrationsResponse.data);
      } catch (err) {
        console.error(err);
        setMessage(translate('it was not possible to get table data'));
        setNoDataMessage(translate('it was not possible to get table data'));
      }
      setTableLoading(false);
    };

    loadIntegrations();
  }, [userData, setMessage, setIntegrationsCount, translate]);

  const isAllowedToResyncAndAdjustment = (rowData) => {
    const isAllowedProvider = allowedProviders.includes(rowData.providerId);

    let isTimeout = false;
    if (isAllowedProvider && allowedStatesIfTimeout.includes(rowData.providerState) && rowData.providerResyncTimeout) {
      const lastUpdate = dayjs(rowData.lastUpdate);
      const now = dayjs();

      const diff = now.diff(lastUpdate, 'minutes');
      isTimeout = diff >= rowData.providerResyncTimeout;
    }

    return isAllowedProvider && (allowedStates.includes(rowData.providerState)|| isTimeout);
  };

  const updateTable = async () => {
    setTableLoading(true);
    const response = await getIntegrations(userData.id);
    setIntegrationsData(response.data);
    setTableLoading(false);
  };


  const triggerResync = async (rowData, custodyAdjustment = false) => {
    const actionMessage = custodyAdjustment ? 'custody adjustment': 'resync';

    try {
      setModalState((prevState) =>({ ...prevState, loading: true }));
      const { data: response } = await startResync(rowData.providerId, userData.id, custodyAdjustment);

      setModalState({
        ...initialModalState,
        title: translate(`${actionMessage} started for @providerName`, { providerName: rowData.name }),
        text: <><b style={{ marginRight: '0.25rem' }}>{translate('process id')}:</b> {response.processStatusId}</>,
        single: true,
        loading: false,
        action: async () => {
          setModalState(initialModalState);
          await updateTable();
        }
      });
    } catch (err) {
      const response = err.response || err;

      setModalState(initialModalState);
      await updateTable();

      if (response.status === 409) {
        setMessage(translate('there is already a process running for this user'));
      } else {
        setMessage(translate(`it was not possible to apply the ${actionMessage}`));
      }
    }
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        options={{
          draggable: false,
          paging: false,
          search: false,
          showTitle: false,
          sorting: false,
          headerStyle: { fontWeight: 'bold' },
          actionsColumnIndex: -1
        }}
        columns={[
          { title: translate('platform'), field: 'providerName' },
          { title: translate('last update'), render: (rowData) => dayjs(rowData.lastUpdate).format('DD/MM/YY[ às ]HH:mm') },
          { title: translate('state'), field: 'providerState' }
        ]}
        data={integrationsData}
        actions={[
          {
            icon: Refresh,
            tooltip: translate('refresh table'),
            isFreeAction: true,
            onClick: updateTable
          },
          (rowData) => {
            const disabled = !isAllowedToResyncAndAdjustment(rowData);

            return {
              icon: () => <CustodyAdjustmentIcon style={{ fill: disabled? disabledColor: enabledColor }} />,
              tooltip: translate('apply custody adjustment'),
              isFreeAction: false,
              disabled: disabled,
              onClick: async ()=> {
                if (!disabled) {
                  setModalState({
                    ...initialModalState,
                    title: translate('confirm start custody adjustment for @providerName', { providerName: rowData.name }),
                    text: <>
                      <p><b style={{ marginRight: '0.25rem' }}>Email:</b> {userData.email}</p>
                      <p style={{ display: 'flex' }}>
                        <InfoOutlinedIcon style={{ marginRight: '5px' }}/>
                        {translate('the custody adjustment will also remove and reinsert all trades')}
                      </p>
                    </>,
                    action: () => triggerResync(rowData, true)
                  });
                }
              }
            };
          },
          (rowData) => {
            const disabled = !isAllowedToResyncAndAdjustment(rowData);

            return {
              icon: () => <IntegrationResyncIcon style={{ fill: disabled? disabledColor: enabledColor }} />,
              tooltip: translate('resync integration'),
              isFreeAction: false,
              disabled: disabled,
              onClick: async ()=> {
                if (!disabled) {
                  setModalState({
                    ...initialModalState,
                    title: translate('confirm start resync for @providerName', { providerName: rowData.name }),
                    text: <>
                      <p><b style={{ marginRight: '0.25rem' }}>Email:</b> {userData.email}</p>
                      <p style={{ display: 'flex' }}>
                        <InfoOutlinedIcon style={{ marginRight: '5px' }}/>
                        {translate('the resync will remove and reinsert all trades')}
                      </p>
                    </>,
                    action: () => triggerResync(rowData, false)
                  });
                }
              }
            };
          }
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: noDataMessage
          },
          header: {
            actions: translate('actions')
          }
        }}
        isLoading={tableLoading}
      />
      {modalState?.title &&
      <ConfirmationModal
        openModal={!!modalState?.title}
        closeModal={() => setModalState(initialModalState)}
        name={<b>{modalState.title}</b>}
        additionalInfo={modalState.text}
        confirm={modalState.action}
        disabled={modalState.loading}
        single={modalState.single}
        cancel='contained'
        loading={modalState.loading}
      />}
    </div>
  );
};

export default UserIntegrations;
