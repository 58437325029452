import MaterialTable from '@material-table/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialIcons } from '../../../../shared/MaterialIcons';
import Search from '@material-ui/icons/Search';
import dayjs from 'dayjs';
import { createOrganizationApiKey, deleteOrganizationsApiKey, listOrganizationsApiKeys } from '../../../../services/organizations';
import { Button, Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { InformationModal } from '../../../../shared';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { enablePagination, pageSizeOptions } from '../../../../shared/pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTable-root tr td:last-child div': {
      justifyContent: 'center !important'
    },
    '& .MuiInputBase-input:last-child': {
      textAlign: 'center'
    }
  },
  button: {
    marginRight: '1rem'
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  apiKey: {
    display: 'flex',
    alignItems: 'center'
  },
  infoApiKey: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  }
}));

export const ApiKeyTable = ({ setMessage, data: orgData, setApiKeysCount }) => {
  const classes = useStyles();

  const [apiKeys, setApiKeys] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [createdApiKey, setCreatedApiKey] = useState('');
  const [loading, setLoading] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState('');

  const { t: translate } = useTranslation();

  useEffect( () => {
    const getApiKeys = async () => {
      try {
        setLoading(true);
        const apiKeyResponse = await listOrganizationsApiKeys(orgData.id);

        setNoDataMessage(translate('no records to display'));

        setApiKeys(apiKeyResponse.data);
        setApiKeysCount(apiKeyResponse.data?.length);
      } catch (err) {
        setMessage(translate('it was not possible to get table data'));
        setNoDataMessage(translate('it was not possible to get table data'));
      }
      setLoading(false);
    };

    getApiKeys();
  }, [orgData, setMessage, setApiKeysCount, translate]);

  const localizationTable = {
    body: {
      editRow: {
        saveTooltip: translate('save'),
        cancelTooltip: translate('cancel'),
        deleteText: translate('delete api key?')
      },
      addTooltip: translate('add api key'),
      deleteTooltip: translate('delete api key'),
      emptyDataSourceMessage: noDataMessage
    },
    header: {
      actions: translate('actions')
    },
    toolbar: {
      searchPlaceholder: translate('search')
    }
  };

  const updateData = async (serviceName, service, updateData) => {
    try {
      const { id: orgId } = orgData;
      let extraData;

      switch (service) {
      case createOrganizationApiKey:
        extraData = updateData.label;
        break;
      case deleteOrganizationsApiKey:
        extraData = updateData.id;
        break;
      default:
        break;
      }

      const { data: serviceResponseData } = await service(orgId, extraData);

      const apiKeyResponse = await listOrganizationsApiKeys(orgId);
      setApiKeys(apiKeyResponse.data);
      setApiKeysCount(apiKeyResponse.data?.length);

      if ( serviceName === 'created') {
        setCreatedApiKey(serviceResponseData.key);
      } else {
        setMessage(translate(`${serviceName} api key`));
      }
    } catch (error) {
      return setMessage(translate(`NOT ${serviceName} api key`));
    }
  };

  const editableTable = {
    onRowAdd: async (newData) => {
      return updateData('created', createOrganizationApiKey, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('deleted', deleteOrganizationsApiKey, oldData);
    }
  };

  const tableProps = {
    data: apiKeys,
    options: { showTitle: false, draggable: false, search: searchActive, sorting: false, actionsColumnIndex: -1, addRowPosition: 'first', headerStyle: { fontWeight: 'bold' }, paging: enablePagination(apiKeys, pageSizeOptions), emptyRowsWhenPaging: false, pageSizeOptions },
    columns: [
      { title: 'Label', field: 'label', align: 'center', width: '25%', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Id', field: 'id', align: 'center', editable: 'never', width: '35%', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Data de criação', editable: 'never', align: 'center', width: '20%', render: (rowData) => dayjs(rowData.created).format('DD/MM/YYYY') }
    ],
    actions: [
      {
        icon: Search,
        tooltip: searchActive ? translate('disable search') : translate('enable search'),
        isFreeAction: true,
        onClick: () => setSearchActive(!searchActive)
      }
    ],
    editable: editableTable

  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(createdApiKey)
      .then(() => {
        setMessage(translate('api key copied'));
      })
      .catch(() => {
        setMessage(translate('api key NOT copied'));
      });
  };

  const handleCloseModal = () => {
    setCreatedApiKey('');
  };

  return (
    <>
      <div className={classes.root}>
        <MaterialTable
          icons={{ ...MaterialIcons }}
          setMessage={setMessage}
          data={tableProps.data}
          options={tableProps.options}
          columns={tableProps.columns}
          actions={tableProps.actions}
          editable={tableProps.editable}
          localization={localizationTable}
          isLoading={loading}
        />
      </div>
      { !!createdApiKey &&
      <InformationModal
        modalOpen={!!createdApiKey}
        title={translate('created api key')}
      >
        <div className={classes.apiKey}>
          <Typography
            className={classes.apiKey}
            variant='subtitle1'
          >
            <b>{translate('api key value')}:&nbsp;</b>
          </Typography>
          <Tooltip title={ translate('click to copy') }>
            <Typography
              onClick={handleCopyApiKey}
              className={classes.apiKey}
              style={{ cursor: 'pointer' }}
            >
              {createdApiKey}
            </Typography>
          </Tooltip>
        </div>
        <Typography className={classes.infoApiKey}>
          <InfoOutlinedIcon style={{ marginRight: '5px' }}/>
          {translate('the API key value can only be viewed this time')}
        </Typography>
        <Grid container className={classes.grid}>
          <Button
            onClick={handleCloseModal}
            variant='contained'
            color='primary'
            className={classes.button}
            autoFocus
          >
            {translate('close')}
          </Button>
        </Grid>
      </InformationModal>
      }
    </>
  );
};

export default ApiKeyTable;
