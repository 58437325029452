import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { postPrice } from '../../../services/prices';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import SuccessSnackBar from '../../../shared/snackbar/SuccessSnackBar';
import DataConfirmationModal from '../../../shared/modals/DataConfirmationModal';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const PriceInsert = () => {
  const symbolRef = React.useRef('');

  const [price, setPrice] = React.useState('');

  const [openDialog, setOpenDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [productType, setProductType] = React.useState('FUNDQUOTE');

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const [referenceDate, setReferenceDate] = React.useState(new Date());

  const handleReferenceDate = (date) => {
    setReferenceDate(date);
  };

  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setOpenDialog(true);
  };

  const handlePriceInsert = async (e) => {
    setOpenDialog(false);

    setLoading(true);

    try {
      await postPrice({
        productType,
        symbol: symbolRef.current.value,
        referenceDate: referenceDate.toISOString().substring(0, 10),
        price
      });

      setSuccess(true);
    } catch (err) {
      setSuccess(false);
    } finally {
      setOpen(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Box my={3}>
        <Container maxWidth="sm">
          <Paper>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box p={3}>
                <Grid container justifyContent="center" alignItems="baseline" spacing={3}>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Tipo de Produto</InputLabel>
                      <Select
                        value={productType}
                        onChange={handleProductTypeChange}
                        disabled
                        label="Tipo de Produto"
                      >
                        <MenuItem value="FUNDQUOTE">FUNDO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Ativo"
                      type="search"
                      variant="standard"
                      disabled
                      defaultValue="MS0121B3DEN"
                      fullWidth
                      inputRef={symbolRef}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberFormat
                      decimalSeparator=','
                      customInput={TextField}
                      onValueChange={({ value }) => setPrice(value)}
                      label="Preço"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      margin="normal"
                      label="Data"
                      value={referenceDate}
                      onChange={handleReferenceDate}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                      {loading ? <CircularProgress size={25} /> : 'INSERIR'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>

      <DataConfirmationModal
        open={openDialog}
        setOpen={setOpenDialog}
        sections={[
          {
            data: {
              'product type': 'FUNDO',
              'security name': symbolRef.current.value,
              'price': currencyFormatter.format(price),
              'date': dayjs(referenceDate).format('DD/MM/YYYY')
            }
          }
        ]}
        onConfirm={handlePriceInsert}
      />

      <SuccessSnackBar
        success={success}
        successMessage="Preço inserido com sucesso!"
        failureMessage="Ocorreu um erro na inserção do preço"
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default PriceInsert;
