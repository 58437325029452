import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from '../../styles';

const RowPlan = (props) => {
  const { editable, expandable, onEditClick, row } = props;
  const { t: translate } = useTranslation();
  const [open, setOpen] = useState(false);
  const rowPlansClasses = useStyles();

  return (
    <React.Fragment>
      <TableRow className={rowPlansClasses.root}>
        {expandable && <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </TableCell>}
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.code}</TableCell>
        <TableCell>{row.gateway}</TableCell>
        <TableCell>{row.tier}</TableCell>
        <TableCell>{row.value}</TableCell>
        <TableCell>{row.discount}</TableCell>
        {editable && <TableCell>
          <IconButton size="small" onClick={() => onEditClick(row)}><EditIcon /></IconButton>
        </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={rowPlansClasses.tableRow}>{translate('Features')}</TableCell>
                    <TableCell className={rowPlansClasses.tableRow}>{translate('Warning')}</TableCell>
                    <TableCell className={rowPlansClasses.tableRow}>{translate('Included')}</TableCell>
                    <TableCell className={rowPlansClasses.tableRow}>{translate('Order')}</TableCell>
                    <TableCell className={rowPlansClasses.tableRow}>{translate('PaymentPeriod')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subscriptionInfo ? [row.subscriptionInfo].map((row) => (
                    <TableRow key={row.features[0]}>
                      <TableCell className={rowPlansClasses.tableCell}>{row.features}</TableCell>
                      <TableCell className={rowPlansClasses.tableCell}>{row.warning}</TableCell>
                      <TableCell className={rowPlansClasses.tableCell}>{row.included}</TableCell>
                      <TableCell className={rowPlansClasses.tableCell}>{row.order}</TableCell>
                      <TableCell className={rowPlansClasses.tableCell}>{row.paymentPeriod}</TableCell>
                    </TableRow>
                  )) : ''}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RowPlan;
