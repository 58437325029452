import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './assets/i18n/pt-br.lang.json';

const resources = {
  pt: {
    translation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt'
  });


export default i18n;
