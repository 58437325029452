import { Grid, Paper, Tab, TableCell, Tabs } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPremiumFeatures, getPremiumPlans } from '../../services/premiumData';
import EditPremiumFeatures from './Modals/EditPremiumFeatures';
import EditPremiumPlans from './Modals/EditPremiumPlans';
import useStyles from './styles';
import PremiumDataTable from './Table/PremiumData';
import RowFeature from './Table/Rows/Feature';
import RowPlan from './Table/Rows/Plan';

const PremiumData = () => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const [premiumPlans, setPremiumPlans] = useState([]);
  const [premiumFeatures, setPremiumFeatures] = useState([]);
  const [rowToEdit, setRowToEdit] = useState();
  const [openPlansModal, setOpenPlansModal] = useState(false);
  const [openFeaturesModal, setOpenFeaturesModal] = useState(false);
  const [page, setPage] = useState({ name: 'premiumPlans', value: 0 });
  const [searched, setSearched] = useState('');
  const [searchedPremiumPlans, setSearchedPremiumPlans] = useState([]);
  const [searchedPremiumFeatures, setSearchedPremiumFeatures] = useState([]);

  useEffect(() => {
    getPremiumData();
  }, [openPlansModal, openFeaturesModal]);

  const handlePage = (_, newPage) => {
    setRowToEdit('');
    setOpenPlansModal(false);
    setOpenFeaturesModal(false);
    setSearched('');
    cancelSearch();

    newPage === 1 ?
      setPage({ name: 'premiumFeatures', value: 1 }) :
      setPage({ name: 'premiumPlans', value: 0 });
  };

  const closeModal = () => {
    openPlansModal ? setOpenPlansModal(false) : setOpenFeaturesModal(false);
    setTimeout(() => {
      getPremiumData();
    }, 500);
  };

  const getPremiumData = () => {
    getPremiumPlans()
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].subscriptionInfo.features = res.data[i].subscriptionInfo.features.join('\n');
        };
        setPremiumPlans(res.data);
        setSearchedPremiumPlans(res.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getPremiumFeatures()
      .then((res) => {
        setPremiumFeatures(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const requestSearch = (searchedValue) => {
    setSearched(searchedValue);
    const searchCandidate = (searched || '').toLowerCase().trim();

    if (!searchCandidate) {
      setSearchedPremiumPlans(premiumPlans);
      return;
    }

    if (page.name === 'premiumPlans') {
      const filteredRows = premiumPlans.filter((row) => {
        const searchRow = {
          name: row.name,
          code: row.code,
          gateway: row.gateway,
          tier: row.tier,
          value: row.value,
          discount: row.discount
        };
        return Object.values(searchRow).find((val) => `${val}`.toLowerCase().includes(searchCandidate));
      });
      setSearchedPremiumPlans(filteredRows);
    } else if (page.name === 'premiumFeatures') {
      const filteredRows = premiumFeatures.filter((row) => {
        return Object.values(row).find((val) => `${val}`.toLowerCase().includes(searchCandidate));
      });
      setSearchedPremiumFeatures(filteredRows);
    };
  };

  const cancelSearch = () => {
    setSearched('');
    setSearchedPremiumPlans(premiumPlans);
    setSearchedPremiumFeatures(premiumFeatures);
  };

  const onEditClick = (row) => {
    setRowToEdit(row);
    setOpenPlansModal(true);
  };

  const onFeatureEditClick = (row) => {
    setRowToEdit(row);
    setOpenFeaturesModal(true);
  };

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item>
        <Paper className={classes.paper} square>
          <Tabs
            value={page.value}
            onChange={handlePage}
            classes={{ indicator: classes.indicator }}
            centered
          >
            <Tab label={translate('PlansData')} />
            <Tab label={translate('TableData')} />
          </Tabs>
          <SearchBar
            value={searched}
            placeholder={translate('Search')}
            onCancelSearch={() => cancelSearch()}
            onChange={(searchVal) => requestSearch(searchVal)}
          />
        </Paper>
      </Grid>
      <Grid item>
        {page.name === 'premiumPlans' ? (
          <div className={classes.tableContainer}>
            <PremiumDataTable
              HeaderCells={<>
                <TableCell />
                <TableCell className={classes.tableRow}>{translate('Name')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Code')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Gateway')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Tier')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Value')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Discount')}</TableCell>
                <TableCell />
              </>}
              BodyRows={searchedPremiumPlans.map((row, index) => (
                <RowPlan key={index} row={row} editable={true} expandable={true} onEditClick={onEditClick} />
              ))}
            />
            <EditPremiumPlans visible={openPlansModal} closeModal={closeModal} row={rowToEdit}></EditPremiumPlans>
          </div>
        ) : (
          <div className={classes.tableContainer}>
            <PremiumDataTable
              HeaderCells={<>
                <TableCell />
                <TableCell className={classes.tableRow}>{translate('Category')}</TableCell>
                <TableCell className={classes.tableRow}>{translate('Order')}</TableCell>
                <TableCell />
              </>}
              BodyRows={searchedPremiumFeatures.map((row, index) => (
                <RowFeature key={index} row={row} editable={false} expandable={true} onEditClick={onEditClick} onFeatureEditClick={onFeatureEditClick} />
              ))}
            />
          </div>
        )}
      </Grid>
      <EditPremiumFeatures visible={openFeaturesModal} closeModal={closeModal} row={rowToEdit} premiumFeatures={premiumFeatures}></EditPremiumFeatures>
    </Grid>
  );
};

export default PremiumData;
