import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import FormRadioGroup from '../../../../../shared/forms/FormRadioGroup';
import dayjs from 'dayjs';
import xlsxParser from 'xlsx-parse-json';
import { percentToNominal } from '../../../../../shared/functions/math';

const amortizationTypes = [
  'remainingAmortization', 'nominalAmortization'
];

const EventsImportSection = (props) => {
  const { t: translate } = useTranslation();

  const {
    setEvents,
    amortizationType,
    setAmortizationType,
    setImportedEvents,
    displaySnackBar,
    yieldRate,
    dateIsOffLimits
  } = props;

  const knownErrors = {
    eventType: 'invalid event type',
    dateFormat: 'date with invalid format',
    interestRate: 'interest with invalid rate',
    dateLimit: 'event date outside boundary'
  };

  const handleValueChange = (onChange) => (event) => {
    onChange(event.target.value);
  };

  const getEventType = (type) => {
    if (type.match(/juros/i)) {
      return 'interest';
    } else if (type.match(/amortiza..o ordin.ria/i)) {
      return 'amortization';
    } else if (type.match(/pr.mio/i)) {
      return 'premium';
    }
    throw new Error(knownErrors.eventType);
  };

  const getDate = (value) => {
    const formats = {
      'DD/MM/YYYY': /\d{2}\/\d{2}\/\d{4}/i,
      'MM/DD/YY': /\d{2}\/\d{2}\/\d{2}/i,
      'MM/D/YY': /\d{2}\/\d\/\d{2}/i,
      'M/DD/YY': /\d\/\d{2}\/\d{2}/i,
      'M/D/YY': /\d\/\d\/\d{2}/i
    };

    for (const format in formats) {
      if (value.match(formats[format])) {
        const date = dayjs(value, format);
        if (dateIsOffLimits(new Date(date.toISOString()))) {
          throw new Error(knownErrors.dateLimit);
        }
        return date.format('YYYY-MM-DD');
      }
    }

    throw new Error(knownErrors.dateFormat);
  };

  const getEventValue = (eventType, nominalYieldRate, row) => {
    const eventYield = row['Taxa'] && percentToNominal(row['Taxa'].replace(',', '.'));

    if (eventType === 'interest' && nominalYieldRate !== eventYield) {
      throw new Error(knownErrors.interestRate);
    }

    const value = eventType === 'premium' ?
      { explicitValue: Number(row['Valor'].replace(',', '.')) } :
      { eventYield };

    return value;
  };

  const readUploadFile = (file) => {
    xlsxParser
      .onFileSelection(file)
      .then((data) => {
        let formattedEvents = [];
        try {
          const sheet = Object.keys(data)[0];
          const nominalYieldRate = percentToNominal(yieldRate);

          formattedEvents = data[sheet].reduce((acc, row) => {
            const eventType = getEventType(row['Evento']);

            acc.push({
              eventDate: getDate(row['Data']),
              eventType,
              ...getEventValue(eventType, nominalYieldRate, row)
            });

            return acc;
          }, []);
        } catch (err) {
          const errorMessage = Object.values(knownErrors).includes(err.message) ?
            err.message :
            'error importing events';

          displaySnackBar(false, errorMessage);
        }

        setImportedEvents(formattedEvents);
        setEvents(formattedEvents);
      });
  };

  return (
    <>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          component="label"
          style={{ margin: '16px' }}
        >
          {translate('upload (.xlsx)')}
          <input
            type="file"
            accept=".xlsx"
            onChange={(event) => readUploadFile(event.target.files[0])}
            hidden
          />
        </Button>

        <Link to="/modelo-fluxo-pagamento.xlsx" target="_blank" download>
          {translate('upload file model')}
        </Link>
      </Grid>

      <Grid item xs={12}>
        <FormRadioGroup
          label={translate('amortization yield application')}
          value={amortizationType}
          onChange={handleValueChange(setAmortizationType)}
          options={amortizationTypes}
        />
      </Grid>
    </>
  );
};

EventsImportSection.propTypes = {
  setEvents: PropTypes.func,
  amortizationType: PropTypes.string,
  setAmortizationType: PropTypes.func,
  setImportedEvents: PropTypes.func,
  displaySnackBar: PropTypes.func,
  dateIsOffLimits: PropTypes.func,
  yieldRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EventsImportSection;
