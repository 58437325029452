import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  headerContent: {
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    width: '300px',
    height: '100px'
  }
});

const DetailsHeader = ({ title, fields, imageUrl, loading }) => {
  const classes = useStyles();

  const { t: translate } = useTranslation();

  return (
    <Grid container direction='column'>
      <Grid container className={classes.headerContent}>
        <Grid>
          <Grid item>
            { loading ?
              <Skeleton animation="wave" variant='rect' width={200}/> :
              title && <Typography variant='h5'>{upperFirst(title)}</Typography>
            }
          </Grid>
          <Grid item>
            {fields?.map( (field, index) => (
              loading ?
                <Skeleton animation="wave" variant='text' width={200} key={index}/> :
                field?.data &&
                <Typography variant='subtitle1' key={index}>
                  <b>{upperFirst(translate(field.name))}:</b> {field.data}
                </Typography>
            ))}
          </Grid>
        </Grid>
        {<Grid>
          <div className={classes.logo} style={{ backgroundImage: `url('${imageUrl}')` }} />
        </Grid>}
      </Grid>
    </Grid>
  );
};

export default DetailsHeader;
