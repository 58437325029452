export const GorilaDefault = '#3366FF';
export const GorilaSecondary = '#24C060';
export const RendaFixaDefault = '#815ae8';
export const RendaFixaSecondary = '#9278f4';
export const MultimercadoDefault = '#0DD6F4';
export const MultimercadoSecondary = '#43E7FF';
export const RendaVariavelDefault = '#ed4581';
export const RendaVariavelSecondary = '#f766a1';
export const MoedasDefault = '#fcbd1f';
export const MoedasSecondary = '#ffd364';
export const textDefault = '#333';
export const textSecondary = '#666';
export const Negative = '#ef2e2e';
export const White = '#fff';
export const DarkerWhite = '#eeeeee';
export const Black = '#000';
export const Gray = '#bdbdbd';
export const SoftGray = '#fafafa';
export const Green = '#18ed00';
export const SeaGreen = '#2E8B57';
export const Red = '#ed0008';
export const Orange = '#faa21e';
export const Blue = '#2aa0fa';
export const Pink = '#facfd1';
export const PositveFeedBack = '#188038';
export const NegativeFeedBack = '#C52222';

