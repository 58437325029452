import React from 'react';
import styled from 'styled-components';
import { color } from '../../../shared';

const SourceChilds = ({ child, setSelectedValues, selectedValues, isAllChecked, isChecked, setIsChecked }) => {
  const { orgId, name, role } = child;

  const handleCheckbox = (event) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedValues({ ...selectedValues, sourceChilds: [...selectedValues.sourceChilds, orgId] });
      setIsChecked({ ...isChecked, [orgId]: true });
    } else {
      setSelectedValues({ ...selectedValues, sourceChilds: selectedValues.sourceChilds.filter((child) => child !== orgId) });
      setIsChecked({ ...isChecked, [orgId]: false });
    }
  };

  return (
    <OptionWrapper>
      <span>OrgID: {orgId} <br/>Org Name: {name}<br/>Role: {role}</span>
      <input type="checkbox" name={orgId} checked={(isAllChecked && selectedValues.sourceChilds.includes(orgId)) || ((isChecked[orgId] === true) && selectedValues.sourceChilds.includes(orgId)) } value={orgId} onChange={handleCheckbox} />
    </OptionWrapper>
  );
};

export default SourceChilds;

const OptionWrapper = styled.section`
  padding: 5px;
  border: 1px solid ${ color.GorilaDefault };
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
`;
