import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import EventsImportSection from './FormSection/EventsImportSection';
import EventsGeneratorSection from './FormSection/EventsGeneratorSection';
import EventsTable from './Tables/EventsTable';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const EventsForm = (props) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const {
    events,
    setEvents,
    amortizationType,
    setAmortizationType,
    importEvent,
    setImportEvent,
    eventStartDate,
    setEventStartDate,
    eventEndDate,
    setEventEndDate,
    formValidity,
    displaySnackBar,
    yieldRate,
    dateIsOffLimits
  } = props;

  const [generatedEvents, setGeneratedEvents] = React.useState([]);
  const [importedEvents, setImportedEvents] = React.useState([]);

  const handleImportEventChange = (value) => {
    setImportEvent(value);

    setEvents(value ? importedEvents : generatedEvents);
  };

  return (
    <>
      <h3>{translate('payment flow')}</h3>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button color="primary" className={!importEvent ? classes.active : ''} onClick={() => handleImportEventChange(false)}>
          {translate('generate events')}
        </Button>
        <Button color="primary" className={importEvent ? classes.active : ''} onClick={() => handleImportEventChange(true)}>
          {translate('import events')}
        </Button>
      </Grid>

      {importEvent ?
        <EventsImportSection
          setEvents={setEvents}
          setImportedEvents={setImportedEvents}
          amortizationType={amortizationType}
          setAmortizationType={setAmortizationType}
          displaySnackBar={displaySnackBar}
          yieldRate={yieldRate}
          dateIsOffLimits={dateIsOffLimits}
        /> :
        <EventsGeneratorSection
          setEvents={setEvents}
          setGeneratedEvents={setGeneratedEvents}
          eventStartDate={eventStartDate}
          setEventStartDate={setEventStartDate}
          eventEndDate={eventEndDate}
          setEventEndDate={setEventEndDate}
          formValidity={formValidity}
        />
      }

      <EventsTable
        events={events}
      />
    </>
  );
};

EventsForm.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      eventDate: PropTypes.string,
      eventType: PropTypes.string,
      eventYield: PropTypes.number
    })
  ),
  setEvents: PropTypes.func,
  importEvent: PropTypes.bool,
  setImportEvent: PropTypes.func,
  amortizationType: PropTypes.string,
  setAmortizationType: PropTypes.func,
  eventStartDate: PropTypes.object,
  setEventStartDate: PropTypes.func,
  eventEndDate: PropTypes.object,
  setEventEndDate: PropTypes.func,
  displaySnackBar: PropTypes.func,
  dateIsOffLimits: PropTypes.func,
  formValidity: PropTypes.shape({
    eventStartDate: PropTypes.shape({
      isInvalid: PropTypes.string,
      error: PropTypes.bool
    }),
    eventEndDate: PropTypes.shape({
      isInvalid: PropTypes.string,
      error: PropTypes.bool
    })
  }),
  yieldRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EventsForm;
