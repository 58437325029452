import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { createBrokers } from '../../../services/brokers';
import { color } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: color.GorilaDefault
  },
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    position: 'absolute',
    backgroundColor: '#FFF',
    boxShadow: '7px 7px 10px rgb(0 0 0 / 30%)',
    padding: '20px'
  },
  form: {
    width: '100%',
    margin: '30px 0'
  },
  containerInput: {
    height: '80px',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '300px'
  },
  button: {
    margin: '10px',
    width: '100px',
    borderRadius: '20px'
  },
  buttonDiv: {
    textAlign: 'center'
  },
  warning: {
    marginTop: '15px',
    color: '#888e8e',
    fontSize: '10px',
    textAlign: 'center'
  }
}));

export default function BrokersCreateModal(props) {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  const initialCreateState = {
    displayName: '',
    symbol: '',
    countrySymbol: 'BR'
  };
  const [createValues, setCreateValues] = useState(initialCreateState);
  const [createdModal, setCreatedModal] = useState(false);
  const [createdModalMensage, setCreatedModalMensage] = useState('');
  const showCreatedModalTime = 3000;

  useEffect(() => {
    if (createdModal) {
      const timerRef = setTimeout(() => {
        setCreatedModal(false);
        setCreatedModalMensage('');
        props.closeModal();
      }, showCreatedModalTime);

      return () => clearTimeout(timerRef);
    }
  }, [createdModal]);

  const handleCreateInput = (event) => {
    const auxValues = { ...createValues };
    auxValues[event.target.name] = event.target.value;
    setCreateValues(auxValues);
  };

  const handleCreate = () => {
    const newValue = {
      displayName: createValues.displayName.toUpperCase().trim(),
      legacyBrokerId: 0,
      country: { symbol: createValues.countrySymbol },
      symbol: createValues.symbol.trim()
    };

    createBrokers(newValue).then((response) => {
      setCreatedModalMensage('Brokerage created successfully!');
      setCreatedModal(true);
    }).catch((error) => {
      setCreatedModalMensage('There was an error creating the broker!');
      setCreatedModal(true);
      console.error(error);
    });

    setCreateValues(initialCreateState);
  };

  const handleCloseModal = () => {
    props.closeModal();
    setCreateValues(initialCreateState);
  };

  const validCNPJ = (CNPJ) => {
    CNPJ = CNPJ.replace(/[^\d]+/g, '');

    if (CNPJ === '') return false;

    if (CNPJ === '00000000000000' ||
      CNPJ === '11111111111111' ||
      CNPJ === '22222222222222' ||
      CNPJ === '33333333333333' ||
      CNPJ === '44444444444444' ||
      CNPJ === '55555555555555' ||
      CNPJ === '66666666666666' ||
      CNPJ === '77777777777777' ||
      CNPJ === '88888888888888' ||
      CNPJ === '99999999999999') {
      return false;
    };

    let length = CNPJ.length - 2;
    const digits = CNPJ.substring(length);
    let numbers = CNPJ.substring(0, length);
    let sum = 0;
    let pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== Number(digits.charAt(0))) return false;

    length = length + 1;
    numbers = CNPJ.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== Number(digits.charAt(1))) return false;

    return true;
  };

  const disabledButtonCreate = () => {
    const isValidCountrySymbol = createValues.countrySymbol !== '';
    const isValidDisplayName = createValues.displayName !== '';
    const isValidsymbol = createValues.countrySymbol === 'BR' ? validCNPJ(createValues.symbol) : createValues.symbol !== '';

    return !(isValidCountrySymbol && isValidDisplayName && isValidsymbol);
  };

  const checkCNPJError = (CNPJ) => {
    CNPJ = CNPJ.replace(/[^\d]+/g, '');
    if (CNPJ.length > 0 && createValues.countrySymbol === 'BR') {
      return !(CNPJ.length === 14 && validCNPJ(CNPJ));
    }
  };

  return (
    <Modal open={props.visible} onClose={props.closeModal}>
      {createdModal === false ? (
        <div key='createModal' className={classes.container}>
          <h2 className={classes.title}>{translate('create brokers')}</h2>
          <form noValidate autoComplete='off' className={classes.form}>
            <Grid container className={classes.containerInput}>
              <TextField
                required
                name='displayName'
                label={translate('broker name')}
                className={classes.input}
                onChange={(event) => handleCreateInput(event)}
                margin='normal'
                variant='outlined'
                inputProps={{
                  'data-lpignore': 'true',
                  'role': 'textbox'
                }}
              />
            </Grid>
            <Grid container className={classes.containerInput}>
              <Select
                required
                displayEmpty
                onChange={(event) => {
                  handleCreateInput(event);
                }}
                name='countrySymbol'
                defaultValue=''
                className={classes.input}
                variant='outlined'
              >
                <MenuItem value='' disabled>
                  {translate('country')}
                </MenuItem>
                <MenuItem value={'BR'}>Brasil</MenuItem>
                <MenuItem value={'US'}>Estados Unidos</MenuItem>
              </Select>
            </Grid>{' '}
            <Grid container className={classes.containerInput}>
              <InputMask
                mask={
                  createValues.countrySymbol === 'BR' ?
                    '99.999.999/9999-99' :
                    '**************************************************'
                }
                maskChar=' '
                name='symbol'
                alwaysShowMask={false}
                onChange={(event) => {
                  handleCreateInput(event);
                }}
              >
                {() => (
                  <TextField
                    required
                    error={checkCNPJError(createValues.symbol)}
                    name='symbol'
                    className={classes.input}
                    label={
                      createValues.countrySymbol === 'BR' ?
                        translate('symbol') :
                        translate('description')
                    }
                    margin='normal'
                    variant='outlined'
                    inputProps={{
                      'data-lpignore': 'true',
                      'role': 'textbox'
                    }}
                  />
                )}
              </InputMask>
            </Grid>
          </form>
          <div className={classes.buttonDiv}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={handleCloseModal}
            >
              {translate('close')}
            </Button>
            <Button
              disabled={disabledButtonCreate()}
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={handleCreate}
            >
              {translate('create')}
            </Button>
          </div>
          <p className={classes.warning}>
            {translate(
              'The creation of a new broker takes an average of 1 hour to complete'
            )}
          </p>
        </div>
      ) : (
        <div key='createModal' className={classes.container}>
          <h2 className={classes.title}>{translate(createdModalMensage)}</h2>
        </div>
      )}
    </Modal>
  );
}
