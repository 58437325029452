import React, { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableHead,
  TableRow,
  Snackbar
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TableHeader from './TableHeader';
import TableData from './TableData';
import SearchInput from './SearchInput';

const SearchTable = ({ name, fields, searchService, changeDataItem, actions }) => {
  const { t: translate } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [message, setMessage] = useState('');

  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [search, setSearch] = useState({
    value: '',
    typingTimeout: 0
  });

  const totalColSpan = fields.length + (actions?.length > 0 ? 1 : 0);

  const searchData = useCallback( (searchField) => {
    if (searchField.trim()) {
      setSearchLoading(true);

      searchService(searchField).then((resp) => {
        let data = resp.data;

        if (changeDataItem) {
          data = data.map((item) => changeDataItem(item));
        }

        setData(data);
        setSearchLoading(false);
        setSearchError(false);

        const lastSearchedValue = localStorage.getItem(`${name}Search`);

        if (lastSearchedValue !== searchField) {
          localStorage.setItem(`${name}Search`, searchField);
        } else {
          localStorage.removeItem(`${name}Search`);
        }
      }).catch((err)=>{
        setSearchError(true);
        setSearchLoading(false);
        localStorage.removeItem(`${name}Search`);
      });
    } else {
      setData([]);
    }
  }, [name, searchService, changeDataItem]);

  const handleSearchChange = (event) => {
    setSearchError(false);

    const searchField = event.target.value;

    if (search.typingTimeout) {
      clearTimeout(search.typingTimeout);
    }

    setSearch({
      value: searchField,
      typingTimeout: setTimeout( async function() {
        searchData(searchField);
      }, 1000)
    });
  };

  const clearSearchInput = () => {
    setSearch({ ...search, value: '' });
    setData([]);
    setSearchError(false);
    localStorage.removeItem(`${name}Search`);
  };

  function handleChangePage(event, newpage) {
    setPage(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(arr, compare) {
    if (orderBy === '') return arr;

    return arr.map((item, index) => ({ item, index }))
      .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
      .map(({ item }) => item);
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ?
      (a, b) => descendingComparator(a, b, orderBy) :
      (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  const getTableData = () => {
    return stableSort(data, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  useEffect(() => {
    setPage(0);
    setRowsPerPage(5);
    setOrderBy('');
    setOrder('asc');
  }, [data]);

  useEffect( ()=> {
    const lastSearchedValue = localStorage.getItem(`${name}Search`);

    if (lastSearchedValue) {
      searchData(lastSearchedValue);
      setSearch((prevSearch) => {
        return { ...prevSearch, value: lastSearchedValue };
      });
    }
  }, [name, searchData]);

  return (
    <>
      <Table
        aria-label={translate(`table to search ${name} and display ${name} information`)}
        style={{ width: '100%' }}
      >
        <TableHead>
          <TableRow>
            <TableCell align='center' colSpan={totalColSpan}>
              <SearchInput
                name={name}
                searchLoading={searchLoading}
                searchValue={search.value}
                handleChange={handleSearchChange}
                clearSearchInput={clearSearchInput}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHeader
              fields={fields}
              orderBy={orderBy}
              handleSort={handleSort}
              order={order}
              includeActions={!!actions?.length}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            <TableData
              data={getTableData()}
              fields={fields}
              actions={actions}
              setMessage={setMessage}
              setSelectedItem={setSelectedItem}
            />
          ) : (
            <TableRow>
              <TableCell colSpan={totalColSpan}>
                <Typography variant='h6' color='textSecondary' align='center' gutterBottom>
                  {searchError ?
                    translate('something went wrong, try again later') :
                    search.value.trim() ?
                      translate('data not found for the used filter') :
                      ''}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {data && data.length > 5 &&
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage={'Itens por página'}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      }
      {actions?.map((action, actionIndex) => (
        action.modal &&
        <action.modal.component
          key={actionIndex}
          item={selectedItem}
          updateTable={() => searchData(search.value)}
          setMessage={setMessage}
          open={action.modal.open}
          closeModal={action.modal.closeModal}
        />
      ))}
      {message && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setMessage('')}
          open={!!message}
          message={message}
        />
      )}
    </>
  );
};

export default SearchTable;
