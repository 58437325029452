import axios from 'axios';

export const getIntegrations = (fundId) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}integration`,
    params: {
      fundId
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const startResync = (providerId, fundId, forceCustodyAdjustment = false) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}integration/start`,
    data: {
      providerId,
      fundId,
      forceCustodyAdjustment
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
