export const IntegrationStateEnum = Object.freeze({
  UPDATED: 'UPDATED',
  ERROR_UNEXPECTED: 'ERROR_UNEXPECTED',
  ERROR_PAYLOAD: 'ERROR_PAYLOAD',
  ERROR_AUTHENTICATION: 'ERROR_AUTHENTICATION',
  CALCULATING_WALLET: 'CALCULATING_WALLET',
  ERROR_TIMEOUT: 'ERROR_TIMEOUT'
});

export const IntegrationProviderEnum = Object.freeze({
  B3: 666
});
