import axios from 'axios';

export const getIssuerList = () => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}issuer/list`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createIssuer = (body) => {
  return axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}issuer`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};

export const editIssuer = (body) => {
  return axios.patch(`${process.env.REACT_APP_URL_BACKOFFICE_API}issuer`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: body
  });
};
