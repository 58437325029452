import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid, ptBR, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { getPriceList } from '../../../services/prices';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const productTypeMapping = {
  STOCK_LOCAL: 'AÇÃO',
  STOCK_ETF: 'ETF',
  STOCK_BDR: 'BDR',
  FII: 'FII',
  FUNDQUOTE: 'FUNDO'
};

const columns = [
  {
    field: 'symbol',
    headerName: 'Ativo',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'productType',
    headerName: 'Tipo de Produto',
    minWidth: 200
  },
  {
    field: 'referenceDate',
    headerName: 'Data',
    type: 'date',
    valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
    minWidth: 150
  },
  {
    field: 'price',
    headerName: 'Preço',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    minWidth: 150
  }
];

const PriceSearch = () => {
  const [symbol, setSymbol] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const [productType, setProductType] = React.useState('STOCK_LOCAL');

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const [startDate, setStartDate] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const [endDate, setEndDate] = React.useState(new Date());

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSymbolChange = (e) => {
    const newValue = e.target.value;

    // eslint-disable-next-line no-useless-escape
    setSymbol(newValue.replace(/[\,\@\-\./\!%<>\\$'"]/g, '').toUpperCase());
  };

  const [prices, setPrices] = React.useState([]);

  const handlePriceSearch = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getPriceList({
        productType,
        symbol,
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD')
      });

      const prices = res.data.map((price) => ({ ...price, id: price.referenceDate, productType: productTypeMapping[price.productType] }));

      setPrices(prices);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box my={3}>
        <Container maxWidth="sm">
          <Paper>
            <form onSubmit={(e) => handlePriceSearch(e)} >
              <Box p={3}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Tipo de Produto</InputLabel>
                      <Select
                        value={productType}
                        onChange={handleProductTypeChange}
                        label="Tipo de Produto"
                      >
                        <MenuItem value="STOCK_LOCAL">AÇÃO</MenuItem>
                        <MenuItem value="STOCK_BDR">BDR</MenuItem>
                        <MenuItem value="STOCK_ETF">ETF</MenuItem>
                        <MenuItem value="FII">FII</MenuItem>
                        <MenuItem value="FUNDQUOTE">FUNDO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Ativo"
                      type="search"
                      variant="standard"
                      fullWidth
                      value={symbol}
                      onChange={handleSymbolChange}
                      required
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      margin="normal"
                      label="Data Inicial"
                      value={startDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      margin="normal"
                      label="Data Final"
                      value={endDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" disabled={loading} type="submit">
                      {loading ? <CircularProgress size={25}/> : 'BUSCAR'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>

      <Container maxWidth="md">
        <DataGrid
          localeText={ptBR.props.MuiDataGrid.localeText}
          rows={prices}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          autoHeight
          components={{ Toolbar: CustomToolbar }}
        />
      </Container>
    </>
  );
};

export default PriceSearch;
