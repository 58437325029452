import { Backdrop, Button, Modal } from '@material-ui/core';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import Form from '@rjsf/material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { editPremiumFeatures } from '../../../services/premiumData';
import { color } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: color.GorilaDefault,
    height: '5px'
  },
  container: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    position: 'absolute',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    boxShadow: '7px 7px 10px rgb(0 0 0 / 30%)',
    padding: '10px'
  },
  button: {
    margin: '10px',
    width: '100px',
    borderRadius: '10px',
    color: '#FFF'
  },
  buttonDiv: {
    textAlign: 'center'
  }
}));


const EditPremiumFeatures = (props) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  let schema = {};

  if (props.row) {
    if (!props.row.features) {
      schema = {
        type: 'object',
        properties: {
          name: { type: 'string', title: translate('Name'), default: props.row.name },
          tooltip: { type: 'string', title: translate('Details'), default: props.row.tooltip },
          tier: { type: 'number', title: translate('Tier'), default: props.row.tier }
        }
      };
    } else if (props.row.features) {
      schema = {
        type: 'object',
        properties: {
          order: { type: 'number', title: translate('Order'), default: props.row.order }
        }
      };
    };
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: color.GorilaDefault
      },
      secondary: {
        main: color.GorilaSecondary
      }
    },
    props: {
      MuiTextField: {
        size: 'small',
        variant: 'outlined'
      }
    }
  });

  const uiSchema = {
    tooltip: {
      'ui:widget': 'textarea'
    }
  };

  const onSubmit = (event) => {
    let categoryData;
    let indexFeature;

    if (!props.row.features) {
      props.premiumFeatures.forEach((categoryElement) => {
        categoryElement.features.filter((featureElement, index) => {
          if (featureElement.name === props.row.name) {
            categoryData = categoryElement;
            indexFeature = index;
          }
        });
      });

      categoryData.features[indexFeature] = event.formData;

      editPremiumFeatures(categoryData.category, categoryData);
    } else if (props.row.features) {
      categoryData = props.row;

      categoryData.order = event.formData.order;

      editPremiumFeatures(props.row.category, categoryData);
    };

    props.closeModal();
  };

  return (
    <Modal
      open={props.visible}
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300
      }}
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{translate('EditingModal').toUpperCase()}</h2>
        <MuiThemeProvider theme={theme}>
          <Form schema={schema} uiSchema={uiSchema} onSubmit={(event) => onSubmit(event)} key="editCategory">
            <div className={classes.buttonDiv}>
              <Button
                type='button'
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={props.closeModal}
              >
                {translate('close')}
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                className={classes.button}
                endIcon={<Edit />}
              >
                {translate('edit')}
              </Button>
            </div>
          </Form>
        </MuiThemeProvider>
      </div>
    </Modal >
  );
};

export default EditPremiumFeatures;
