import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Amount from '../../assets/images/icons/Amount.svg';
import LeftArrowIcon from '../../assets/images/icons/chevron_left.svg';
import RightArrowIcon from '../../assets/images/icons/chevron_right.svg';
import Clients from '../../assets/images/icons/clients.svg';
import Portfolios from '../../assets/images/icons/portfolios.svg';
import Organizations from '../../assets/images/icons/organizations.svg';
import CouponsIcon from '../../assets/images/icons/coupon.svg';
import BrokersIcon from '../../assets/images/icons/broker.svg';
import PremiumDataIcon from '../../assets/images/icons/premium-data.svg';
import MenuIcon from '../../assets/images/icons/hamb-menu.svg';
import LogoutIcon from '../../assets/images/icons/logout.svg';
import NotificationIcon from '../../assets/images/icons/notification.svg';
import DollarIcon from '../../assets/images/icons/dollar.svg';
import Logo from '../../assets/images/logo/logo_gorila.svg';
import { revokeToken } from '../../context/actions/auth';
import { useAuthDispatch, useAuthState } from '../../context/authentication';
import { checkAuthorization, removeToken } from '../../services/auth';
import ConfirmationModal from '../modals/ConfirmationModal';
import { SecurityIcon } from '../../assets/images/icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
    backgroundColor: '#fff',
    marginBottom: '2rem'
  },
  logo: {
    width: '10rem'
  },
  drawerPaper: {
    width: 240
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  icon: {
    width: '24px',
    height: '24px'
  }
}));

const Header = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const enabledFeatures = process.env.REACT_APP_ENABLED_FEATURES.split(',');
  const menuItems = [
    {
      pageName: 'users',
      icon: Clients,
      callback: () => goToRoute(`/user`),
      permission: 'read:users'
    },
    {
      pageName: 'portfolios',
      icon: Portfolios,
      callback: () => goToRoute(`/portfolios`),
      permission: 'read:portfolio'
    },
    {
      pageName: 'organizations',
      icon: Organizations,
      callback: () => goToRoute(`/organizations`),
      permission: 'read:organizations'
    },
    {
      pageName: 'coupons',
      icon: CouponsIcon,
      callback: () => goToRoute(`/coupons`),
      permission: 'read:coupons'
    },
    {
      pageName: 'enterprises',
      icon: BrokersIcon,
      callback: () => goToRoute(`/enterprises`),
      permission: 'read:brokers'
    },
    {
      pageName: 'premiumData',
      icon: PremiumDataIcon,
      callback: () => goToRoute(`/premiumdata`),
      permission: 'read:premium'
    },
    {
      pageName: 'notifications',
      icon: NotificationIcon,
      callback: () => goToRoute(`/notification`),
      permission: 'read:notifications'
    },
    {
      pageName: 'ETL',
      icon: Amount,
      callback: () => goToETL(),
      permission: 'access:etl'
    },
    {
      pageName: 'prices',
      icon: DollarIcon,
      callback: () => goToRoute(`/prices`),
      permission: 'read:prices'
    },
    {
      pageName: 'securities',
      icon: SecurityIcon,
      callback: () => goToRoute(`/securities`),
      permission: 'write:securities'
    }
  ];

  const goToRoute = (url) => {
    setOpen(false);
    history.push(url);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const logout = () => {
    removeToken(() => dispatch(revokeToken()));
    setOpen(false);
    setModalVisible(false);
  };

  const messageFromChild = (childWindow, event) => {
    if (event && event.origin === process.env.REACT_APP_URL_ETL) {
      childWindow.postMessage(
        {
          accessToken: localStorage.getItem('accessToken'),
          idToken: localStorage.getItem('idToken')
        },
        process.env.REACT_APP_URL_ETL
      );
    }
  };

  const goToETL = () => {
    const etl = window.open(process.env.REACT_APP_URL_ETL);
    // eslint-disable-next-line no-invalid-this
    window.addEventListener('message', messageFromChild.bind(this, etl));
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {state.isAuthenticate && (
            <IconButton
              color="inherit"
              aria-label={translate('open menu')}
              onClick={handleDrawerOpen}
              edge="start"
              className={`${classes.menuButton} ${open && classes.hide}`}
              data-testid="menuButton"
            >
              <img alt="" src={MenuIcon} />
            </IconButton>
          )}
          <img src={Logo} className={classes.logo} alt="logo" />
        </Toolbar>
      </AppBar>
      {state.isAuthenticate && (
        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
          open={open}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <img
                alt=""
                src={theme.direction === 'ltr' ? LeftArrowIcon : RightArrowIcon}
              />
            </IconButton>
          </div>
          <Divider />
          <List>
            {menuItems.map((item) =>
              enabledFeatures.includes(item.pageName) &&
              checkAuthorization(item.permission) ? (
                  <ListItem
                    key={item.pageName}
                    button
                    onClick={item.callback}
                    data-testid={`goTo${item.pageName}PageButton`}
                  >
                    <ListItemIcon>
                      <img alt="" className={classes.icon} src={item.icon} />
                    </ListItemIcon>
                    <ListItemText primary={translate(`${item.pageName}`)} />
                  </ListItem>
                ) : (
                  ''
                )
            )}
            <ListItem
              button
              onClick={() => setModalVisible(true)}
              data-testid="logoutButton"
            >
              <ListItemIcon>
                <img alt="" src={LogoutIcon} />
              </ListItemIcon>
              <ListItemText primary={translate('log out')} />
            </ListItem>
          </List>
        </Drawer>
      )}
      <ConfirmationModal
        openModal={modalVisible}
        closeModal={handleCloseModal}
        confirm={logout}
        name={translate('confirm log out')}
      />
    </>
  );
};

export default Header;
