import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { makeStyles, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { color } from '../../shared';

const useStyles = makeStyles((theme) => ({
  loading: {
    marginLeft: '0.5rem',
    color: color.GorilaDefault
  }
}));

const UpdatingModal = ({
  openModal,
  closeModal,
  name,
  textFields = [],
  confirm,
  id,
  disabled,
  inputChangedHandler,
  children,
  loading
}) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="Diálogo de edição"
      aria-describedby="Diálogo de edição"
      data-testid="updateModal"
    >
      <DialogContent style={{ maxWidth: '25rem' }}>
        <DialogContentText>{name}</DialogContentText>
        {children}
        <Grid item>
          {textFields.map((type) => {
            return (
              <TextField
                key={type}
                fullWidth
                onChange={(event) => inputChangedHandler({ [type]: event.target.value })}
                id={type}
                label={translate(type)}
                margin='dense'
                variant='outlined'
                size='small'
                inputProps={{ 'data-testid': `${type}Input` }}
              />
            );
          })}
        </Grid>
        <DialogActions>
          <Button onClick={closeModal} variant='contained' data-testid='cancel' color='primary'>
            {translate('cancel')}
          </Button>
          <Button onClick={confirm} key={id} color='primary' data-testid='save' disabled={disabled} autoFocus>
            {translate('save')}
            {loading && <CircularProgress className={classes.loading} size='1rem' />}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatingModal;
