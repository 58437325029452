import React from 'react';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingHeaderCell: {
    width: '20%'
  },
  loadingBodyCell: {
    height: '5rem'
  }
});

const LoadingTable = ({ headerCells, tableCells }) => {
  const classes = useStyles();
  const loadingHeaderCells = [...Array(headerCells).keys()];
  const loadingTableCells = [...Array(tableCells).keys()];

  return (
    <MaterialTable data-testid="loading-table">
      <TableHead>
        <TableRow>
          {
            loadingHeaderCells.map((headerCell) => (
              <TableCell key={headerCell} className={classes.loadingHeaderCell}><Skeleton height={18} /></TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          loadingTableCells.map((tableCell) => (
            <TableRow key={tableCell} className={classes.loadingBodyCell}>
              {loadingHeaderCells.map((headerCell) => (
                <TableCell key={headerCell}><Skeleton height="16" /></TableCell>
              ))}
            </TableRow>
          ))
        }
      </TableBody>
    </MaterialTable>
  );
};

export default LoadingTable;
