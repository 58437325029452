import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import FormRadioGroup from '../../../../../shared/forms/FormRadioGroup';
import FormDatePicker from '../../../../../shared/forms/FormDatePicker';
import dayjs from 'dayjs';

const eventTypes = [
  'interest'
];

const eventFrequencies = {
  'monthly': 1,
  'semiannually': 6,
  'annually': 12
};

const EventsGeneratorSection = (props) => {
  const { t: translate } = useTranslation();

  const {
    setEvents,
    setGeneratedEvents,
    eventStartDate,
    setEventStartDate,
    eventEndDate,
    setEventEndDate,
    formValidity
  } = props;

  const [eventType, setEventType] = React.useState('interest');
  const [eventFrequency, setEventFrequency] = React.useState('monthly');

  const handleValueChange = (onChange) => (event) => {
    onChange(event.target.value);
  };

  const handleDateChange = (onChange) => (date) => {
    onChange(date);
  };

  const generateEvents = () => {
    const createdEvents = [];
    let currentDate = dayjs(eventStartDate);
    const finalDate = dayjs(eventEndDate).add(1, 'day');
    const monthStep = eventFrequencies[eventFrequency];

    while (currentDate.isBefore(finalDate)) {
      createdEvents.push({
        eventDate: currentDate.format('YYYY-MM-DD'),
        eventType: 'interest',
        eventYield: 1
      });
      currentDate = currentDate.add(monthStep, 'month');
    }

    setGeneratedEvents(createdEvents);
    setEvents(createdEvents);
  };

  return (
    <>
      <FormRadioGroup
        label={translate('event type')}
        value={eventType}
        onChange={handleValueChange(setEventType)}
        options={eventTypes}
      />

      <FormDatePicker
        label="start date"
        value={eventStartDate}
        onChange={handleDateChange(setEventStartDate)}
        error={formValidity.eventStartDate.isInvalid}
        helperText={formValidity.eventStartDate.error}
      />

      <FormDatePicker
        label="end date"
        value={eventEndDate}
        onChange={handleDateChange(setEventEndDate)}
        error={formValidity.eventEndDate.isInvalid}
        helperText={formValidity.eventEndDate.error}
      />

      <FormRadioGroup
        label={translate('frequency')}
        value={eventFrequency}
        onChange={handleValueChange(setEventFrequency)}
        options={Object.keys(eventFrequencies)}
      />

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          component="label"
          onClick={generateEvents}
        >
          {translate('generate')}
        </Button>
      </Grid>
    </>
  );
};

EventsGeneratorSection.propTypes = {
  setEvents: PropTypes.func,
  setGeneratedEvents: PropTypes.func,
  eventStartDate: PropTypes.object,
  setEventStartDate: PropTypes.func,
  eventEndDate: PropTypes.object,
  setEventEndDate: PropTypes.func,
  formValidity: PropTypes.shape({
    eventStartDate: PropTypes.shape({
      isInvalid: PropTypes.string,
      error: PropTypes.bool
    }),
    eventEndDate: PropTypes.shape({
      isInvalid: PropTypes.string,
      error: PropTypes.bool
    })
  })
};

export default EventsGeneratorSection;
