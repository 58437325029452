import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CouponList from './CouponList/CouponList';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '35px'
  }
}));

const Coupons = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <CouponList />
    </div>
  );
};

export default Coupons;
