import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from '../../styles';

const RowFeature = (props) => {
  const { editable, expandable, onEditClick, onFeatureEditClick, row } = props;
  const { t: translate } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {expandable && <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </TableCell>}
        <TableCell>{row.category}</TableCell>
        <TableCell>{row.order}</TableCell>
        {editable && <TableCell>
          <IconButton size="small" onClick={() => onEditClick(row)}><EditIcon /></IconButton>
        </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableRow}>{translate('Name')}</TableCell>
                    <TableCell className={classes.tableRow}>{translate('Tooltip')}</TableCell>
                    <TableCell className={classes.tableRow}>{translate('Tier')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.features ? row.features.map((innerRow, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{innerRow.name}</TableCell>
                      <TableCell className={classes.tableCell}>{innerRow.tooltip}</TableCell>
                      <TableCell className={classes.tableCell}>{innerRow.tier}</TableCell>
                      <TableCell>
                        <IconButton size="small" onClick={() => onFeatureEditClick(innerRow)}
                        ><EditIcon /></IconButton>
                      </TableCell>
                    </TableRow>
                  )) : ''}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
};

export default RowFeature;
