import React from 'react';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CustomTextField = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  error,
  type,
  helperText,
  endAdornment
}) => {
  const { t: translate } = useTranslation();

  return (
    <TextField
      id={`input-${name}`}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error}
      label={translate(name)}
      type={type}
      autoComplete="off"
      margin='dense'
      variant='outlined'
      size='small'
      fullWidth
      helperText={helperText}
      inputProps={{ 'data-lpignore': 'true', 'role': 'textbox' }}
      InputProps={{
        endAdornment: endAdornment ? (
          <InputAdornment position="end">
            <IconButton onClick={endAdornment.onClick} edge="end">
              {endAdornment.icon}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
    />
  );
};

export default CustomTextField;
