import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { color } from '../../../shared';
import { transferAccount } from '../../../services/user';
import { ArrowRightIcon } from '../../../assets/images/icons/index';
import TransferForm from './TransferForm';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '1rem',
    borderRadius: '20px'
  },
  loading: {
    marginLeft: '0.5rem',
    color: color.White
  },
  arrowRightIcon: {
    width: '30px'
  }
}));

const TransferPortfolio = () => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const initialValuesState = { userOrigemPortfolioId: '', userDestinoPortfolioId: '' };
  const [message, setMessage] = useState('');
  /* eslint no-unused-vars:*/
  const [_, setErrorState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValuesState);
  const desktop = useMediaQuery('(min-width:992px)');

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value.replace(/\D/, '') });
  };

  const clearState = () => {
    setErrorState(false);
  };

  const handleClose = () => {
    setMessage('');
  };

  const handleError = (error) => {
    let message = '';
    switch (error.message) {
    case 'Request failed with status code 404':
    case 'User not found':
      message = translate('user not found or no trades');
      break;
    case 'User without manual trades':
      message = translate(error.message);
      break;
    default:
      message = translate('transfer Account Error');
      break;
    }
    setMessage(message);
  };

  const handleTransfer = () => {
    if (!isFormInvalid() && !loading) {
      transfer();
    }
  };

  const transfer = async () => {
    setLoading(true);
    setErrorState(false);
    try {
      await transferAccount({ sourcePortfolioId: values.userOrigemPortfolioId, destinationPortfolioId: values.userDestinoPortfolioId });
      setMessage(translate('Account transfered with success'));
      setValues(initialValuesState);
      clearState();
    } catch (error) {
      setErrorState(true);
      handleError(error);
    }
    setLoading(false);
  };

  const isFormInvalid = () => Object.values(values).some((value) => (value === ''));

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <TransferForm item values={values} title = "Portfólio de Origem" userType="Origem" handleChange={handleChange} />
        {
          desktop && <img className={ classes.arrowRightIcon } src={ ArrowRightIcon } alt="right arrow"/>
        }
        <TransferForm item values={values} title = "Portfólio de Destino" userType="Destino" handleChange={handleChange} />
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          data-testid="transfer-button"
          disabled={isFormInvalid()}
          onClick={handleTransfer}
          className={classes.button}
        >
          {translate('transfer')}
          {
            loading &&
            <CircularProgress data-testid="loading" className={classes.loading} size="1rem" />
          }
        </Button>
      </Grid>
      {
        message &&
        <Snackbar
          data-testid="message"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          key={`bottom,center`}
          open={true}
          onClose={handleClose}
          message={message}
        />
      }
    </>
  );
};

export default TransferPortfolio;
