import React, { useState } from 'react';
import { listOrganizations } from '../../../services/organizations';
import { SearchTable } from '../../../shared/components';
import { Grid, Paper } from '@material-ui/core';
import { EditOrgModal, PortfolioRerunModal } from './Modals';
import { Organization } from '../../../assets/images/icons';
import { useHistory } from 'react-router-dom';

const OrganizationSearch = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rerunModalOpen, setRerunModalOpen] = useState(false);
  const history = useHistory();

  const goToDetails = ({ dataItem: org }) => {
    history.push(`/organizations/details/${org.id}`, { orgData: org });
  };

  const tableFields = [{
    name: 'name',
    key: 'name',
    sortable: true,
    translateData: false
  },
  {
    name: 'orgId',
    key: 'id',
    sortable: false,
    translateData: false
  },
  {
    name: 'taxId',
    key: 'taxId',
    sortable: true,
    translateData: false
  }];

  const actions = [{
    inMenu: true,
    type: 'updateOrganization',
    authPermission: 'write:organizations',
    srcImg: 'Platform/Edit',
    action: () => setEditModalOpen(true),
    modal: {
      component: EditOrgModal,
      open: editModalOpen,
      closeModal: () => setEditModalOpen(false)
    }
  },
  {
    inMenu: true,
    type: 'rerunPortfolio',
    authPermission: 'update:portfolio',
    srcImg: 'Platform/Sync',
    action: () => setRerunModalOpen(true),
    modal: {
      component: PortfolioRerunModal,
      open: rerunModalOpen,
      closeModal: () => setRerunModalOpen(false)
    }
  },
  {
    type: 'organization details',
    authPermission: 'read:organizations',
    iconWidth: '26px',
    action: goToDetails,
    srcImg: Organization
  }];

  return (
    <Grid container justifyContent="center">
      <Grid item style={{ marginTop: '2rem', marginBottom: '2rem', width: '70%' }}>
        <Paper>
          <SearchTable
            name='organization'
            fields={tableFields}
            searchService={listOrganizations}
            actions={actions}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrganizationSearch;
