import axios from 'axios';

export const deletePortfolioTrades = (portfolioId) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/${portfolioId}/trades`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  });
};

export const rerunPortfolio = (portfolioId) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/rerun`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    data: { portfolioId }
  });
};

export const clonePortfolio = (params) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/clone`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    data: params
  });
};


export const getPortfolioList = async (search) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio`,
    params: {
      search
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const readPortfolio = async (portfolioId) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/${portfolioId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const uploadSinacorFile = async (file, portfolioId, importCosts) => {
  const form = new FormData();
  form.append('file', file);

  try {
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_URL_BACKOFFICE_API}brokerage-notes/upload/${portfolioId}`,
      params: {
        extractCosts: importCosts
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      data: form
    });
    return data;
  } catch (err) {
    if (err.response?.status === 409) {
      return null;
    }
    throw err;
  }
};

export const validateSinacorFile = async (file, maxFiles, maxSize) => {
  const form = new FormData();
  form.append('file', file);
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}brokerage-notes/validate`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    data: form,
    params: {
      maxFiles,
      maxSize
    }
  });

  return data;
};

export const listPortfolioUsers = async (params) => {
  const { portfolioId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user-portfolio`,
    params: {
      portfolio: portfolioId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listPortfolioOrganizationUsers = async (params) => {
  const { portfolioId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    params: {
      portfolio: portfolioId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listPortfolioOrganizations = async (params) => {
  const { portfolioId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-portfolio`,
    params: {
      portfolio: portfolioId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
