import MaterialTable from '@material-table/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCouponList } from '../../../services/coupon';
import { MaterialIcons } from '../../../shared/MaterialIcons';
import CouponModals from '../Modals/CouponModals';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '85%'
  }
}));

const CouponList = () => {
  const [couponList, setCouponList] = useState([]);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [modal, setModal] = useState([0, '']);

  useEffect(() => {
    getCoupounsList();
  }, [openModal]);

  const getCoupounsList = () => {
    getCouponList()
      .then((couponList) => {
        couponList.data.map((coupon) => {
          coupon.until = (new Date(coupon.until)).toLocaleDateString('pt-BR');
          coupon.from = (new Date(coupon.from)).toLocaleDateString('pt-BR');

          coupon.status = translate(coupon.status);
        });
        setCouponList(couponList.data);
      })
      .catch((error) => {
        console.error(error);
        return setCouponList([]);
      });
  };

  const closeModal = () => {
    setOpenModal(false);
    setModal([0, '']);
    getCoupounsList();
  };

  return (
    <div className={classes.table}>
      <MaterialTable
        title={translate('coupon table')}
        icons={{
          NextPage: MaterialIcons.NextPage,
          FirstPage: MaterialIcons.FirstPage,
          PreviousPage: MaterialIcons.PreviousPage,
          LastPage: MaterialIcons.LastPage,
          ResetSearch: MaterialIcons.Clear,
          Search: MaterialIcons.Search
        }}
        options={{
          sorting: false,
          draggable: false,
          actionsColumnIndex: -1,
          paginationType: 'stepped',
          headerStyle: { fontWeight: 'bold', fontSize: '18px' }
        }}
        localization={{
          header: {
            actions: translate('actions')
          },
          toolbar: {
            searchTooltip: translate('search coupon'),
            searchPlaceholder: translate('search coupon')
          },
          pagination: {
            labelRowsSelect: translate('rows'),
            firstTooltip: translate('first page'),
            nextTooltip: translate('next page'),
            previousTooltip: translate('previous page'),
            lastTooltip: translate('last page')
          }
        }}
        columns={[
          { title: translate('code'), field: 'code' },
          { title: translate('status'), field: 'status' },
          { title: translate('cycles'), field: 'cycles' },
          { title: translate('amount'), field: 'amount' },
          { title: translate('from'), field: 'from' },
          { title: translate('until'), field: 'until' },
          { title: translate('advisorId'), field: 'advisorId' },
          { title: translate('plan'), field: 'plan' }
        ]}
        data={couponList}
        actions={[
          {
            icon: MaterialIcons.Add,
            tooltip: translate('add coupon'),
            position: 'toolbar',
            onClick: () => {
              setModal([0, '']);
              setOpenModal(true);
            }
          },
          (rowData) => ({
            disabled: rowData.status === translate('EXPIRED'),
            icon: MaterialIcons.Edit,
            tooltip: rowData.status === translate('ACTIVE') ? translate('edit coupon') : translate('expired coupon'),
            onClick: (event, row) => {
              setModal([1, row]);
              setOpenModal(true);
            }
          }),
          (rowData) => ({
            disabled: rowData.wasUsed,
            icon: MaterialIcons.Delete,
            tooltip: rowData.wasUsed ? translate('was used coupon') : translate('delete coupon'),
            onClick: (event, row) => {
              setModal([2, row]);
              setOpenModal(true);
            }
          })
        ]}
      />
      <CouponModals visible={openModal} closeModal={closeModal} modal={modal} />
    </div>
  );
};

export default CouponList;
