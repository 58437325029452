import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import SourceChilds from './SourceChilds';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: '140px',
    maxHeight: '500px',
    minWidth: '270px',
    maxWidth: '550px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '3rem',
    margin: '1rem'
  }
}));

const SourceForm = ({ childs, handleSourceInput, setSelectedValues, selectedValues, setIsAllChecked, isAllChecked, setIsChecked, isChecked }) => {
  const { searchingValue, foundValues } = childs;
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const userFundId = `Origem`;

  const handleCheckAll = (event) => {
    const { checked } = event.target;

    if (checked) {
      setIsAllChecked(true);
      setSelectedValues({ ...selectedValues, sourceChilds: foundValues?.map((child) => child.orgId) });
    } else {
      setIsAllChecked(false);
      setSelectedValues({ ...selectedValues, sourceChilds: [] });
      setIsChecked({});
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' align="center" color="primary" >{translate('source advisor')}</Typography>
      <TextField
        id={userFundId}
        value={searchingValue}
        label={`UserId ${userFundId}`}
        onChange={handleSourceInput}
        type='text'
        margin='normal'
        fullWidth
      />
      { foundValues?.length ? (
        <span>
          Select All <input type="checkbox" name={'Select All'} checked={isAllChecked} value={foundValues} onChange={handleCheckAll} />
        </span> ) : null }

      <ChildContainer show={ foundValues?.length > 7 } >
        {foundValues?.map((child, i) => (
          <SourceChilds child={child} key={i} isAllChecked={isAllChecked} setSelectedValues={setSelectedValues} selectedValues={selectedValues} isChecked={isChecked} setIsChecked={setIsChecked} />
        ))}
      </ChildContainer>
    </Paper>
  );
};

export default SourceForm;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  overflow-y: scroll;
  margin-top: ${(props) => props.show ? '10px' : '0px' };
  padding-bottom: ${(props) => props.show ? '10px' : '0px' };
  &::-webkit-scrollbar {
    display: ${(props) => props.show ? 'show' : 'none' };
  }
`;
