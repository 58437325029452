import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import BrokersList from './BrokersList/BrokersList';
import IssuerList from './IssuerList/IssuerList';
import { getBrokersList } from '../../services/brokers';
import { getIssuerList } from '../../services/issuers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '10px'
  },
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const Enterprises = () => {
  const classes = useStyles();
  const [page, setPage] = useState('brokers');
  const [refreshList, setRefreshList] = useState(false);
  const [issuerList, setIssuerList] = useState([]);
  const [brokersList, setBrokersList] = useState([]);

  useEffect(() => {
    getBrokersList()
      .then((brokersList) => {
        setBrokersList(brokersList.data);
      })
      .catch((error) => {
        console.error(error);
      });
    getIssuerList()
      .then((issuerList) => {
        setIssuerList(issuerList.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [refreshList]);

  const brokerComponent = (
    <BrokersList setRefreshList={setRefreshList} refreshList={refreshList} brokersList={brokersList} />
  );

  const issuerComponent = (
    <IssuerList setRefreshList={setRefreshList} refreshList={refreshList} issuerList={issuerList} setIssuerList={setIssuerList} />
  );

  return (
    <div className={classes.container}>
      <Grid>
        <Button color='primary' className={page === 'brokers' ? classes.active : ''} onClick={() => setPage('brokers')} >Corretoras</Button>
        <Button color='primary' className={page === 'issuers' ? classes.active : ''} onClick={() => setPage('issuers')} >Emissoras</Button>
      </Grid>
      {page === 'brokers' ? brokerComponent : issuerComponent}
    </div>
  );
};

export default Enterprises;
