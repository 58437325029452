import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { color } from '..';

const useStyles = makeStyles((theme) => ({
  loading: {
    marginLeft: '0.5rem',
    color: color.GorilaDefault
  }
}));

const ConfirmationModal = (props) => {
  const { openModal, closeModal, testid, name, confirm, additionalInfo, save, cancel, single, loading, disabled } = props;
  const { t: translate } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={closeModal}
      aria-labelledby="Diálogo de confirmação"
      aria-describedby="Diálogo de confirmação"
      data-testid={testid}
    >
      <DialogContent>
        <DialogContentText component={'div'}>
          {name}
        </DialogContentText>
        <DialogContentText data-testid="info" component={'div'}>
          {additionalInfo}
        </DialogContentText>
        <DialogActions>
          {!single &&
          <Button onClick={closeModal} variant={cancel} data-testid="no" color="primary">
            {save ? translate('cancel') : translate('no')}
          </Button>
          }
          <Button onClick={confirm} variant={single ? cancel : save} color="primary" data-testid="yes" disabled={disabled} autoFocus>
            {save ? translate('save') : single ? translate('close') : translate('yes')}
            {
              loading &&
              <CircularProgress className={classes.loading} size="1rem" />
            }
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
