import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdatingModal } from '../../../../shared';
import { updateUser } from '../../../../services/user';

const EditUserModal = ({ open, closeModal, item: user, setMessage, updateTable }) => {
  const [userInput, setUserInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { t: translate } = useTranslation();

  const inputChangedHandler = (values) => {
    setUserInput({ ...userInput, ...values });
    setDisabled(Object.values(values).some((item) => !item.trim()));
  };

  const errorMessage = (error) => {
    const response = error.response || error;
    switch (response.status) {
    case 409:
      setMessage(translate('email already exists'));
      break;
    case 403:
      setMessage(translate('permission denied'));
      break;
    default:
      setMessage(translate(`NOT updated user`));
      break;
    }
  };

  const handleClose = () => {
    setUserInput({});
    setDisabled(true);
    setLoading(false);
    closeModal();
  };

  const confirmUpdate = async () => {
    setLoading(true);
    setDisabled(true);

    try {
      await updateUser({ id: user.id, ...userInput });
      setMessage(translate(`updated user`));
      updateTable();
    } catch (err) {
      errorMessage(err);
    }
    handleClose();
  };

  return (
    <>
      <UpdatingModal
        openModal={open}
        closeModal={() => handleClose()}
        confirm={() => confirmUpdate()}
        name={translate('update')}
        testid='edit-user-modal'
        textFields={['name', 'email']}
        inputChangedHandler={inputChangedHandler}
        disabled={disabled}
        loading={loading}
      />
    </>
  );
};

export default EditUserModal;
