import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { checkAuthorization } from '../../../services/auth';

const IconAction = ({ testid, type, permissionType, action, srcImg, width, setMessage }) => {
  const { t: translate } = useTranslation();

  let iconPath = srcImg;

  if (!iconPath.endsWith('.svg')) {
    iconPath = `${process.env.REACT_APP_ICON_PATH}/${srcImg}.svg`;
  }

  const callActionToAuhtorizedUsers = (event) => {
    if (!permissionType || checkAuthorization(permissionType)) return action(event);
    setMessage(translate('permission denied'));
  };

  return (
    <>
      <Tooltip title={translate(`${type}`)}>
        <IconButton
          aria-label={translate(`${type}`)}
          data-testid={testid}
          onClick={callActionToAuhtorizedUsers}
        >
          <img src={iconPath} alt={translate(`${type}`)} width={width} height={width} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default IconAction;
