import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

const PremiumDataTable = ({ HeaderCells, BodyRows }) =>
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          {HeaderCells}
        </TableRow>
      </TableHead>
      <TableBody>
        {BodyRows}
      </TableBody>
    </Table>
  </TableContainer>;

export default PremiumDataTable;
