import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';

const FormRadioGroup = (props) => {
  const { label, value, onChange, options } = props;

  const { t: translate } = useTranslation();

  return (
    <Grid item xs={12}>
      <FormControl variant="standard" fullWidth required>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          name="radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {options.map((option, i) => (
            <FormControlLabel
              value={option}
              label={translate(option)}
              key={i}
              control={<Radio size="small" />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

FormRadioGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string)
};

export default FormRadioGroup;
