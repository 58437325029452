import React from 'react';
import { removeAdvisorLogo, uploadAdvisorLogo } from '../../../../services/user';
import { MegaBytes } from '../../../../shared/bytesConversion';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '../../../../shared/components';

const ImportLogo = ({ setLogo, setMessage, data }) => {
  const { t: translate } = useTranslation();

  const uploadInfo = {
    name: 'PNG',
    type: '.png',
    maxSize: 1*MegaBytes,
    contentType: [
      'image/png'
    ],
    requirements: [
      'insert the image 300px width and 100px height',
      '1 MB file size limit'
    ]
  };

  const handleUpload = async (file) => {
    await removeAdvisorLogo(data.id);
    const response = await uploadAdvisorLogo(file, data.id);
    setLogo(`${response.data.url}?${Date.now()}`);
    setMessage(translate('uploaded files'));
  };

  return <UploadFile
    uploadInfo={uploadInfo}
    setMessage={setMessage}
    handleUploadFile={handleUpload}
  />;
};

export default ImportLogo;
