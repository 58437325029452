import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField
} from '@material-ui/core';
import SuccessSnackBar from '../../../shared/snackbar/SuccessSnackBar';
import DataConfirmationModal from '../../../shared/modals/DataConfirmationModal';
import FormDatePicker from '../../../shared/forms/FormDatePicker';
import FormRadioGroup from '../../../shared/forms/FormRadioGroup';
import EventsForm from './EventsForm/EventsForm';
import EventsTable from './EventsForm/Tables/EventsTable';
import { postSecurity } from '../../../services/securities';
import { percentToNominal } from '../../../shared/functions/math';
import InformationModal from '../../../shared/modals/InformationModal';

const productTypes = [
  'CORPORATE_BONDS_CRI',
  'CORPORATE_BONDS_CRA'
];

const annualBases = {
  252: {
    yieldDayCount: 'workingDays'
  },
  360: {
    yieldDayCount: 'actualDays'
  }
};

const yieldRateRange = {
  spread: {
    minYield: 0,
    maxYield: 50
  },
  ratio: {
    minYield: 50,
    maxYield: 500
  }
};

const indexes = {
  'di%': {
    index: 'di',
    dealType: 'ratio',
    classification: 'PRIVATE_CREDIT_CDI_INDEX',
    pricingMethod: 'DEBENTURE_BASIC_INTEREST',
    indexRelation: 'interest',
    ...yieldRateRange.ratio
  },
  'di+': {
    index: 'di',
    dealType: 'spread',
    classification: 'PRIVATE_CREDIT_CDI_INDEX',
    pricingMethod: 'DEBENTURE_BASIC_INTEREST',
    indexRelation: 'interest',
    ...yieldRateRange.spread
  },
  'igpm': {
    index: 'igpm',
    dealType: 'spread',
    classification: 'PRIVATE_CREDIT_IGPM_INDEX',
    pricingMethod: 'DEBENTURE_INFLATION',
    indexRelation: 'faceValue',
    ...yieldRateRange.spread
  },
  'ipca': {
    index: 'ipca',
    dealType: 'spread',
    classification: 'PRIVATE_CREDIT_IPCA_INDEX',
    pricingMethod: 'DEBENTURE_INFLATION',
    indexRelation: 'faceValue',
    ...yieldRateRange.spread
  },
  'pre': {
    index: 'pre',
    dealType: 'spread',
    classification: 'PRIVATE_CREDIT_PREFIXED',
    pricingMethod: 'BANK_SECURITIES_PRE',
    indexRelation: null,
    ...yieldRateRange.spread
  }
};

const proRataStyles = ['workingDays', 'actualDays'];

const indexUpdateFrequencies = ['daily', 'monthly', 'annually'];

const floatRegExp = new RegExp('^$|^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
const alphaNumericOrEmptyRegExp = new RegExp('^$|^[A-Za-z0-9]+$');

const CorporateBondsInsert = () => {
  const { t: translate } = useTranslation();

  const [openDataModal, setOpenDataModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const [issueDate, setIssueDate] = React.useState(new Date());
  const [yieldDate, setYieldDate] = React.useState(new Date());
  const [maturityDate, setMaturityDate] = React.useState(new Date());

  const [symbol, setSymbol] = React.useState('');
  const [productType, setProductType] = React.useState('CORPORATE_BONDS_CRI');
  const [isin, setIsin] = React.useState('');
  const [issueFaceValue, setIssueFaceValue] = React.useState(1000);
  const [index, setIndex] = React.useState('di%');
  const [yieldRate, setYieldRate] = React.useState('');

  const [indexLag, setIndexLag] = React.useState(1);
  const [annualBasis, setAnnualBasis] = React.useState('252');
  const [proRataStyle, setProRataStyle] = React.useState('workingDays');
  const [anniversaryDate, setAnniversaryDate] = React.useState(new Date());
  const [indexUpdateFrequency, setIndexUpdateFrequency] =
    React.useState('daily');
  const [hasInterestStep, setHasInterestStep] = React.useState(false);

  const [events, setEvents] = React.useState([]);
  const [importEvent, setImportEvent] = React.useState(false);
  const [eventStartDate, setEventStartDate] = React.useState(new Date());
  const [eventEndDate, setEventEndDate] = React.useState(new Date());
  const [amortizationType, setAmortizationType] = React.useState('remainingAmortization');

  const [security, setSecurity] = React.useState({});
  const [confirmationFormData, setConfirmationFormData] = React.useState({});

  const [errorModalOpen, setErrorModalOpen] = React.useState(false);

  const dateIsOffLimits = (date) => date < issueDate || date > maturityDate;

  const isValidYield = (value) => value >= indexes[index].minYield &&
    value <= indexes[index].maxYield;

  const isSpread = (index) => index !== 'di%';

  const isDiIndexed = (index) => ['di%', 'di+'].includes(index);

  const isInflation = useMemo(() => ['ipca', 'igpm'].includes(index), [index]);

  const formValidity = useMemo(() => ({
    yieldDate: {
      isInvalid: dateIsOffLimits(yieldDate),
      error: translate('must be after issue and before maturity date')
    },
    maturityDate: {
      isInvalid: (maturityDate < issueDate),
      error: translate('must be after issue date')
    },
    anniversaryDate: {
      isInvalid: (isInflation && dateIsOffLimits(anniversaryDate)),
      error: translate('must be after issue and before maturity date')
    },
    eventStartDate: {
      isInvalid: (!importEvent && dateIsOffLimits(eventStartDate)),
      error: translate('must be after issue and before maturity date')
    },
    eventEndDate: {
      isInvalid: (!importEvent && (
        dateIsOffLimits(eventEndDate) || eventEndDate < eventStartDate)
      ),
      error: translate('must be after issue and event start date, and same or before maturity')
    },
    yieldRate: {
      isInvalid: yieldRate !== '' && !isValidYield(yieldRate),
      error: translate(`Valor deve ser entre ${indexes[index].minYield} e ${indexes[index].maxYield}`)
    }
  }),
  [
    issueDate,
    yieldDate,
    maturityDate,
    anniversaryDate,
    eventStartDate,
    eventEndDate,
    yieldRate,
    dateIsOffLimits,
    importEvent,
    index,
    isInflation,
    isValidYield,
    translate
  ]
  );

  const handleDateChange = (onChange) => (date) => {
    onChange(date);
  };
  const handleValueChange = (onChange) => (event) => {
    onChange(event.target.value);
  };
  const handleCheckBoxChange = (onChange) => (event) => {
    onChange(event.target.checked);
  };

  const handleTextChange = (onChange) => (event) => {
    const newValue = event.target.value;

    if (newValue.match(alphaNumericOrEmptyRegExp)) {
      onChange(newValue.toUpperCase());
    }
  };

  const handleYieldRateChange = (event) => {
    const newValue = event.target.value;

    if (newValue.match(floatRegExp)) {
      setYieldRate(newValue);
    }
  };

  const closeModal = () => {
    setErrorModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const hasInvalidField = Object.values(formValidity).find((field) =>
      field.isInvalid
    );

    if (hasInvalidField) {
      setErrorModalOpen(true);
    } else {
      buildConfirmationFormData();
      buildSecurity();

      setOpenDataModal(true);
    }
  };

  const displaySnackBar = (successful, message) => {
    setSuccess(successful);
    setSnackBarMessage(translate(message));
    setSnackBarOpen(true);

    setTimeout(() => {
      setSnackBarOpen(false);
    }, 3000);
  };

  const handleSecurityInsert = async (e) => {
    setOpenDataModal(false);

    setLoading(true);

    try {
      await postSecurity(security);

      displaySnackBar(true, 'security added successfully!');
    } catch (err) {
      displaySnackBar(false, 'error on security insertion');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => date.toISOString().substring(0, 10);

  const buildConfirmationFormData = () => {
    setConfirmationFormData({
      'issue date': formatDate(issueDate),
      'yield start date': formatDate(yieldDate),
      'maturity date': formatDate(maturityDate),
      'ticker': symbol,
      'product type': translate(productType),
      'isin': isin,
      'issue face value': issueFaceValue,
      'index': translate(index),
      'yield': `${yieldRate}%`,
      ...(isSpread(index) && { 'annual basis': annualBasis }),
      ...(isDiIndexed(index) && { 'index lag(days)': indexLag }),
      ...(isInflation && {
        'index lag(months)': indexLag,
        'pro rata style': translate(proRataStyle),
        'anniversary date': formatDate(anniversaryDate),
        'index update frequency': translate(indexUpdateFrequency),
        'interest step': hasInterestStep ? translate('yes') : translate('no')
      })
    });
  };

  const formatEvents = () => {
    const nominalYieldRate = percentToNominal(yieldRate);

    return events.reduce((acc, rawEvent) => {
      const event = { ...rawEvent };

      if (event.eventDate === formatDate(maturityDate)) {
        return acc;
      }

      if (event.eventType.match(/.*amortization/i)) {
        event.eventType = amortizationType;
      } else if (event.eventType === 'interest' &&
        (event.eventYield === 1 || event.eventYield === nominalYieldRate)
      ) {
        event.eventYield = 1;
      }

      acc.push(event);

      return acc;
    }, []);
  };

  const setDealTypeSpecificData = () => {
    const dealTypeSpecificData = {};
    const date = formatDate(yieldDate);
    const yieldValue = percentToNominal(yieldRate);

    if (isSpread(index)) {
      dealTypeSpecificData.spreadYields = [
        {
          date,
          yield: yieldValue,
          annualBasis,
          ...(hasInterestStep && { interestStep: 'monthly' }),
          yieldDayCount: annualBases[annualBasis].yieldDayCount
        }
      ];
      if (index !== 'pre') {
        dealTypeSpecificData.indexYields = [{ date, yield: 1 }];
      }
    } else {
      dealTypeSpecificData.spreadYields = [];
      dealTypeSpecificData.indexYields = [{ date, yield: yieldValue }];
    }

    return dealTypeSpecificData;
  };

  const buildSecurity = () => {
    const indexRelation = indexes[index].indexRelation;
    const eventList = formatEvents();
    const dealTypeSpecificData = setDealTypeSpecificData();

    setSecurity({
      issueDate: formatDate(issueDate),
      symbol,
      productType,
      tradeSettlementLead: 0,
      validityDate: formatDate(issueDate),
      displayName: symbol,
      source: 'PRICING_SERVICE',
      calendar: {
        name: 'BRAZIL',
        category: 'COUNTRY'
      },
      paymentCurrency: 'BRL',
      classification: indexes[index].classification,
      pricingMethod: indexes[index].pricingMethod,
      specificData: {
        ...dealTypeSpecificData,
        index: indexes[index].index,
        dealType: indexes[index].dealType,
        maturityDate: formatDate(maturityDate),
        issueFaceValue,
        eventPaymentLead: 0,
        indexUpdateFrequency,
        ...(isin !== '' && { isin }),
        ...(indexRelation && { indexRelation }),
        ...(isDiIndexed(index) && { indexDayLag: Number(indexLag) }),
        ...(isInflation && {
          indexMonthLag: Number(indexLag),
          proRataStyle,
          anniversaryDate: formatDate(anniversaryDate)
        }),
        eventList
      }
    });
  };

  return (
    <>
      <Box my={3}>
        <Container maxWidth="sm">
          <Paper>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box p={3}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="baseline"
                  spacing={3}
                >
                  <h3>{translate('basic information')}</h3>

                  <FormDatePicker
                    label="issue date"
                    value={issueDate}
                    onChange={handleDateChange(setIssueDate)}
                  />

                  <FormDatePicker
                    label="yield start date"
                    value={yieldDate}
                    onChange={handleDateChange(setYieldDate)}
                    error={formValidity.yieldDate.isInvalid}
                    helperText={formValidity.yieldDate.error}
                  />

                  <FormDatePicker
                    label="maturity date"
                    value={maturityDate}
                    onChange={handleDateChange(setMaturityDate)}
                    error={formValidity.maturityDate.isInvalid}
                    helperText={formValidity.maturityDate.error}
                  />

                  <Grid item xs={12}>
                    <TextField
                      label={translate('ticker')}
                      type="search"
                      variant="standard"
                      fullWidth
                      value={symbol}
                      onChange={handleTextChange(setSymbol)}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      required
                    />
                  </Grid>

                  <FormRadioGroup
                    label={translate('product type')}
                    value={productType}
                    onChange={handleValueChange(setProductType)}
                    options={productTypes}
                  />

                  <Grid item xs={12}>
                    <TextField
                      label={translate('isin')}
                      type="search"
                      variant="standard"
                      fullWidth
                      value={isin}
                      onChange={handleTextChange(setIsin)}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={translate('issue face value')}
                      type="number"
                      variant="standard"
                      fullWidth
                      value={issueFaceValue}
                      onChange={handleValueChange(setIssueFaceValue)}
                      required
                    />
                  </Grid>

                  <FormRadioGroup
                    label={translate('index')}
                    value={index}
                    onChange={handleValueChange(setIndex)}
                    options={Object.keys(indexes)}
                  />

                  <Grid item xs={12}>
                    <TextField
                      label={translate('yield rate 99,99 = 99,99%')}
                      variant="standard"
                      fullWidth
                      value={yieldRate}
                      error={formValidity.yieldRate.isInvalid}
                      helperText={formValidity.yieldRate.error}
                      onChange={handleYieldRateChange}
                      required
                    />
                  </Grid>

                  {index !== 'pre' && (
                    <Grid item xs={12}>
                      <TextField
                        label={isInflation ? translate('index lag(months)') : translate('index lag(days)')}
                        type="number"
                        variant="standard"
                        fullWidth
                        value={indexLag}
                        onChange={handleValueChange(setIndexLag)}
                        required
                      />
                    </Grid>
                  )}

                  {isSpread(index) && (
                    <FormRadioGroup
                      label={translate('annual basis')}
                      value={annualBasis}
                      onChange={handleValueChange(setAnnualBasis)}
                      options={Object.keys(annualBases)}
                    />
                  )}

                  {isInflation && (
                    <>
                      <FormRadioGroup
                        label={translate('pro rata style')}
                        value={proRataStyle}
                        onChange={handleValueChange(setProRataStyle)}
                        options={proRataStyles}
                      />

                      <FormDatePicker
                        label="anniversary date"
                        value={anniversaryDate}
                        onChange={handleDateChange(setAnniversaryDate)}
                        error={formValidity.anniversaryDate.isInvalid}
                        helperText={formValidity.anniversaryDate.error}
                      />

                      <FormRadioGroup
                        label={translate('index update frequency')}
                        value={indexUpdateFrequency}
                        onChange={handleValueChange(setIndexUpdateFrequency)}
                        options={indexUpdateFrequencies}
                      />

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hasInterestStep}
                              onChange={handleCheckBoxChange(
                                setHasInterestStep
                              )}
                            />
                          }
                          label={translate('interest step')}
                        />
                      </Grid>
                    </>
                  )}

                  <EventsForm
                    events={events}
                    setEvents={setEvents}
                    amortizationType={amortizationType}
                    setAmortizationType={setAmortizationType}
                    importEvent={importEvent}
                    setImportEvent={setImportEvent}
                    eventStartDate={eventStartDate}
                    setEventStartDate={setEventStartDate}
                    eventEndDate={eventEndDate}
                    setEventEndDate={setEventEndDate}
                    formValidity={formValidity}
                    displaySnackBar={displaySnackBar}
                    yieldRate={yieldRate}
                    dateIsOffLimits={dateIsOffLimits}
                  />

                  <Grid item xs={12}></Grid>

                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={25} />
                      ) : (
                        translate('insert')
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>

      <DataConfirmationModal
        events={events}
        open={openDataModal}
        setOpen={setOpenDataModal}
        downloadData={security}
        sections={[
          {
            title: 'basic information',
            data: confirmationFormData
          },
          {
            title: 'payment flow',
            data: {
              ...(importEvent && { 'amortization yield application': translate(amortizationType) })
            },
            component: (
              <EventsTable
                events={events}
              />
            )
          }
        ]}
        onConfirm={handleSecurityInsert}
      />

      <InformationModal
        modalOpen={errorModalOpen}
        closeModal={closeModal}
        title={translate('error')}
      >
        {
          <p>
            {translate('there are invalid or unfilled fields in the form')}
          </p>
        }
      </InformationModal>

      <SuccessSnackBar
        success={success}
        successMessage={snackBarMessage}
        failureMessage={snackBarMessage}
        open={snackBarOpen}
        setOpen={setSnackBarOpen}
      />
    </>
  );
};

export default CorporateBondsInsert;
