import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import DestFathers from './DestFathers';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { color } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: '140px',
    maxHeight: '500px',
    minWidth: '270px',
    maxWidth: '550px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '3rem',
    margin: '1rem'
  }
}));
const DestForm = ({ dests, handleDestInput, searchingValue, setDestSearch, selectedValues, setSelectedValues }) => {
  const destFoundValues = dests.foundValues;
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const userFundId = `Destino`;

  const handleClearSelectedFunds = () => {
    setSelectedValues({ ...selectedValues, dest: [] });
  };
  const selectedBox = selectedValues.dest?.map((dest, i) => (
    <p key={i} >
      <span>Fund ID: {dest.id}</span>
      <br />
      <span>Name: {dest.name}</span>
      <br />
      <span>Email: {dest.email}</span>
    </p>
  ));

  const clearFundsButton = (
    <Button variant="contained" color="primary" className={classes.button} onClick={handleClearSelectedFunds}>{translate('clear selected funds')}</Button>
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' align="center" color="primary" >{translate('destination advisor')}</Typography>
      <TextField
        id={userFundId}
        value={searchingValue}
        label={`UserId ${userFundId}`}
        onChange={handleDestInput}
        type='text'
        margin='normal'
        fullWidth
      />
      <DestContainer show={ destFoundValues?.length > 5 } >
        { selectedValues.dest.length ? <div className='wrap-selected'>{selectedBox}</div> : null }
        { selectedValues.dest.length ? clearFundsButton: null }
        {destFoundValues?.map((dest, i) => (
          <DestFathers dest={dest} key={i} setDestSearch={setDestSearch} selectedValues={selectedValues} setSelectedValues={setSelectedValues} destSearch={dests}/>
        ))}
      </DestContainer>
    </Paper>
  );
};

export default DestForm;

const DestContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  overflow-y: scroll;
  margin-top: ${(props) => props.show ? '10px' : '0px' };
  padding-bottom: ${(props) => props.show ? '10px' : '0px' };
  &::-webkit-scrollbar {
    display: ${(props) => props.show ? 'show' : 'none' };
  }

  .wrap-selected {
    border: 1px solid ${color.GorilaSecondary};
    border-radius: 8px;
    padding: 0px 6px;
  }
`;
