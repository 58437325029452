import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useState } from 'react';
import LoadingTable from './LoadingTable';

const useStyles = makeStyles( (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginBottom: '2rem'
  },
  root: {
    flexGrow: 1,
    borderBottom: '1px solid #e6e6e6',
    boxShadow: 'none'
  }
}));

function allowedTabs(data, tabs) {
  return tabs.filter((tab) => {
    return !tab.allowedData || Object.keys(tab.allowedData).every((key) => {
      return data?.hasOwnProperty(key) && Array.isArray(tab.allowedData[key]) && tab.allowedData[key].includes(data[key]);
    });
  });
}

const DetailsTabs = ({ tabs, data, loading, setMessage }) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelectTab = (event, newValue) =>{
    setSelectedTab(newValue);
  };

  return (
    <>
      {<Paper className={classes.root}>
        <Tabs value={selectedTab} onChange={handleSelectTab} indicatorColor='primary' textColor='primary'>
          {!loading && allowedTabs(data, tabs).map((tab) => {
            return <Tab key={tab.name} label={tab.name} />;
          })}
        </Tabs>
      </Paper>}
      {loading ?
        <Paper elevation={3} className={classes.paper}>
          <LoadingTable headerCells={4} tableCells={2} />
        </Paper> :
        allowedTabs(data, tabs).map((item, tabIndex) =>
          <Typography
            component="div"
            role="tabpanel"
            key={`panel-${tabIndex}`}
            id={`tabPanel-${tabIndex}`}
            hidden={selectedTab !== tabIndex}
            aria-labelledby={`tab-${tabIndex}`}
          >
            {
              selectedTab === tabIndex &&
              <Box p={3}>
                { !loading && item?.content?.component &&
                  <item.content.component
                    {...item.content.props }
                    data={data}
                    setMessage={setMessage}
                  /> }
              </Box>
            }
          </Typography>
        )
      }
    </>
  );
};

export default DetailsTabs;
