import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Header } from './shared';
import AuthenticatedApp from './routes/AuthenticatedApp';
import UnauthenticatedApp from './routes/UnautheticatedApp';
import { useAuthState, useAuthDispatch } from './context/authentication';
import { checkToken } from './context/actions/auth';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
require('dotenv-extended').load();

const useStyles = makeStyles(() => ({
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const App = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(checkToken());
  }, [state.isAuthenticate, dispatch]);

  return (
    <Box className={classes.main}>
      <Header />
      <Switch>
        { state.isAuthenticate ? <AuthenticatedApp /> : <UnauthenticatedApp /> }
      </Switch>
    </Box>
  );
};

export default App;
