import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import PriceSearch from './PriceSearch/PriceSearch';
import PriceInsert from './PriceInsert/PriceInsert';


const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const Price = (props) => {
  const classes = useStyles();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;
  const history = useHistory();

  const isPageActive = (page) => pathName.search(page) !== -1;

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <Button color="primary" className={isPageActive('search') ? classes.active : ''} onClick={() => handleChangePage('search')}>
            BUSCAR PREÇO
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('insert') ? classes.active : ''} onClick={() => handleChangePage('insert')}>
            INSERIR PREÇO
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Switch>
          <Route path={previousRoute + '/search'} component={PriceSearch} />
          <Route path={previousRoute + '/insert'} component={PriceInsert} />
          <Redirect to={previousRoute + '/search'} />
        </Switch>
      </Grid>
    </>
  );
};

export default Price;
